import React, { PureComponent } from 'react';
import Select from 'react-select';
import { Alert } from 'reactstrap';

import FieldErrorViewer from '../../Passports/components/FieldErrorViewer';
import FileUpload from '../../../components/FileUpload';
import { POSITIVE_ACTION_STATUSES, SubscriptionAPI } from '../../../api/Client';
import CreatableSelect from 'react-select/creatable';

class SubscriptionFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchFilterCities = this.fetchFilterCities.bind(this);
    this.fetchFilterCountries = this.fetchFilterCountries.bind(this);
    this.fetchFilterSources = this.fetchFilterSources.bind(this);
    this.fetchFilterTypes = this.fetchFilterTypes.bind(this);
    this.fetchUploadTypes = this.fetchUploadTypes.bind(this);
    this.handleFilterCity = this.handleFilterCity.bind(this);
    this.handleFilterCountry = this.handleFilterCountry.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleUploadCity = this.handleUploadCity.bind(this);
    this.handleUploadCountry = this.handleUploadCountry.bind(this);
    this.handleUploadSource = this.handleUploadSource.bind(this);
    this.handleUploadType = this.handleUploadType.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleFilterSource = this.handleFilterSource.bind(this);
    this.handleFilterType = this.handleFilterType.bind(this);
    this.handleCreateSourceOption = this.handleCreateSourceOption.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onFileUploadMenuOpen = this.onFileUploadMenuOpen.bind(this);

    this.state = {
      cities: [],
      city: [],
      countries: [],
      country: '',
      errorMessages: {},
      showSuccessUpload: false,
      source: '',
      sources: [],
      type: [],
      types: [],
      uploadError: '',
      uploadErrors: {},
      uploadCity: '',
      uploadCountry: '',
      uploadSource: '',
      uploadType: '',
      uploadTypes: [],
      uploadedFiles: [],
      isFileUploadMenuOpened: false,
      isCitiesLoading: true,
      isCountriesLoading: true,
      isSourcesLoading: false,
      isTypesLoading: false,
    };
  }

  componentDidMount() {
    this.fetchFilterCities();
    this.fetchFilterCountries();
    this.fetchFilterSources();
    this.fetchFilterTypes();
    this.fetchUploadTypes();
  }

  fetchFilterCities() {
    let status;
    let { filter } = this.state;
    this.setState({ isCitiesLoading: true });

    SubscriptionAPI.fetchList(1, filter, 'filter/cities/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            cities: r || [],
            isCitiesLoading: false,
          });
        }
      });
  }

  fetchFilterCountries() {
    let status;
    let { filter } = this.state;
    this.setState({ isCountriesLoading: true });

    SubscriptionAPI.fetchList(1, filter, 'filter/countries/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            countries: r || [],
            isCountriesLoading: false,
          });
        }
      });
  }

  fetchFilterSources() {
    let status;
    let { filter } = this.state;
    this.setState({ isSourcesLoading: true });

    SubscriptionAPI.fetchList(1, filter, 'filter/sources/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            sources: r || [],
            isSourcesLoading: false,
          });
        }
      });
  }

  fetchFilterTypes() {
    let status;
    let { filter } = this.state;
    this.setState({ isTypesLoading: true });

    SubscriptionAPI.fetchList(1, filter, 'filter/types/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            types: r || [],
            isTypesLoading: false,
          });
        }
      });
  }

  fetchUploadTypes() {
    let status;
    let { filter } = this.state;

    SubscriptionAPI.fetchList(1, filter, 'types/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            uploadTypes: r || [],
          });
        }
      });
  }

  handleFileUpload(files) {
    this.setState({ uploadedFiles: files });
  }

  onUpload() {
    this.setState({ ...this.state, uploadError: '', uploadErrors: {} });
    if (!this.state.uploadCountry) {
      this.setState({
        ...this.state,
        showSuccessUpload: false,
        uploadError: 'Укажите страну для файла',
        uploadErrors: {
          ...this.state.uploadErrors,
          country: 'Обязательное поле',
        },
      });
      return;
    }
    if (!this.state.uploadSource) {
      this.setState({
        ...this.state,
        showSuccessUpload: false,
        uploadError: 'Укажите источник для файла',
        uploadErrors: {
          ...this.state.uploadErrors,
          source: 'Обязательное поле',
        },
      });
      return;
    }
    if (!this.state.uploadType) {
      this.setState({
        ...this.state,
        showSuccessUpload: false,
        uploadError: 'Укажите тип для файла',
        uploadErrors: {
          ...this.state.uploadErrors,
          type: 'Обязательное поле',
        },
      });
      return;
    }

    let formData = new FormData();
    this.state.uploadedFiles.map((file) => formData.append('file_path', file));
    formData.append('country', this.state.uploadCountry.value);
    formData.append('source', this.state.uploadSource.value);
    formData.append('type', this.state.uploadType.value);
    if (this.state.uploadCity) {
      formData.append('city', this.state.uploadCity.value);
    }

    let status;
    SubscriptionAPI.upload(formData)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === 201) {
          const uploadedFiles = [];
          uploadedFiles.push(r);
          this.setState({
            ...this.state,
            showSuccessUpload: true,
            uploadError: '',
            uploadedFiles: uploadedFiles,
          });
        } else {
          this.setState({
            ...this.state,
            showSuccessUpload: false,
            uploadError: r.non_field_errors || 'Не удалось загрузить файл',
            uploadErrors: r,
            uploadedFiles: [],
          });
        }
      });
  }

  handleFilterCity(city) {
    this.setState({ city: city || [] });
  }

  handleFilterCountry(country) {
    this.setState({ country: country || '' });
  }

  handleFilterSource(source) {
    this.setState({ source: source || '' });
  }

  handleFilterType(type) {
    this.setState({ type: type || [] });
  }

  handleUploadCity(uploadCity) {
    this.setState({ uploadCity });
  }

  handleUploadCountry(uploadCountry) {
    this.setState({ uploadCountry });
  }

  handleUploadSource(uploadSource) {
    this.setState({ uploadSource });
  }

  handleUploadType(type) {
    this.setState({ uploadType: type || '' });
  }

  handleCreateSourceOption(input) {
    console.log(input);
    const { value, label } = input;
    const newOption = { value, label, isInTree: true };
    this.setState((prevState) => {
      return {
        sources: [...prevState.sources, newOption],
        uploadSource: newOption.value,
      };
    });
  }

  handleReset() {
    this.setState(
      {
        errorMessages: {},
        city: [],
        country: '',
        source: '',
        type: [],
      },
      this.onFilter
    );
  }

  onFilter() {
    this.props.onFilter({
      city: this.state.city.map((c) => c.value).join(',') || '',
      country: this.state.country.value || '',
      source: this.state.source.value || '',
      type: this.state.type.map((t) => t.value).join(',') || '',
    });
  }

  onFileUploadMenuOpen() {
    this.setState({
      isFileUploadMenuOpened: !this.state.isFileUploadMenuOpened,
    });
  }

  render() {
    const { cities, city, countries, country, source, sources, type, types } =
      this.state;

    // filter
    const CITY_OPTIONS = cities.length
      ? cities.map((c) => {
          return { value: c.value, label: c.label };
        })
      : [];
    const COUNTRY_OPTIONS = countries.length
      ? countries.map((c) => {
          return { value: c.value, label: c.label };
        })
      : [];
    const TYPE_OPTIONS = types.length
      ? types.map((t) => {
          return { value: t.value, label: t.label };
        })
      : [];

    // file upload
    const {
      uploadCity,
      uploadCountry,
      uploadSource,
      uploadType,
      uploadTypes,
      isFileUploadMenuOpened,
      isCitiesLoading,
      isCountriesLoading,
      isSourcesLoading,
      isTypesLoading,
    } = this.state;
    const UPLOAD_TYPE_OPTIONS = uploadTypes.length
      ? uploadTypes.map((t) => {
          return { value: t.value, label: t.label };
        })
      : [];

    return (
      <div className="animated fadeIn filter-container">
        <div className="filter filter--blue">
          <div className="card card--search-bg">
            <div className="card-header">
              <strong>Агенты, клиенты и подписка</strong>
            </div>
            <div className="card-body">
              <div className="filter-row">
                <div className="filter-field">
                  <label htmlFor="subscriptionCities">Город</label>
                  <Select
                    id="subscriptionCities"
                    name="city"
                    placeholder="Укажите город"
                    value={city}
                    isMulti={true}
                    onChange={this.handleFilterCity}
                    options={CITY_OPTIONS}
                    isClearable
                    isLoading={isCitiesLoading}
                  />
                </div>
                <div className="filter-field">
                  <label htmlFor="subscriptionCountries">Страна</label>
                  <Select
                    id="subscriptionCountries"
                    name="country"
                    placeholder="Укажите страну"
                    value={country}
                    onChange={this.handleFilterCountry}
                    options={COUNTRY_OPTIONS}
                    isClearable
                    isLoading={isCountriesLoading}
                  />
                </div>
                {sources.length > 1 ? (
                  <div className="filter-field">
                    <label htmlFor="subscriptionSource">Источник</label>
                    <Select
                      id="subscriptionSource"
                      name="source"
                      placeholder="Укажите источник"
                      value={source}
                      onChange={this.handleFilterSource}
                      options={sources}
                      isClearable
                      isLoading={isSourcesLoading}
                    />
                  </div>
                ) : null}
                {TYPE_OPTIONS.length > 1 ? (
                  <div className="filter-field">
                    <label htmlFor="subscriptionType">Тип</label>
                    <Select
                      id="subscriptionType"
                      name="type"
                      placeholder="Укажите тип"
                      value={type}
                      isMulti={true}
                      onChange={this.handleFilterType}
                      options={TYPE_OPTIONS}
                      isClearable
                      isLoading={isTypesLoading}
                    />
                  </div>
                ) : null}
              </div>
              <div className="card-footer-container">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.onFileUploadMenuOpen}
                  style={{ borderRadius: '4px' }}
                >
                  Загрузить файл
                </button>
                <div className="card-footer">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={this.onFilter}
                  >
                    Найти
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    style={{ backgroundColor: 'transparent' }}
                    onClick={this.handleReset}
                  >
                    Сбросить
                  </button>
                </div>
              </div>
              <div
                className={`file-upload ${
                  isFileUploadMenuOpened ? 'file-upload_visible' : ''
                }`}
              >
                <div className="filter-row">
                  <div className="filter-field">
                    <label htmlFor="uploadCountry">Страна</label>
                    <Select
                      id="uploadCountry"
                      name="uploadCountry"
                      placeholder="Укажите страну"
                      value={uploadCountry}
                      onChange={this.handleUploadCountry}
                      options={COUNTRY_OPTIONS}
                    />
                    <FieldErrorViewer
                      errorMessages={this.state.uploadErrors}
                      field="country"
                    />
                  </div>
                  <div className="filter-field">
                    <label htmlFor="uploadCity">Город, если нет в файле</label>
                    <Select
                      id="uploadCity"
                      name="uploadCity"
                      placeholder="Город, если нет в файле"
                      value={uploadCity}
                      onChange={this.handleUploadCity}
                      options={CITY_OPTIONS}
                    />
                    <FieldErrorViewer
                      errorMessages={this.state.uploadErrors}
                      field="city"
                    />
                  </div>
                  <div className="filter-field">
                    <label htmlFor="uploadSource">Источник</label>
                    <CreatableSelect
                      id="uploadSource"
                      name="uploadSource"
                      placeholder="Укажите источник"
                      isClearable={true}
                      value={uploadSource}
                      onChange={this.handleUploadSource}
                      options={sources}
                      onNewOptionClick={this.handleCreateSourceOption}
                    />
                    <FieldErrorViewer
                      errorMessages={this.state.uploadErrors}
                      field="source"
                    />
                  </div>
                  <div className="filter-field">
                    <label htmlFor="uploadType">Тип для файла</label>
                    <Select
                      id="uploadType"
                      name="uploadType"
                      placeholder="Тип"
                      value={uploadType}
                      onChange={this.handleUploadType}
                      options={UPLOAD_TYPE_OPTIONS}
                      required={true}
                    />
                    <FieldErrorViewer
                      errorMessages={this.state.uploadErrors}
                      field="type"
                    />
                  </div>
                </div>
                <div className="card-footer">
                  <FileUpload
                    multiple={false}
                    onChange={this.handleFileUpload}
                    accept="*.xslx"
                    name="filename"
                    uploadedFiles={this.state.uploadedFiles}
                  />
                  <button
                    disabled={!this.state.uploadedFiles.length}
                    className="btn btn-primary"
                    onClick={this.onUpload}
                  >
                    Загрузить
                  </button>
                  {this.state.showSuccessUpload && (
                    <Alert color="success">
                      Файл успешно загружен и будет обработан в течении 5 минут
                    </Alert>
                  )}
                  {this.state.uploadError && (
                    <Alert color="danger">{this.state.uploadError}</Alert>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubscriptionFilter;
