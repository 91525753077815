export const SMS_PERSON_SUBJECT = 'отправлено смс потенциальному клиенту'
export const SMS_TOURIST_SUBJECT = 'отправлено смс туристу'
export const EMAIL_PERSON_SUBJECT = 'отправлено email потенциальному клиенту'
export const EMAIL_TOURIST_SUBJECT  = 'отправлено email туристу'

export const ISSUE_SOURCE_MAP = {
  call_to_office: {
    name: 'Звонок в офис',
    icon: 'fa-phone',
  },
  call_to_phone: {
    name: 'Звонок на сотовый',
    icon: 'fa-mobile',
  },
  visitor: {
    name: 'Посетитель',
    icon: 'fa-user',
  },
  geo_site: {
    name: 'Сайт',
    icon: 'fa-star',
  },
  geo_site_chat: {
    name: 'Сайт (чат)',
    icon: 'fa-star',
  },
  geo_site_form_hot: {
    name: 'Сайт (горящая заявка)',
    icon: 'fa-star',
  },
  geo_site_quote: {
    name: 'Геоподборка',
    icon: 'fa-star',
  },
  agency_tour: {
    name: 'Сайт (Агентский поисковик)',
    icon: 'fa-star',
  },
  cabinet: {
    name: 'ЛК (офис)',
    icon: 'fa-star-half-o',
  },
  cabinet_online: {
    name: 'ЛК (покупка онлайн)',
    icon: 'fa-star-half-o',
  },
  cabinet_tourist: {
    name: 'Кабинет туриста',
    icon: 'fa-star-half-o',
  },
  excel: {
    name: 'Выгрузка excel',
    icon: 'fa-file',
  },
  recommendation: {
    name: 'Рекомендация',
    icon: 'fa-hand-peace-o',
  },
  permanent: {
    name: 'Постоянный клиент',
    icon: 'fa-star',
  },
  instagram: {
    name: 'Инстаграм',
    icon: 'fa-instagram',
  },
  vkontakte: {
    name: 'ВКонтакте',
    icon: 'fa-vk',
  },
  facebook: {
    name: 'Фейсбук',
    icon: 'fa-facebook',
  },
  odnoklasniki: {
    name: 'Однокласники',
    icon: 'fa-odnoklassniki',
  },
  socials: {
    name: 'Соцсети',
    icon: 'fa-share-alt',
  },
  mailing: {
    name: 'Рассылки',
    icon: 'fa-paper-plane-o',
  },
  mailing_whatsapp: {
    name: 'Рассылка whatsapp',
    icon: 'fa-whatsapp',
  },
  viber: {
    name: 'Вайбер',
    icon: 'fa-weixin',
  },
  telegram: {
    name: 'Телеграм',
    icon: 'fa-telegram',
  },
  telegram_bot: {
    name: 'Телеграм Бот',
    icon: 'fa-telegram',
  },
  whatsapp: {
    name: 'whatsapp',
    icon: 'fa-whatsapp',
  },
  quotes_from_crm: {
    name: 'Геоподборка',
    icon: 'fa-star',
  },
  yandex: {
    name: 'Яндекс',
    icon: 'fa-star',
  },
  google: {
    name: 'Гугл',
    icon: 'fa-google',
  },
  geo_site_subscribe: {
    name: 'Сайт (подписка Тусич)',
    icon: 'fa-star',
  },
  tourvisor: {
    name: 'Турвизор онлайн',
    icon: 'fa-star',
  },
  tourvisor_office: {
    name: 'Турвизор офис',
    icon: 'fa-star',
  },
};

export const ISSUE_STATUSES = {
  active: 'active',
  call: 'call',
  called: 'called',
  cancel: 'cancel',
  client_cancel: 'client_cancel',
  deffered: 'deffered',
  meet: 'meet',
  new: 'new',
  no_call_answered: 'no_call_answered',
  order: 'order',
  quotes_from_crm: 'quotes_from_crm',
  transfer: 'transfer',
  tours: 'tours',
  we_cancel: 'we_cancel',
  whatsapp: 'whatsapp',
};
export const STATUS_DESCRIPTION_MAP = {
  new: 'Новая',
  call: 'Назначен звонок',
  tours: 'Отправлена подборка',
  meet: 'Назначено событие в календаре',
  client_cancel: 'Турист отказался',
  we_cancel: 'Мы отказали',
  work: 'Открыт',
  order: 'Покупка',
  cancelled: 'Аннулирована',
  refused: 'Отклонен',
  deffered: 'Отложить до',
  called: 'Cозвонились',
  quotes_from_crm: 'Подборка из CRM',
};
export const ISSUE_STATUS_COLOR = {
  [ISSUE_STATUSES.active]: 'badge-warning',
  [ISSUE_STATUSES.call]: 'badge-green',
  [ISSUE_STATUSES.called]: 'badge-primary',
  [ISSUE_STATUSES.cancel]: 'badge-gray',
  [ISSUE_STATUSES.client_cancel]: 'badge-red',
  [ISSUE_STATUSES.deffered]: 'badge-orange',
  [ISSUE_STATUSES.meet]: 'badge-green',
  [ISSUE_STATUSES.new]: 'badge-danger',
  [ISSUE_STATUSES.no_call_answered]: 'badge-default',
  [ISSUE_STATUSES.order]: 'badge-order',
  [ISSUE_STATUSES.quotes_from_crm]: 'badge-pink',
  [ISSUE_STATUSES.transfer]: 'badge-default',
  [ISSUE_STATUSES.tours]: 'badge-green',
  [ISSUE_STATUSES.we_cancel]: 'badge-brown',
  [ISSUE_STATUSES.whatsapp]: 'badge-green',
};
export const ISSUE_STATUS_ICONS = {
  [ISSUE_STATUSES.active]: 'fa-hourglass-half',
  [ISSUE_STATUSES.call]: 'fa-phone',
  [ISSUE_STATUSES.called]: 'fa-phone',
  [ISSUE_STATUSES.cancel]: 'fa-history',
  [ISSUE_STATUSES.client_cancel]: 'fa-frown-o',
  [ISSUE_STATUSES.deffered]: 'fa-calendar',
  [ISSUE_STATUSES.meet]: 'fa-calendar',
  [ISSUE_STATUSES.new]: 'fa-arrow-up',
  [ISSUE_STATUSES.order]: 'fa-money',
  [ISSUE_STATUSES.quotes_from_crm]: 'fa-tasks',
  [ISSUE_STATUSES.transfer]: 'fa-user',
  [ISSUE_STATUSES.tours]: 'fa-tasks',
  [ISSUE_STATUSES.we_cancel]: 'fa-ban',
  [ISSUE_STATUSES.whatsapp]: 'fa-whatsapp',
};
export const ISSUE_STATUSES_LANG = {
  [ISSUE_STATUSES.new]: 'Новый',
  [ISSUE_STATUSES.active]: 'Открыт',
  [ISSUE_STATUSES.meet]: 'Событие в календаре',
  [ISSUE_STATUSES.deffered]: 'Отложен',
  [ISSUE_STATUSES.transfer]: 'Перевод',
  [ISSUE_STATUSES.tours]: 'Отправлена подборка',
  [ISSUE_STATUSES.cancel]: 'Аннулирована',
  [ISSUE_STATUSES.order]: 'Покупка',
  [ISSUE_STATUSES.call]: 'Назначен звонок',
  [ISSUE_STATUSES.we_cancel]: 'Мы отказали',
  [ISSUE_STATUSES.client_cancel]: 'Турист отказался',
  [ISSUE_STATUSES.called]: 'Cозвонились',
  [ISSUE_STATUSES.no_call_answered]: 'Не берет трубку',
  [ISSUE_STATUSES.whatsapp]: 'Написали на WA',
  [ISSUE_STATUSES.quotes_from_crm]: 'Отправили подборку',
};