import React from 'react';
import Select from 'react-select';
import FieldErrorViewer from '../Passports/components/FieldErrorViewer';
import { Alert } from 'reactstrap';

export function Action({
  data = {},
  structure,
  structureType,
  actions,
  id = 0,
  franchiseApplicationId,
  errors = {},
  isSuccess = false,
  isCreation = false,
}) {
  data.id = franchiseApplicationId;

  const onChangeInput = (name, event) => {
    event.preventDefault();
    actions.onChangeInput(Object.assign(data, { [name]: event.target.value }));
  };

  const onChangeSelect = (name, val) => {
    actions.onChangeInput(Object.assign(data, { [name]: val.value }));
  };

  const onUpdate = (event) => {
    event.preventDefault();
    actions.onUpdateData(data);
  };

  const items = structure.map((item, idx) => {
    let element;
    if (item.type === 'select') {
      element = (
        <Select
          id={`input-${structureType}-${item.name}-${idx}`}
          onChange={(val) => onChangeSelect(item.name, val)}
          options={item.options}
          placeholder={item.lang}
          name={item.name}
          value={
            data[item.name]?.value
              ? data[item.name]
              : item.options.find((o) => o.value === data[item.name])
          }
          getOptionLabel={(o) => o.label}
          getOptionValue={(o) => o.value}
        />
      );
    } else if (item.type === 'textarea') {
      element = (
        <textarea
          id={`input-${structureType}-${item.name}-${idx}`}
          className="form-control"
          rows="3"
          name={item.name}
          onChange={(val) => onChangeInput(item.name, val)}
          placeholder={item.lang}
          value={data[item.name] === null ? '' : data[item.name]}
        >
          {}
        </textarea>
      );
    } else {
      element = (
        <input
          type={item.type}
          id={`input-${structureType}-${item.name}-${idx}`}
          name={item.name}
          value={data[item.name] === null ? '' : data[item.name]}
          onChange={(val) => onChangeInput(item.name, val)}
          className="form-control"
          placeholder={item.placeholder}
        />
      );
    }
    return (
      <div className="form-group row" key={idx}>
        <label
          className="col-md-3 col-form-label"
          htmlFor={`input-${item.name}-${idx}`}
        >
          {item.lang}
        </label>
        <div className="col-md-9">
          {element}
          <FieldErrorViewer errorMessages={errors} field={item.name} />
        </div>
      </div>
    );
  });
  return (
    <>
      {!isCreation ? (
        <form id={id}>
          {items}
          <button
            className="btn btn-success mb-2"
            onClick={onUpdate}
            type="submit"
          >
            Обновить
          </button>
        </form>
      ) : (
        items
      )}
      {isSuccess ? <Alert color="success">Успешно</Alert> : null}
    </>
  );
}
