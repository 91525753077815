import React, { Component, useEffect, useState } from 'react';
import BackButton from '../../../components/BackButton';
import { MailingAPI, POSITIVE_ACTION_STATUSES } from '../../../api/Client';
import moment from 'moment';
import 'moment/locale/ru';
import TouristLists from './TouristLists';
import TouristViberLists from './TouristViberLists';
import EmailTemplatePreview from './EmailTemplatePreview';
import { Editor } from '@tinymce/tinymce-react';
import { getUserRole, SUPER_ADMIN } from '../../../connect/auth';
import apiKey from '../../../constants/TinyMceApiKey';
import { useParams } from 'react-router-dom';

function Report() {
  const { reportId } = useParams();

  const [reportInfo, setReportInfo] = useState({});
  const [emailPreview, setEmailPreview] = useState();

  useEffect(() => {
    getInfo();
    getEmailPreviewHtml(reportId)
      .then((r) => (r.url.includes('localhost') ? r.text() : r.json()))
      .then((r) => {
        setEmailPreview(r);
      });
  }, []);

  function getInfo() {
    let status;
    MailingAPI.get(reportId)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.retrieve) {
          setReportInfo(r);
        }
      });
  }

  function onChangeModerationStatus(e) {
    let status;
    const { value } = e.target;
    MailingAPI.update(`${MailingAPI.resource_url}${reportId}`, {
      sms_campaign: { moderation_status: value },
    })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.update) {
          setReportInfo(r);
        }
      });
  }

  function getEmailPreviewHtml(bookId) {
    return MailingAPI.getEmailPreviewHtml(bookId);
  }
  const userRole = getUserRole();

  const {
    status,
    dispatch_type = {},
    email_campaign,
    sms_campaign,
    created,
    user,
    creator,
    report,
    errors,
    email_preview,
  } = reportInfo;

  return (
    <div className="animated fadeIn">
      <h2>отчет о рассылке №{reportId}</h2>
      <p>Статус: {status?.name}</p>
      {dispatch_type?.id === 2 && !!email_campaign ? (
        <Editor
          disabled={true}
          apiKey={apiKey}
          value={email_campaign.message}
        />
      ) : null}
      {dispatch_type?.id === 1 && !!sms_campaign ? (
        <p>Сообщение: {sms_campaign.message}</p>
      ) : null}
      <p>дата создания: {moment(created).format('DD.MM.YYYY HH:mm')}</p>
      {/*<p>Уведомление: <span>{campaign.is_notification ? `да` : `нет`}</span></p>*/}
      <p>
        Тип рассылки: <span>{dispatch_type?.name}</span>
      </p>
      {!!user ? (
        <p>
          Создатель рассылки:{' '}
          {`${creator.last_name} ${creator.first_name} (${creator.username})`}
        </p>
      ) : null}
      {userRole !== SUPER_ADMIN && dispatch_type?.id === 1 && !!sms_campaign ? (
        <p>Модерация: {sms_campaign.moderation_status.name}</p>
      ) : null}
      {userRole === SUPER_ADMIN && dispatch_type?.id === 1 && !!sms_campaign ? (
        <div className="row">
          <div className="col-md-2">
            <select
              onChange={onChangeModerationStatus}
              className="form-control"
              placeholder="Статус модерации"
              disabled={sms_campaign.moderation_status?.id !== 1}
              defaultValue={
                sms_campaign.moderation_status?.id ||
                sms_campaign.moderation_status
              }
            >
              <option value="1">Ожидает модерации</option>
              <option value="2">Одобрена</option>
              <option value="3">Отклонена</option>
            </select>
          </div>
        </div>
      ) : null}

      {dispatch_type?.name?.toLowerCase() === 'email' && (
        <EmailTemplatePreview
          html={emailPreview}
          id={reportId}
          url={email_preview}
        />
      )}

      {dispatch_type?.id && (
        <div>
          <h5>Статистика:</h5>
          {Array.isArray(report) && report.length ? (
            <div className="report__list">
              {report.map((report, idx) => (
                <div key={idx} className={`report__item ${report.alias}`}>
                  {report.name && (
                    <div className={report.alias}>
                      <b>{report.value}</b>
                      <span>{report.name}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div>нет статистики</div>
          )}
          {report && report.viber_reports && (
            <div className="report__list">
              <div className="report__item">
                <div className="delivered">
                  <b>{report.viber_reports.sent}</b>
                  <span>Отправлено</span>
                </div>
              </div>
              <div className="report__item">
                <div className="sent">
                  <b>{report.viber_reports.delivered}</b>
                  <span>
                    <strong>
                      {Math.round(
                        report.viber_reports.delivered /
                          (report.viber_reports.sent / 100)
                      )}
                      %
                    </strong>{' '}
                    доставлено
                  </span>
                </div>
              </div>
              <div className="report__item">
                <div className="read">
                  <b>{report.viber_reports.read}</b>
                  <span>
                    <strong>
                      {Math.round(
                        report.viber_reports.read /
                          (report.viber_reports.sent / 100)
                      )}
                      %
                    </strong>{' '}
                    прочитано
                  </span>
                </div>
              </div>
              <div className="report__item">
                <div className="redirected">
                  <b>{report.viber_reports.redirected}</b>
                  <span>
                    <strong>
                      {Math.round(
                        report.viber_reports.redirected /
                          (report.viber_reports.sent / 100)
                      )}
                      %
                    </strong>{' '}
                    переходы
                  </span>
                </div>
              </div>
              <div className="report__item">
                <div className="undelivered">
                  <b>{report.viber_reports.undelivered}</b>
                  <span>
                    <strong>
                      {Math.round(
                        report.viber_reports.undelivered /
                          (report.viber_reports.sent / 100)
                      )}
                      %
                    </strong>{' '}
                    недоставлено
                  </span>
                </div>
              </div>
              <div className="report__item">
                <div className="errors">
                  <b>{report.viber_reports.errors}</b>
                  <span>
                    <strong>
                      {Math.round(
                        report.viber_reports.errors /
                          (report.viber_reports.sent / 100)
                      )}
                      %
                    </strong>{' '}
                    ошибок
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div>
        Список ошибок:{' '}
        {Array.isArray(errors) && errors.length ? (
          <ol>
            {errors.map((err, idx) => (
              <li key={idx}>{err}</li>
            ))}
          </ol>
        ) : null}
      </div>
      <div>Список получателей:</div>
      {!dispatch_type.id ? null : dispatch_type?.id !== 0 ? (
        <TouristViberLists campaign={reportInfo} />
      ) : (
        <TouristLists campaign={reportInfo} />
      )}

      <BackButton />
    </div>
  );
}

export default Report;
