import React, { Component } from 'react';
import {
  OrderFpAPI,
  LeadAPI,
  PersonAPI,
  TouristFpAPI,
  TouristListsAPI,
  StagingsAPI,
  AgenciesAPI,
  FranchiseApplicationAPI,
  AbandonedCustomersAPI,
  CabinetAPI,
  SubscriptionAPI,
} from '../../../api/Client';
import { getFilterName } from '../utils';
import Paginator from '../../../components/Paginator/Paginator';
import { AiOutlineSearch, AiOutlineCloseCircle, AiOutlineLoading } from 'react-icons/ai';

export class RecipientsList extends Component {
  constructor(props) {
    super(props);
    this.getListOfRecipients = this.getListOfRecipients.bind(this);
    this.sendApiRequest = this.sendApiRequest.bind(this);
    this.handleOnPageChange = this.handleOnPageChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.reset = this.reset.bind(this);

    this.state = {
      recipients: [],
      hasNext: null,
      hasPrev: null,
      count: 0,
      pageNumber: 1,
      filterType: this.props.filterType,
      touristType: this.props.touristType,
      filterName: getFilterName(),
      searchQuery: '',
    };
  }

  sendApiRequest(apiName, p) {
    this.props.setListLoading();
    const { filterName, searchQuery } = this.state;
    console.log('filterName', filterName);
    let params = {};
    if (filterName) {
      params = JSON.parse(filterName);
    }
    if (searchQuery) {
      params.q = searchQuery;
    }

    apiName.getList(p, params).then((r) => {
      this.setState({
        recipients: r.results || [],
        hasNext: r.next != null,
        hasPrev: r.previous != null,
        count: r.count,
      });
      this.props.setListLoading();
    });
  }

  getListOfRecipients(filter, touristType, p = 1) {
    if (
      (filter && filter.type === 'all') ||
      (filter && filter.type === 'tags') ||
      (filter && filter.type === 'personal')
    ) {
      sendApiRequest(StagingsAPI, p);
    }
    if (filter && filter.type === 'tourists_cabinet') {
      this.sendApiRequest(CabinetAPI, p);
    } else if (touristType === 'cabinet') {
      this.sendApiRequest(CabinetAPI, p);
    } else if (touristType === 'leads') {
      this.sendApiRequest(LeadAPI, p);
    } else if (touristType === 'touristfp') {
      this.sendApiRequest(TouristFpAPI, p);
    } else if (touristType === 'persons') {
      this.sendApiRequest(PersonAPI, p);
    } else if (touristType === 'tourist') {
      this.sendApiRequest(OrderFpAPI, p);
    } else if (touristType === 'franchise') {
      this.sendApiRequest(AgenciesAPI, p);
    } else if (touristType === 'report') {
      this.sendApiRequest(TouristListsAPI, p);
    } else if (touristType === 'franchise_leads') {
      this.sendApiRequest(FranchiseApplicationAPI, p);
    } else if (touristType === 'abandonedcustomers') {
      this.sendApiRequest(AbandonedCustomersAPI, p);
    } else if (touristType === 'subscription') {
      this.sendApiRequest(SubscriptionAPI, p);
    }
  }

  handleOnPageChange(pageNumber) {
    const { selected } = pageNumber;
    const { filterType, touristType } = this.state;
    this.getListOfRecipients(filterType, touristType, selected + 1);
    this.setState({
      pageNumber: selected,
    });
  }

  reset() {
    this.setState(
      {
        ...this.state,
        pageNumber: 1,
        searchQuery: '',
      },
      () => {
        const { filterType, touristType } = this.state;
        this.getListOfRecipients(filterType, touristType);
      }
    );
  }

  handleInput(e) {
    this.setState({
      searchQuery: e.target.value,
    });
  }

  handleSearch(e) {
    const { filterType, touristType } = this.state;
    this.getListOfRecipients(filterType, touristType);
  }

  componentDidMount() {
    const { filterType, touristType } = this.state;
    this.getListOfRecipients(filterType, touristType);
  }

  render() {
    const { recipients, count, pageNumber, touristType, searchQuery } =
      this.state;
    const { isOpened, isListLoading } = this.props;

    return (
      <div>
        {isOpened ? (
          <div>
            <form
              className="search"
              onSubmit={(e) => {
                e.preventDefault();
                this.handleSearch(e);
              }}
            >
              <input
                type="text"
                className="form-control search__input"
                value={searchQuery}
                onChange={this.handleInput}
                placeholder="ФИО, email, телефон"
                title="ФИО, номер телефона или email"
                disabled={isListLoading}
                required
              />
              <button
                className="search__btn search__btn_submit"
                type="submit"
                disabled={isListLoading}
                title="Найти"
              >
                {isListLoading ? (
                  <AiOutlineLoading className="search__icon search__icon-loading" />
                ) : (
                  <AiOutlineSearch className="search__icon" />
                )}
              </button>
              <button
                className="search__btn search__btn_reset"
                type="button"
                disabled={isListLoading}
                title="Сбросить"
                onClick={this.reset}
              >
                <AiOutlineCloseCircle className="search__icon" />
              </button>
            </form>
            <div className="list">
              {touristType === 'touristfp' ||
              touristType === 'persons' ||
              touristType === 'abandonedcustomers'
                ? recipients.map((item) => {
                    let name = '-';
                    if (item.full_name) {
                      name = item.full_name;
                    } else if (item.fio) {
                      name = item.fio;
                    } else if (item.name) {
                      name = item.name;
                    }
                    return (
                      <div className="list__item" key={item.id}>
                        <h4 className="list__item-header">{name}</h4>
                        <div className="list__item-info-container">
                          <div className="list__item-info">
                            {item.email && item.email.length
                              ? item.email[0].value
                              : 'Нет e-mail'}
                          </div>
                          <div className="list__item-info">
                            {item.phone &&
                            item.phone.length &&
                            item.phone[0].value
                              ? item.phone[0].value
                              : 'Нет телефона'}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
              {touristType === 'franchise'
                ? recipients.map((item) => {
                    return (
                      <div className="list__item" key={item.id}>
                        <h4 className="list__item-header">
                          {item.director ? item.director : '-'}
                        </h4>
                        <div className="list__item-info-container">
                          <div>{item.email ? item.email : 'Нет E-mail'}</div>
                          <div>
                            {item.phones ? item.phones : 'Нет телефона'}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
              {touristType === 'cabinet'
                ? recipients.map((item) => {
                    return (
                      <div className="list__item" key={item.id}>
                        <h4 className="list__item-header">
                          {item.name ? item.name : '-'}
                        </h4>
                        <div className="list__item-info-container">
                          <div className="list__item-info">
                            {item.email_registered && item.email_settings
                              ? item.email_settings
                              : item.email_registered}
                          </div>
                          <div className="list__item-info">
                            {item.phone_settings && item.phone_settings.length
                              ? item.phone_settings
                              : 'Нет телефона'}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
              {touristType === 'franchise_leads'
                ? recipients.map((item) => {
                    return (
                      <div className="list__item" key={item.id}>
                        <h4 className="list__item-header">
                          {item.applicant.name ? item.applicant.name : '-'}
                        </h4>
                        <div className="list__item-info-container">
                          <div>
                            {item.applicant.email && item.applicant.email.length
                              ? item.applicant.email
                              : 'Нет e-mail'}
                          </div>
                          <div>
                            {item.applicant.phone && item.applicant.phone.length
                              ? item.applicant.phone
                              : 'Нет телефона'}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
              {touristType === 'subscription'
                ? recipients.map((item) => {
                    return (
                      <div className="list__item" key={item.id}>
                        <h4 className="list__item-header">
                          {item.name ? item.name : '-'}
                        </h4>
                        <div className="list__item-info-container">
                          <div>{item.email ? item.email : 'Нет e-mail'}</div>
                          <div>{item.phone ? item.phone : 'Нет телефона'}</div>
                        </div>
                      </div>
                    );
                  })
                : ''}
              <Paginator
                total={count}
                onPageChange={this.handleOnPageChange}
                forcePage={pageNumber - 1}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default RecipientsList;
