import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';
import { CabinetAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client';
import 'react-datepicker/dist/react-datepicker.css';

export class TouristCabinetFilter extends Component {
  constructor(props) {
    super(props);
    this.onFilter = this.onFilter.bind(this);
    this.handleLikes = this.handleLikes.bind(this);
    this.handleCart = this.handleCart.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.startDateInteractionChange =
      this.startDateInteractionChange.bind(this);
    this.endDateInteractionChange = this.endDateInteractionChange.bind(this);
    this.startDateRegistrationChange =
      this.startDateRegistrationChange.bind(this);
    this.endDateRegistrationChange = this.endDateRegistrationChange.bind(this);
    this.dtSyncChange = this.dtSyncChange.bind(this);
    this.handleInput = this.handleInput.bind(this);

    this.state = {
      lastName: '',
      name: '',
      startDateInteraction: '',
      endDateInteraction: '',
      startDateRegistration: '',
      endDateRegistration: '',
      phone_settings: '',
      likes: '',
      cart: '',
      users: [],
      phone: '',
      email: '',
      isLoading: false,
    };
  }

  startDateInteractionChange(startDateInteraction) {
    this.setState({ startDateInteraction });
  }

  endDateInteractionChange(endDateInteraction) {
    this.setState({ endDateInteraction });
  }

  startDateRegistrationChange(startDateRegistration) {
    this.setState({ startDateRegistration });
  }

  endDateRegistrationChange(endDateRegistration) {
    this.setState({ endDateRegistration });
  }

  dtSyncChange(dtSync) {
    this.setState({ dtSync });
  }

  handleLikes(likes) {
    this.setState({ likes }, () => {
      this.onFilter();
    });
  }

  handleCart(cart) {
    this.setState({ cart }, () => {
      this.onFilter();
    });
  }

  handleInput(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleReset() {
    this.setState(
      {
        lastName: '',
        name: '',
        startDateInteraction: '',
        endDateInteraction: '',
        startDateRegistration: '',
        endDateRegistration: '',
        phone_settings: '',
        likes: '',
        cart: '',
      },
      this.onFilter
    );
  }

  onFilter() {
    let filter = {};

    if (this.state.phone) {
      filter.phone_settings = this.state.phone;
    }
    if (this.state.email) {
      filter.email_settings = this.state.email;
    }
    if (this.state.lastName) {
      filter.last_name = this.state.lastName;
    }
    if (this.state.name) {
      filter.name = this.state.name;
    }
    if (this.state.likes === '') {
    } else if (this.likes !== 0) {
      filter.count_likes = this.state.likes.map((l) => l.value);
    }
    if (this.state.cart === '') {
    } else if (this.cart !== 0) {
      filter.count_cart = this.state.cart.map((c) => c.value);
    }
    if (this.state.startDateInteraction) {
      filter.dt_sync__gte = moment(this.state.startDateInteraction).format(
        'DD.MM.YYYY'
      );
    }
    if (this.state.endDateInteraction) {
      filter.dt_sync__lte = moment(this.state.endDateInteraction).format(
        'DD.MM.YYYY'
      );
    }
    if (this.state.startDateRegistration) {
      filter.dt_registered__gte = moment(
        this.state.startDateRegistration
      ).format('DD.MM.YYYY');
    }
    if (this.state.endDateRegistration) {
      filter.dt_registered__lte = moment(this.state.endDateRegistration).format(
        'DD.MM.YYYY'
      );
    }
    this.props.onFilter(filter);
  }

  fetchUsers() {
    this.setState({ isLoading: true });
    let self = this;
    let status;
    CabinetAPI.fetchJSON(`full_list/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        self.setState({
          users: status === POSITIVE_ACTION_STATUSES.list ? r : [],
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchUsers();
  }

  render() {
    const {
      phone,
      email,
      lastName,
      name,
      users,
      startDateInteraction,
      endDateInteraction,
      startDateRegistration,
      endDateRegistration,
      likes,
      cart,
      isLoading,
    } = this.state;

    function removeDuplicates(users, field) {
      const output = [];
      for (let i = 0; i < users.length; i++) {
        if (!output.includes(users[i][field])) {
          output.push(users[i][field]);
        }
      }
      return output.sort((a, z) => a - z);
    }

    const likesUnique = removeDuplicates(users, 'count_likes');
    const CABINET_LIKES = likesUnique.map((el) => {
      return {
        value: el.toString(),
        label: el.toString(),
      };
    });

    const cartUnique = removeDuplicates(users, 'count_cart');
    const CABINET_CART = cartUnique.map((el) => {
      return {
        value: el.toString(),
        label: el.toString(),
      };
    });

    return (
      <div className="animated fadeIn filter-container">
        <div className="filter filter--blue">
          <div className="card card--search-bg">
            <div className="card-header">
              <strong>Кабинет туриста</strong>
            </div>
            <div className="card-body">
              <div className="filter-row">
                <div className="filter-field">
                  <label htmlFor="" className="col-form-label">
                    Фамилия
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    placeholder="Фамилия"
                    name="lastName"
                    value={lastName}
                    onChange={this.handleInput}
                  />
                </div>
                <div className="filter-field">
                  <label htmlFor="" className="col-form-label">
                    Имя
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Имя"
                    name="name"
                    value={name}
                    onChange={this.handleInput}
                  />
                </div>
                <div className="filter-field filter__count">
                  <Select
                    id="likes"
                    name="likes"
                    placeholder="Понравилось"
                    isMulti={true}
                    simpleValue={true}
                    closeOnSelect={true}
                    value={likes}
                    onChange={this.handleLikes}
                    options={CABINET_LIKES}
                    isLoading={isLoading}
                  />
                </div>
                <div className="filter-field filter__count">
                  <Select
                    id="cart"
                    name="cart"
                    placeholder="В корзине"
                    isMulti={true}
                    simpleValue={true}
                    closeOnSelect={true}
                    value={cart}
                    onChange={this.handleCart}
                    options={CABINET_CART}
                    isLoading={isLoading}
                  />
                </div>
              </div>
              <div className="filter-row ">
                <div className="filter-field date-from">
                  <div className="date-from">
                    <DatePicker
                      locale={ru}
                      dateFormat="dd.MM.yyyy"
                      selected={startDateInteraction}
                      selectsStart
                      startDate={startDateInteraction}
                      endDate={endDateInteraction}
                      onChange={this.startDateInteractionChange}
                      className="d-block"
                      placeholderText="Дата взаимодействия ОТ"
                    />
                  </div>
                </div>
                <div className="filter-field date-to">
                  <div className="date-to">
                    <DatePicker
                      locale={ru}
                      dateFormat="dd.MM.yyyy"
                      selected={endDateInteraction}
                      selectsEnd
                      startDate={startDateInteraction}
                      endDate={endDateInteraction}
                      onChange={this.endDateInteractionChange}
                      placeholderText="Дата взаимодействия ДО"
                    />
                  </div>
                </div>
                <div className="filter-field date-from">
                  <div className="date-from">
                    <DatePicker
                      locale={ru}
                      dateFormat="dd.MM.yyyy"
                      selected={startDateRegistration}
                      selectsStart
                      startDate={startDateRegistration}
                      endDate={endDateRegistration}
                      onChange={this.startDateRegistrationChange}
                      className="d-block"
                      placeholderText="Дата регистрации ОТ"
                    />
                  </div>
                </div>
                <div className="filter-field date-to">
                  <div className="date-to">
                    <DatePicker
                      locale={ru}
                      dateFormat="dd.MM.yyyy"
                      selected={endDateRegistration}
                      selectsEnd
                      startDate={startDateRegistration}
                      endDate={endDateRegistration}
                      onChange={this.endDateRegistrationChange}
                      placeholderText="Дата регистрации ДО"
                    />
                  </div>
                </div>
              </div>
              <div className="filter-row">
                <div className="filter-field">
                  <label htmlFor="" className="col-form-label">
                    Телефон
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="Телефон"
                    name="phone"
                    value={phone}
                    onChange={this.handleInput}
                  />
                </div>
                <div className="filter-field">
                  <label htmlFor="" className="col-form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={this.handleInput}
                  />
                </div>
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.onFilter}
                >
                  Найти
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  style={{ backgroundColor: 'transparent' }}
                  onClick={this.handleReset}
                >
                  Сбросить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
