import React, { Component } from 'react';
import ClientAPI, {
  ExcelAPI,
  IssuesAPI,
  PersonAPI,
} from '../../../api/Client/Client';
import { Alert } from 'reactstrap';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/ru.json';

import FileUpload from '../../../components/FileUpload';
import { getCurrentOfficeId, getUserInfo } from '../../../connect/auth';

export default class IssueAddForm extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.getReportExcel = this.getReportExcel.bind(this);

    this.state = {
      is_show: false,
      title: '',
      text: '',
      source: '',
      name: '',
      phone: '',
      email: '',
      destination_country: '',
      city: '',
      person: this.props.person || false,
      showErrorMessage: false,
      showSuccessMessage: false,
      errorMessages: {},
      showErrorUpload: false,
      showSuccessUpload: false,
      uploadedFiles: [],
      action: null,
    };
  }

  toggle() {
    this.setState({
      ...this.state,
      is_show: !this.state.is_show,
      showErrorUpload: false,
      showSuccessUpload: false,
      uploadedFiles: [],
      action: null,
    });
    if (this.props.toggleForm) {
      this.props.toggleForm();
    }
  }

  uploadTourists() {
    console.log('загрузить из файла');
  }
  getReportExcel() {
    ClientAPI.getJSON('/excel_reports/').then((res) => console.log(res));
  }
  handleFileUpload(files, action = 'upload') {
    // debugger;
    let formData;
    let status;
    if (files.length) {
      if (action === 'upload') {
        formData = new FormData();
        formData.append('tourist', this.state.itemId);
        files.map((file) => formData.append('file_path', file));

        ExcelAPI.upload(formData)
          .then((r) => {
            status = r.status;
            return r.json();
          })
          .then((r) => {
            if (status === 201) {
              const uploadedFiles = [];
              uploadedFiles.push(r);
              this.setState({
                ...this.state,
                showSuccessUpload: true,
                showErrorUpload: false,
                uploadedFiles: uploadedFiles,
                action: action,
              });
            } else {
              this.setState({
                ...this.state,
                showSuccessUpload: false,
                showErrorUpload: true,
                uploadedFiles: [],
                action: action,
              });
            }
          });
      } else if (action === 'delete') {
        ExcelAPI.delete(files[0].id).then((r) => {
          this.setState({
            ...this.state,
            showSuccessUpload: r.status === 204,
            showErrorUpload: r.status !== 204,
            action: action,
          });
        });
      }
    }
  }

  handleInput(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  getValidPhone(phone) {
    return '+' + phone;
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = {
      name: this.state.name,
      issues: [
        {
          title: this.state.title,
          text: this.state.text,
          source: this.state.source,
          city: this.state.city,
          destination_country: this.state.destination_country,
        },
      ],
      tag: [],
      person_info: {
        is_sms_notify: true,
        is_email_notify: true,
      },
    };
    if (this.state.phone) {
      data.phone = [{ value: this.getValidPhone(this.state.phone) }];
    }
    if (this.state.email) {
      data.email = [{ value: this.state.email }];
    }

    if (this.state.person) {
      PersonAPI.create(data).then((result) => {
        result.person_info
          ? this.successSubmit(result)
          : this.errorMessage(result);
      });
    } else {
      const userInfo = getUserInfo();
      let data = {
        title: this.state.title,
        text: this.state.text,
        source: this.state.source,
        city: this.state.city,
        destination_country: this.state.destination_country,
        type: this.props.type,
        office: getCurrentOfficeId(),
        manager: userInfo.user_id,
      };
      if (this.props.type === 'person') {
        data.person = this.props.tourist;
      } else {
        data.tourist = this.props.tourist;
      }
      IssuesAPI.create(data).then((result) => {
        result.type ? this.successSubmit(result) : this.errorMessage(result);
      });
    }
  }

  successSubmit(r) {
    this.setState({
      ...this.state,
      title: '',
      text: '',
      source: '',
      showErrorMessage: false,
      showSuccessMessage: true,
      errorMessages: {},
    });
  }

  errorMessage(err) {
    this.setState({
      ...this.state,
      showErrorMessage: err,
      errorMessages: err,
    });
  }

  render() {
    const messages = {
      upload: {
        error: `Ошибка. Не удалось загрузить файл`,
        success: `Файл успешно загружен и будет обработан в течение 10 минут`,
      },
      delete: {
        error: `Ошибка. Не удалось удалить файл`,
        success: `Файл успешно удален`,
      },
    };
    const issuesErrorMessages = this.state.errorMessages
      ? this.state.person && this.state.errorMessages.issues
        ? this.state.errorMessages.issues[0]
        : this.state.errorMessages
      : null;
    const {
      is_show,
      showErrorUpload,
      showErrorMessage,
      showSuccessUpload,
      showSuccessMessage,
      uploadedFiles,
      action,
      person,
    } = this.state;
    const { isFormVisible } = this.props;
    return (
      <div>
        {is_show ? (
          <div className="card">
            <div className="card-body">
              <form
                action=""
                method="post"
                encType="multipart/form-data"
                className="form-horizontal"
              >
                {person ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="name">Фамилия Имя Отчество</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Иванов Иван Иванович"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleInput}
                      />
                      {this.state.errorMessages &&
                      this.state.errorMessages['name'] ? (
                        <div className="invalid-feedback">
                          {this.state.errorMessages['name'][0]}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Телефон</label>
                      <PhoneInput
                        country={'ru'}
                        localization={ru}
                        onlyCountries={['ru', 'kz', 'by']}
                        countryCodeEditable={false}
                        value={this.state.phone}
                        onChange={(phone) => this.setState({ phone })}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Электронная почта</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="email@mail.com"
                        value={this.state.email}
                        onChange={this.handleInput}
                      />
                      {this.state.errorMessages &&
                      this.state.errorMessages['emails'] ? (
                        <div className="invalid-feedback">
                          {this.state.errorMessages['emails'][0]}
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
                <div className="form-group row">
                  <label
                    className="col-md-3 form-control-label"
                    htmlFor="text-input"
                  >
                    Название
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      id="text-input"
                      name="title"
                      className={
                        issuesErrorMessages && issuesErrorMessages['title']
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      placeholder="Введите название запроса"
                      onChange={this.handleInput}
                    />
                    {issuesErrorMessages &&
                    issuesErrorMessages.title &&
                    issuesErrorMessages.title.length
                      ? issuesErrorMessages['title'].map((item) => (
                          <div className="invalid-feedback">{item}</div>
                        ))
                      : ``}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="col-md-3 form-control-label"
                    htmlFor="textarea-input"
                  >
                    Текст запроса
                  </label>
                  <div className="col-md-9">
                    <textarea
                      name="text"
                      rows="7"
                      className="form-control textarea-input"
                      placeholder="Введите сообщение с данными о туре"
                      onChange={this.handleInput}
                    ></textarea>
                    {issuesErrorMessages &&
                    issuesErrorMessages.text &&
                    issuesErrorMessages.text.length
                      ? issuesErrorMessages['text'].map((item) => (
                          <div className="invalid-feedback">{item}</div>
                        ))
                      : ``}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="col-md-3 form-control-label "
                    htmlFor="select"
                  >
                    Город вылета
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      name="city"
                      placeholder="Укажите город вылета"
                      onChange={this.handleInput}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="col-md-3 form-control-label "
                    htmlFor="select"
                  >
                    Страна назначения
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      name="destination_country"
                      placeholder="Укажите страну назначения"
                      onChange={this.handleInput}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="col-md-3 form-control-label "
                    htmlFor="select"
                  >
                    Источник
                  </label>
                  <div className="col-md-9">
                    <select
                      id="select"
                      name="source"
                      className="form-control"
                      onChange={this.handleInput}
                    >
                      <option value="">Выберите источник</option>
                      <option value="call_to_office">Звонок в офис</option>
                      <option value="call_to_phone">Звонок на сотовый</option>
                      <option value="visitor">Посетитель</option>
                      <option value="recommendation">
                        Обращение по рекомендации
                      </option>
                      <option value="permanent">Постоянный клиент</option>
                      <option value="instagram">Инстаграм</option>
                      <option value="vkontakte">В контакте</option>
                      <option value="facebook">Фейсбук</option>
                      <option value="odnoklasniki">Однокласники</option>
                      <option value="socials">Соцсети</option>
                      <option value="mailing">Рассылки</option>
                      <option value="mailing_whatsapp">
                        Рассылка WhatsApp
                      </option>
                      <option value="viber">Вайбер</option>
                      <option value="telegram">Телеграм</option>
                      <option value="telegram_bot">Телеграм Бот</option>
                      <option value="whatsapp">WhatsApp</option>
                      <option value="yandex">Яндекс</option>
                      <option value="google">Google</option>
                      <option value="geo_site">Сайт</option>
                      <option value="geo_site_chat">Сайт (чат)</option>
                      <option value="cabinet_tourist">Кабинет туриста</option>
                      <option value="tourvisor">ТурВизор онлайн</option>
                      <option value="tourvisor_office">ТурВизор офис</option>
                    </select>
                    {issuesErrorMessages &&
                    issuesErrorMessages.source &&
                    issuesErrorMessages.source.length ? (
                      <div className="invalid-feedback">Укажите источник</div>
                    ) : (
                      ``
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div
                    className="col-md-12"
                    style={{
                      display: 'flex',
                      gap: '5px',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <button
                      type="reset"
                      className="btn btn-sm btn-default"
                      onClick={this.toggle}
                    >
                      <i className="fa fa-ban"></i> Отмена
                    </button>
                    <button
                      type="submit"
                      className="btn btn-sm btn-success"
                      onClick={this.handleSubmit}
                    >
                      <i className="fa fa-check"></i> Создать
                    </button>
                  </div>
                </div>
                {showErrorMessage ? (
                  <Alert color="warning">
                    <strong>Ошибка!</strong> Не удалось создать
                  </Alert>
                ) : null}
                {showSuccessMessage ? (
                  <Alert color="success">
                    <strong>Запрос создан!</strong> Обновите страницу
                  </Alert>
                ) : (
                  ''
                )}
              </form>
            </div>
          </div>
        ) : (
          <div>
            {showErrorUpload || showSuccessUpload ? (
              <div className="row">
                <div className="col-md-12">
                  <Alert className={showErrorUpload ? `bg-danger` : ``}>
                    {showErrorUpload
                      ? messages[action]['error']
                      : messages[action]['success']}
                  </Alert>
                </div>
              </div>
            ) : (
              ``
            )}
            <div className="issue__button-container mb-2">
              <button
                className="btn btn-pink"
                onClick={this.toggle}
                style={{
                  width: 'auto',
                  backgroundColor: '#de4596',
                  borderColor: '#de4596',
                }}
              >
                <i className="fa fa-plus mr-3"></i>
                Добавить запрос
              </button>
              <FileUpload
                multiple={false}
                onChange={this.handleFileUpload}
                accept="*.xslx"
                name="filename"
                uploadedFiles={uploadedFiles}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
