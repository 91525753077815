import React, { Component } from 'react';
import Paginator from '../../components/Paginator';
import FilterPersons from '../Components/FilterPersons';
import { Link } from 'react-router-dom';
import APIClient, {
  PersonAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../api/Client';
import { Alert, Button } from 'reactstrap';
import { getUserRole, SUPER_ADMIN } from '../../connect/auth';
import Toggle from '../Notify/components/Toggle';
import { handlePersonFilters } from '../Components/FilterPersons';
import { ISSUE_SOURCE_MAP } from '../Issues/constants';
import ProgressBar from '../../components/ProgressBar';
import Swal from 'sweetalert';
import { clearAllFilterParams } from '../../Utils/clearEmailfilter';

export default class Persons extends Component {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.toggleItem = this.toggleItem.bind(this);
    this.createMergers = this.createMergers.bind(this);
    this.toggleNotify = this.toggleNotify.bind(this);
    this.createMail = this.createMail.bind(this);
    this.createMailWarning = this.createMailWarning.bind(this);
    this.deletePerson = this.deletePerson.bind(this);

    this.state = {
      items: [],
      pageNumber: 1,
      count: 0,
      filters: {},
      selected: [],
      mergerIsError: false,
      mergerIsSuccess: false,
      errorMessages: [],
      mergerRelations: [],
      isDisabledMergerButton: false,
      toggleErrors: {},
      errors: {},
      isSuccess: false,
      isLoading: false,
    };

    clearAllFilterParams();
  }
  createMail() {
    if (this.state.filters) {
      localStorage.setItem(
        'persons_filter',
        JSON.stringify(this.state.filters)
      );
      localStorage.setItem('persons_total', JSON.stringify(this.state.count));
      localStorage.setItem('type', 'persons');
    }
    return false;
  }

  createMailWarning() {
    Swal(`Для создания рассылки выберите фильтры по e-mail, телефону. Можно выбрать по меткам.

      После этого список будет сформирован.`);
  }

  deletePerson() {
    let status;
    const { pageNumber, selected } = this.state;

    PersonAPI.deletePersons(selected)
      .then((r) => {
        status = r.status;
        if (status === 400) {
          return r.json();
        } else if (status === 403) {
          this.setState({
            errors: {
              detail:
                'У вас недостаточно прав для выполнения данного действия.',
            },
            isSuccess: false,
          });
        }
        return {};
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.destroy) {
          this.setState(
            {
              isSuccess: true,
              selected: [],
              errors: null,
            },
            () => this.fetchItems(pageNumber)
          );
        } else if (status === 400) {
          this.setState({
            errors: r,
            isSuccess: false,
          });
        }
      });
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState(
      {
        pageNumber: page,
      },
      () => this.fetchItems(page)
    );
  }

  filterItems(st) {
    const filters = handlePersonFilters(st);
    if (filters) {
      this.setState({ filters }, () => this.fetchItems());
    }
  }

  toggleNotify(person, type) {
    const newItems = [...this.state.items];
    const { person_info } = person;
    const key = `is_${type}_notify`;
    let status;

    APIClient.update(`/persons_info/${person_info.id}`, {
      [key]: !person_info[key],
    })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.update) {
          const index = newItems.findIndex((item) => item.id === person.id);
          if (index !== -1) {
            newItems[index] = { ...newItems[index], person_info: r };
            this.setState({
              items: newItems,
            });
          }
        } else {
          this.setState({
            toggleErrors: { [person_info.id]: r },
          });
        }
      });
  }

  fetchItems(page = 1) {
    const filters = { ...this.state.filters };
    filters.is_active = true;
    let status;
    this.setState(
      {
        ...this.state,
        isLoading: true,
      },
      () => {
        PersonAPI.fetchList(page, filters)
          .then((r) => {
            status = r.status;
            return r.json();
          })
          .then((r) => {
            if (status === POSITIVE_ACTION_STATUSES.list) {
              this.setState({
                items: r.results || [],
                count: r.count,
                selected: [],
                mergerIsError: false,
                mergerIsSuccess: false,
                errorMessages: [],
                mergerRelations: [],
                isDisabledMergerButton: false,
                toggleErrors: {},
                isLoading: false,
              });
            }
          });
      }
    );
  }

  toggleItem(item) {
    let selected = [...this.state.selected];

    if (selected.includes(item.id)) {
      selected = selected.filter((pk) => pk !== item.id);
    } else {
      selected = selected.concat(item.id);
    }
    selected.sort((a, b) => {
      return b - a;
    });

    this.setState({
      mergerIsError: false,
      mergerIsSuccess: false,
      selected: selected,
      isDisabledMergerButton: false,
      toggleErrors: {},
    });
  }

  createMergers(e) {
    e.preventDefault();
    if (this.state.selected.length < 2) {
      Swal('выберите клиентов для слияния');
      return false;
    }
    this.setState(
      {
        isDisabledMergerButton: true,
      },
      () => {
        let status = 200;
        const selected = [...this.state.selected];
        const main = selected.shift();
        const mergerRelations = [];
        const query = {
          duplicates: selected.map((elem) => {
            mergerRelations.push({ duplicate: elem, person: main });
            return { duplicate: elem };
          }),
        };
        PersonAPI.update(`/persons/${main}/duplicates`, query)
          .then((r) => {
            status = r.status;
            return r.json();
          })
          .then((r) => {
            try {
              if (status === 200) {
                this.setState({
                  mergerIsSuccess: true,
                  mergerIsError: false,
                  errorMessages: {},
                  mergerRelations: mergerRelations,
                  selected: [],
                  isDisabledMergerButton: false,
                  toggleErrors: {},
                });
              } else {
                this.setState({
                  mergerIsSuccess: false,
                  mergerIsError: true,
                  errorMessages: !r.duplicates ? r : r.duplicates,
                  mergerRelations: mergerRelations,
                  isDisabledMergerButton: false,
                  toggleErrors: {},
                });
              }
            } catch (e) {
              this.setState({
                mergerIsSuccess: false,
                mergerIsError: true,
                errorMessages: { non_field_errors: ['Неизвестная ошибка'] },
                mergerRelations: mergerRelations,
                isDisabledMergerButton: false,
                toggleErrors: {},
              });
            }
          });
      }
    );
  }

  componentDidMount() {
    this.fetchItems();
  }

  render() {
    const {
      mergerIsSuccess,
      pageNumber,
      count,
      filters,
      mergerIsError,
      mergerRelations,
      selected,
      errorMessages,
      errors,
      isSuccess,
      items,
      isDisabledMergerButton,
      toggleErrors,
    } = this.state;

    const prepareErrorMessages = [];
    if (mergerIsError) {
      if (Array.isArray(errorMessages) && errorMessages.length) {
        errorMessages.forEach((item, index) => {
          ['non_field_errors', 'duplicate'].map((f) => {
            if (item[f]) {
              item[f].forEach((message) => {
                prepareErrorMessages.push(
                  `Слияние №${mergerRelations[index].person}-№${mergerRelations[index].duplicate}: ${message}`
                );
              });
            }
          });
        });
      } else if (typeof errorMessages === 'object') {
        ['non_field_errors', 'duplicates'].forEach((f) => {
          if (Array.isArray(errorMessages[f]) && errorMessages[f].length) {
            errorMessages[f].map((message) =>
              prepareErrorMessages.push(`Ошибка запроса: ${message}`)
            );
          }
        });
        if (typeof errorMessages['detail'] === 'string') {
          prepareErrorMessages.push(
            `Ошибка запроса: ${errorMessages['detail']}`
          );
        }
      }
    }
    const userRole = getUserRole();
    const isSuperAdmin = userRole === SUPER_ADMIN;
    const persons = items.map((person) => {
      const { person_info } = person;
      return (
        <tr key={`person-${person.id}`}>
          <td className="text-center tableItem hidden-mobile">
            <input
              type="checkbox"
              disabled={!person.is_active}
              onChange={() => this.toggleItem(person)}
              checked={selected.includes(person.id)}
            />
          </td>
          <td className="hidden-mobile">
            <Link to={`/persons/${person.id}`}>{person.id}</Link>
          </td>
          <td>
            <Link to={`/persons/${person.id}`}>{person.fio}</Link>
          </td>
          {person.source && ISSUE_SOURCE_MAP[person.source] ? (
            <td className="text-left tableItem">
              <i
                className={`fa ${
                  ISSUE_SOURCE_MAP[person.source].icon || 'fa-star'
                }`}
                style={{
                  fontSize: '14px',
                  backgroundColor: '#E4087E',
                  textAlign: 'center',
                  color: '#fff',
                  padding: '5px',
                  borderRadius: '30px',
                  width: '24px',
                  height: '24px',
                  marginRight: '10px',
                }}
              >
                {''}
              </i>
              {ISSUE_SOURCE_MAP[person.source].name}
            </td>
          ) : (
            <td className="text-left tableItem">{'не указан'}</td>
          )}
          <td>
            {Array.isArray(person.phone) &&
              person.phone.map((i, idx) => (
                <a key={idx} href={`tel://${i.value}`}>
                  {i.value}
                </a>
              ))}
          </td>
          <td className="hidden-mobile">
            {Array.isArray(person.email) &&
              person.email
                .map((i) => i.value)
                .filter((i) => i)
                .join(`, `)}
          </td>
          {/* <td className="hidden-mobile text-center">{ person_info.has_viber ? <i className="fa fa-check" style={{color: "green"}}></i> : "" }</td> */}
          <td className="hidden-mobile">
            {Array.isArray(person.tag) &&
              person.tag.map((t) => (
                <span
                  className="badge badge-pill badge-success ml-2"
                  key={`person-${person.id}-tag-${t.id}`}
                >
                  {t.name}
                </span>
              ))}
          </td>
          <td>
            <div className="text-small-mobile flex-column-mobile">
              <Toggle
                id={person_info.id}
                lang="Email"
                checked={person_info.is_email_notify}
                onChange={() => this.toggleNotify(person, 'email')}
              />
              <Toggle
                id={person_info.id}
                lang="SMS"
                checked={person_info.is_sms_notify}
                onChange={() => this.toggleNotify(person, 'sms')}
              />
              {/* <Toggle
                  id={person_info.id}
                  lang="Viber"
                  checked={person_info.is_viber_notify}
                  onChange={() => this.toggleNotify(person, 'viber')}
                /> */}
              {!!toggleErrors[person_info.id] &&
              Object.keys(toggleErrors[person_info.id]).length
                ? Object.keys(toggleErrors[person_info.id]).map((key) => (
                    <Alert color="danger" key={key}>
                      {toggleErrors[person_info.id][key]}
                    </Alert>
                  ))
                : null}
            </div>
          </td>
          <td className="hidden-mobile">
            {person.office && <span>{person.office.address}</span>}
          </td>
        </tr>
      );
    });

    return (
      <div className="animated fadeIn">
        <div className="filter filter--green">
          <FilterPersons name="" onFilter={this.filterItems} />
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header card-header__container">
                <div className="card-header__info-group">
                  <span className="badge badge-info">
                    <i className="fa fa-align-justify hidden-mobile">{''}</i>
                    Всего&nbsp;{count}
                  </span>
                  <div className="" hidden={!(selected.length >= 1)}>
                    <Button
                      className="btn btn-danger mr-2"
                      onClick={this.deletePerson}
                      type="button"
                    >
                      <span>
                        <i className="fa fa-close mr-2">{''}</i>Удалить{' '}
                        {selected.length}
                      </span>
                    </Button>
                  </div>
                  {mergerIsSuccess && mergerRelations.length ? (
                    <div className="row">
                      {mergerRelations.map((item) => (
                        <div
                          className="col-md-12"
                          key={`merger-${item.person}-${item.duplicate}`}
                        >
                          <Alert>
                            {`Слияние №${item.person}-№${item.duplicate}: будет обработано в течение 5 минут`}
                          </Alert>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {mergerIsError && prepareErrorMessages.length
                    ? prepareErrorMessages.map((item, index) => (
                        <Alert
                          className="bg-danger"
                          key={`alert-danger-${index}`}
                        >
                          {item}
                        </Alert>
                      ))
                    : null}
                </div>
                <div className="card-header__btn-group">
                  {!Object.keys(filters).length ? (
                    <button
                      className="btn btn-info mt-0"
                      onClick={this.createMailWarning}
                    >
                      <i className="fa fa-send mr-2">{''}</i>
                      Создать рассылку
                    </button>
                  ) : (
                    <Link
                      className="btn btn-success mt-0"
                      onClick={this.createMail}
                      to="/templates/"
                      style={{ display: 'inline-block' }}
                    >
                      <span>
                        <i className="fa fa-send mr-2">{''}</i>
                        Создать рассылку
                      </span>
                    </Link>
                  )}
                  <div className="">
                    <Button
                      className="btn btn-info"
                      onClick={this.createMergers}
                      type="button"
                      disabled={isDisabledMergerButton}
                    >
                      <span>
                        <i className="fa fa-tasks mr-2">{''}</i>Создать слияние
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
              {errors !== null &&
              typeof errors === 'object' &&
              Object.keys(errors).length
                ? Object.keys(errors).map((item, idx) => (
                    <Alert class="m-2" color="danger" key={idx}>
                      {errors[item]}
                    </Alert>
                  ))
                : null}
              {isSuccess ? <Alert>Успешно</Alert> : null}
              <div className="card-body card-body_responsive">
                {!items.length ? (
                  <div className="row animated fadeIn">
                    <div className="col-md-12 col-md-offset-6 text-center">
                      {this.state.isLoading ? (
                        <ProgressBar />
                      ) : (
                        <p>Потенциальных клиентов не найдено</p>
                      )}
                    </div>
                  </div>
                ) : (
                  <table className="table table--green table-bordered table-striped table-sm">
                    <thead>
                      <tr>
                        <th className="hidden-mobile">*</th>
                        <th className="hidden-mobile">Id</th>
                        <th className="fio">ФИО</th>
                        <th className="source">Источник</th>
                        <th>Телефоны</th>
                        <th className="hidden-mobile">Еmail</th>
                        {/* <th className="hidden-mobile">Вайбер</th> */}
                        <th className="hidden-mobile">Метки</th>
                        <th>Уведомления</th>
                        <th className="hidden-mobile">Офис</th>
                      </tr>
                    </thead>
                    <tbody>{persons}</tbody>
                  </table>
                )}
                <Paginator
                  total={count}
                  onPageChange={this.handlePageChange}
                  forcePage={pageNumber - 1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
