import React, { Component } from 'react';

const EditInputEmail = ({ emails, onChange, errors }) => (
  <>
    {emails ? (
      <div className="col-md-10">
        {emails.map((email, idx) => (
          <div className="row mb-2" key={idx}>
            <div className="col-md-8 col-sm-12">
              <input
                type="email"
                className="form-control"
                value={email.value}
                onChange={(e) => {
                  const newEmails = [...emails];
                  newEmails[idx].value = e.target.value;
                  onChange(newEmails);
                }}
              />
            </div>
            <div className="col-md-2">
              <button
                onClick={() => {
                  const newEmails = [...emails];
                  let i = newEmails.indexOf(email);
                  newEmails.splice(i, 1);
                  onChange(newEmails);
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-remove"></i>
              </button>
            </div>
            {Array.isArray(errors) && errors[idx] && !!errors[idx].value ? (
              <p>{errors[idx].value}</p>
            ) : null}
          </div>
        ))}
        <button
          className="btn btn-success mt-2"
          onClick={() => onChange([...emails, { value: '' }])}
        >
          добавить почту
        </button>
      </div>
    ) : null}
  </>
);
export default EditInputEmail;
