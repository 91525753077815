import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import apiKey from '../../../constants/TinyMceApiKey';

const EditorWrapper = ({ message, updateMessage, isDisabled }) => {
  const handleEditorChange = (content, editor) => {
    updateMessage(content);
  };

  return (
    <Editor
      apiKey={apiKey}
      value={message}
      init={{
        language: 'ru',
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | formatselect | bold italic forecolor | \
          alignleft aligncenter alignright | link code | \
          bullist numlist  | removeformat',
        color_map: [
          '000000',
          'Black',
          '808080',
          'Gray',
          'FFFFFF',
          'White',
          'e5087f',
          'Pink',
          'ffcc4d',
          'Yellow',
          '019a47',
          'Green',
          '00a0e2',
          'Blue',
        ],
      }}
      onEditorChange={handleEditorChange}
      disabled={isDisabled}
    />
  );
};

export default EditorWrapper;
