import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Paginator from '../../../components/Paginator';
import APIClient, {
  POSITIVE_ACTION_STATUSES,
  TouristListsAPI,
} from '../../../api/Client';
import 'moment/locale/ru';
import { TouristViberListsFilter } from './TouristViberListsFilter';
import { getUserRole, SUPER_ADMIN } from '../../../connect/auth';
import Swal from 'sweetalert';
import { array } from 'prop-types';
import { BarLoader } from 'react-spinners';
import ProgressBar from '../../../components/ProgressBar';

class TouristViberLists extends Component {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.createMail = this.createMail.bind(this);
    this.setStrikesForRequest = this.setStrikesForRequest.bind(this);

    this.state = {
      isLoadingTable: true,
      isLoadingStrikes: true,
      items: [],
      all_strikes_for_addressbook: [],
      strikes_for_request: '',
      filter: '',
      pageNumber: 1,
      count: 0,
      selected: [],
    };

    localStorage.removeItem('abandonedcustomers_filter');
    localStorage.removeItem('franchise_filter');
    localStorage.removeItem('franchise_leads_filter');
    localStorage.removeItem('leads_filter');
    localStorage.removeItem('persons_filter');
    localStorage.removeItem('subscription_filter');
    localStorage.removeItem('tourist_filter');
    localStorage.removeItem('tourists_cabinet_filter');
    localStorage.removeItem('tourists_filter');
    localStorage.removeItem('tourists_report_filter');
    localStorage.removeItem('touristsfp_filter');

    localStorage.removeItem('leads_total');
    localStorage.removeItem('tourists_total');
    localStorage.removeItem('tourist_total');
    localStorage.removeItem('touristsfp_total');
    localStorage.removeItem('persons_total');
    localStorage.removeItem('tourists_cabinet_total');
    localStorage.removeItem('franchise_total');
    localStorage.removeItem('subscription_total');
    localStorage.removeItem('franchise_leads_total');
    localStorage.removeItem('tourists_report_type');
    localStorage.removeItem('tourists_report_address_books');
    localStorage.removeItem('abandonedcustomers_total');
    localStorage.removeItem('type');
  }

  handleFilter(filter) {
    this.setState(
      {
        ...this.state,
        filter: filter,
      },
      () => this.fetchItems()
    );
  }

  createMail() {
    Swal(
      `Создаем рассылку из отчета №${this.props.campaign.id} для ${this.state.count} клиентов`
    );
    localStorage.setItem(
      'tourists_report_filter',
      JSON.stringify(this.state.filter)
    );
    localStorage.setItem(
      'tourists_report_total',
      JSON.stringify(this.state.count)
    );
    localStorage.setItem(
      'tourists_report_type',
      JSON.stringify(this.props.campaign.dispatch_type)
    );
    localStorage.setItem(
      'tourists_report_address_books',
      JSON.stringify(this.props.campaign.address_book.id)
    );
    localStorage.setItem('type', 'report');
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({ pageNumber: page }, () =>
      this.fetchItems(page, this.state.strikes_for_request)
    );
  }

  fetchStrikes(bookId) {
    TouristListsAPI.getStrikes(bookId)
      // .then((r) => r.json())
      .then((r) => {
        this.setState({
          ...this.state,
          all_strikes_for_addressbook: r,
          isLoadingStrikes: false,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          ...this.state,
          isLoadingStrikes: false,
        });
      });
  }

  setStrikesForRequest(strikesString) {
    this.setState(
      {
        ...this.state,
        pageNumber: 1,
        strikes_for_request: strikesString,
      },
      () => this.fetchItems(1)
    );
  }

  fetchItems(page = 1) {
    this.setState({
      pageNumber: page,
      isLoadingTable: true,
    });
    let status;
    const { campaign } = this.props;
    let { filter } = this.state;
    let { strikes_for_request } = this.state;

    TouristListsAPI.fetchList(
      page,
      filter,
      `${campaign.address_book.id}/entries/`,
      strikes_for_request
    )
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            items: r.results,
            count: r.count,
            selected: [],
          });
        }
      })
      .finally(() => {
        this.setState({
          isLoadingTable: false,
        });
      });
  }

  toggleItem(item) {
    const { selected } = this.state;
    this.setState({
      selected: selected.includes(item)
        ? selected.filter((pk) => pk !== item)
        : selected.concat([item]),
    });
  }

  toggleEmailNotify(e) {
    let url = `persons_info`;
    if (e.target.dataset.clientType === 'natural_customer_info') {
      url = `natural_customers_info`;
    } else if (e.target.dataset.clientType === 'tourist_fp_info') {
      url = `touristsfp_info`;
    }

    APIClient.update(`/${url}/${e.target.dataset.clientId}`, {
      is_email_notify: e.target.checked,
    }).then((res) => {});
  }

  toggleSmsNotify(e) {
    let url = `persons_info`;
    if (e.target.dataset.clientType === 'natural_customer_info') {
      url = `natural_customers_info`;
    } else if (e.target.dataset.clientType === 'tourist_fp_info') {
      url = `touristsfp_info`;
    }
    APIClient.update(`/${url}/${e.target.dataset.clientId}`, {
      is_sms_notify: e.target.checked,
    }).then((res) => {});
  }

  componentDidMount() {
    let bookId = this.props.campaign.address_book.id;
    this.fetchStrikes(bookId);
    this.fetchItems(1);
  }

  render() {
    const { items, count } = this.state;
    const { campaign } = this.props;

    return (
      <div>
        <TouristViberListsFilter
          isLoadingStrikes={this.state.isLoadingStrikes}
          setStrikesForRequest={this.setStrikesForRequest}
          strikes={this.state.all_strikes_for_addressbook}
          onFilter={this.handleFilter}
        />
        <div className="card-header">
          <i className="fa fa-align-justify"></i> Всего&nbsp;
          <span className="badge badge-info">{count} шт.</span>
          <Link
            className="btn btn-success ml-3 mt-0"
            onClick={this.createMail}
            to="/templates/"
            style={{ display: 'inline-block' }}
          >
            <span>
              <i className="fa fa-send mr-2">{''}</i>
              Создать рассылку
            </span>
          </Link>
          {campaign.statistics_received
            ? ` Стоимость `
            : ` Предварительная стоимость `}
          <span className="badge badge-success">
            {campaign.cost.value}&nbsp;{campaign.cost.currency}
          </span>
        </div>
        <div className="card-body_responsive">
          {/*{this.state.isLoadingTable && <ProgressBar style={{minHeight:"20px"}}/>}*/}

          <table className="table table--green table-bordered table-striped table-sm">
            <thead>
              <tr>
                <th>Id</th>
                <th>ФИО</th>
                <th>Контакт</th>
                <th>Тип</th>
                <th>Состояние</th>
                <th>Статус</th>
                <th>Стоимость</th>
                <th>Переходы</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => {
                const field =
                  campaign.dispatch_type.id === 3 ? 'phones' : 'emails';
                let client = item.tourist || { id: 0 };
                let url = `tourists`;
                let type = 'турист';

                if (item.client_type.id === 2) {
                  client = item.person || { id: 0 };
                  url = `persons`;
                  type = 'потен. клиент';
                }

                if (item.client_type.id === 3) {
                  client = {
                    id: item.site_cabinet_user_id || 0,
                    full_name: item.first_name || '-',
                  };
                  url = `touristcabinets`;
                  type = 'кабинет';
                }

                if (item.client_type.id === 4) {
                  client = {
                    id: item.agency_id || 0,
                    full_name: item.first_name || '-',
                  };
                  url = `agencies`;
                  type = 'агентство';
                }

                if (item.client_type.id === 5) {
                  client = {
                    id: item.franchising_id || 0,
                    full_name: item.first_name || '-',
                  };
                  url = `franchising`;
                  type = 'заявка на франчайзинг';
                }

                if (campaign.dispatch_type.id === 1) {
                  let phoneSMS = item.phones[0].service_format;
                  let cost = item.phones[0].cost;
                  return (
                    <tr key={`${item.id}-${client.id}`}>
                      <td>
                        {<Link to={`/${url}/${client.id}`}>{client.id}</Link>}
                      </td>
                      <td>
                        {
                          <Link to={`/${url}/${client.id}`}>
                            {client.full_name}
                          </Link>
                        }
                      </td>
                      <td>{phoneSMS}</td>
                      <td>{type}</td>
                      <td>{}</td>
                      <td>-</td>
                      <td>{cost} руб.</td>
                    </tr>
                  );
                }

                if (Array.isArray(item[field]) && item[field].length) {
                  let strikes = [];
                  if (item[field][0].strikes && item[field][0].strikes.length) {
                    item[field][0].strikes.forEach((strike) => {
                      if (strike.url && strike.title) {
                        let html = (
                          <a href={strike.url} target="_blank">
                            {strike.title}
                          </a>
                        );
                        strikes.push(html);
                      }
                    });
                  }
                  return item[field].map((contact, idx) => (
                    <tr key={`${item.id}-${client.id}-${contact.entry}-${idx}`}>
                      <td>
                        {<Link to={`/${url}/${client.id}`}>{client.id}</Link>}
                      </td>
                      <td>
                        {
                          <Link to={`/${url}/${client.id}`}>
                            {client.full_name}
                          </Link>
                        }
                      </td>
                      <td>{contact.contact.value}</td>
                      <td>{type}</td>
                      <td>{contact.status.name}</td>
                      <td>
                        {contact.report_status && contact.report_status.name
                          ? contact.report_status.name
                          : '-'}
                      </td>
                      <td>{contact.cost ? contact.cost : '-'}</td>
                      <td>
                        {strikes.length
                          ? strikes.map((el) => <div key={el.title}>{el}</div>)
                          : '-'}
                      </td>
                    </tr>
                  ));
                } else {
                  return (
                    <tr key={`${item.id}-${client.id}`}>
                      <td>
                        {<Link to={`/${url}/${client.id}`}>{client.id}</Link>}
                      </td>
                      <td>
                        {
                          <Link to={`/${url}/${client.id}`}>
                            {client.full_name}
                          </Link>
                        }
                      </td>
                      <td>-</td>
                      <td>{type}</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          <Paginator
            total={count}
            onPageChange={this.handlePageChange}
            forcePage={this.state.pageNumber - 1}
          />
        </div>
      </div>
    );
  }
}

export default TouristViberLists;
