import React, { PureComponent } from 'react';
import APIClient, {
  AnswersAPI,
  IssuesAPI,
  PersonAPI,
  TouristFpAPI,
} from '../../../api/Client';
import LeadAddWithQuote from '../../Lead/components/LeadAddWithQuote';
import PersonWithQuote from '../../Person/components/PersonWithQuote';
import Swal from 'sweetalert';
import Select from 'react-select';
import TouristWithQuote from '../../TouristFp/components/TouristWithQuote';
import SearchBlock from './SearchBlock';
import IssueListItem from '../../Quotes/components/IssueListItem';

export default class QuoteTourists extends PureComponent {
  constructor(props) {
    super(props);
    this.handleAddPerson = this.handleAddPerson.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleTouristTypeChange = this.handleTouristTypeChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePersonSearch = this.handlePersonSearch.bind(this);
    this.handleTouristSearch = this.handleTouristSearch.bind(this);
    this.fetchClients = this.fetchClients.bind(this);
    this.handleClientSelect = this.handleClientSelect.bind(this);
    this.handleAddPersonToQuery = this.handleAddPersonToQuery.bind(this);
    this.resetClientToChoose = this.resetClientToChoose.bind(this);
    this.toggleLoadingStatus = this.toggleLoadingStatus.bind(this);
    this.handleIssuesListEmpty = this.handleIssuesListEmpty.bind(this);
    this.addQuoteToComment = this.addQuoteToComment.bind(this);
    this.resetAllIssues = this.resetAllIssues.bind(this);

    this.state = {
      quote_id: '',
      type: 'list',
      value: '',
      touristToAdd: '',
      personRequest: '',
      touristfpRequest: '',
      results: [],
      count: 0,
      personId: '',
      touristfpId: '',
      clientToChoose: {},
      personData: '',
      isLoading: false,
      personIssues: '',
      touristIssues: '',
      issuesListEmpty: false,
    };
  }

  options = [
    { id: 1, label: 'Новый клиент', type: 'newPerson' },
    { id: 2, label: 'Потенциальный клиент', type: 'persons' },
    { id: 3, label: 'Постоянный клиент', type: 'touristfp' },
  ];

  handleAddPerson() {
    this.setState({
      type: 'edit',
    });
  }

  handleUpdate() {
    this.setState({
      type: 'list',
      results: [],
      count: 0,
      clientToChoose: {},
      personRequest: '',
      touristfpRequest: '',
      touristfpId: '',
      personId: '',
      personData: '',
    });
    this.resetAllIssues();
  }

  resetClientToChoose() {
    this.setState({
      clientToChoose: {},
      touristfpId: '',
      personId: '',
    });
    this.resetAllIssues();
  }

  resetAllIssues() {
    this.setState({
      personIssues: '',
      touristIssues: '',
      issuesListEmpty: false,
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleCreate(personId) {
    Swal(
      `Добавляем в подборку ${this.props.quote_id} туриста с ID ${personId}`
    );
    if (personId && this.props.quote_id) {
      let Persons = this.props.personList;
      Persons.push(personId);
      APIClient.update(`/quotes/${this.props.quote_id}`, {
        persons: Persons,
      }).then((res) => {
        Swal(
          `Новый клиент ID ${personId} добавлен в подборку ${this.props.quote_id}`
        );
      });
    }
    this.handleUpdate();
  }

  handleTouristTypeChange(value) {
    this.setState({ type: value.type });
  }

  handlePersonSearch() {
    this.fetchClients(this.state.personRequest);
  }

  handleTouristSearch() {
    this.fetchClients(this.state.touristfpRequest);
  }

  toggleLoadingStatus(status) {
    this.setState({ isLoading: status });
  }

  fetchClients(request, page = 1) {
    this.resetClientToChoose();

    this.toggleLoadingStatus(true);
    if (this.state.type === 'persons') {
      PersonAPI.fetchList(page, { q: request })
        .then((r) => {
          return r.json();
        })
        .then(({ results, count }) => {
          this.setState({ results, count });

          this.toggleLoadingStatus(false);
        });
    } else if (this.state.type === 'touristfp') {
      TouristFpAPI.fetchList(page, { q: request })
        .then((r) => {
          return r.json();
        })
        .then(({ results, count }) => {
          this.setState({ results, count });
          this.toggleLoadingStatus(false);
        });
    }
  }

  handleClientSelect(value) {
    this.resetAllIssues();
    if (!value?.id) {
      return this.setState({
        clientToChoose: {},
        personId: '',
        touristfpId: '',
      });
    }
    if (value.tourist_fp_info) {
      this.setState({ clientToChoose: value, touristfpId: value.id });
      TouristFpAPI.fetchList(1, {}, `${value.id}/issues/`)
        .then((r) => {
          return r.json();
        })
        .then((r) => {
          if (r.count) {
            this.setState({
              touristIssues: r.results,
            });
          } else {
            this.handleIssuesListEmpty(true);
          }
        });
    } else if (value.person_info) {
      this.setState({ clientToChoose: value, personId: value.id });
      IssuesAPI.getList(1, { person: value.id }).then((r) => {
        if (r.count) {
          this.setState({
            personIssues: r.results,
          });
        } else {
          this.handleIssuesListEmpty(true);
        }
      });
    }
  }

  handleIssuesListEmpty(state) {
    this.setState({ issuesListEmpty: state });
  }

  handleAddPersonToQuery() {
    const {
      touristfpId,
      personId,
      touristIssues,
      personIssues,
      clientToChoose,
    } = this.state;
    if (touristfpId) {
      const { touristsFpList, quote_id } = this.props;
      if (touristsFpList.some((tourist) => tourist.id === touristfpId)) {
        return Swal(
          `Ошибка. Постоянный клиент ID ${touristfpId} уже добавлен в подборку ${quote_id}`
        );
      } else {
        touristsFpList.push(clientToChoose);

        if (touristIssues[0]?.id) {
          this.addQuoteToComment(touristIssues, quote_id);
        }

        APIClient.update(`/quotes/${quote_id}`, {
          touristsfp: touristsFpList.map((t) => t.id),
        })
          .then((res) => {
            Swal(
              `Постоянный клиент ID ${touristfpId} добавлен в подборку ${quote_id}`
            );
          })
          .catch((err) => {
            console.log(err);
            Swal(`Возникла ошибка: ${err}`);
          });
      }
    } else if (personId) {
      const { personList, quote_id } = this.props;
      if (personList.some((person) => person.id === personId)) {
        return Swal(
          `Ошибка. Постоянный клиент ID ${personId} уже добавлен в подборку ${quote_id}`
        );
      } else {
        personList.push(clientToChoose);

        if (personIssues[0].id) {
          this.addQuoteToComment(personIssues, quote_id);
        }

        APIClient.update(`/quotes/${quote_id}`, {
          persons: personList.map((p) => p.id),
        })
          .then((res) => {
            Swal(
              `Потенциальный клиент ID ${personId} добавлен в подборку ${quote_id}`
            );
          })
          .catch((err) => {
            console.log(err);
            Swal(`Возникла ошибка: ${err}`);
          });
      }
    }

    this.handleUpdate();
  }

  addQuoteToComment(client, quote_id) {
    let data = {
      issue: client[0].id,
      title: 'title',
      text: quote_id,
      type: 'tours',
    };
    AnswersAPI.create(data)
      .then((r) => {
        return r.json();
      })
      .catch((err) => {
        console.log(
          'Произошла ошибка при прикреплении подборки к запросу клиента',
          err
        );
        Swal(`Возникла ошибка: ${err}`);
      });
  }

  componentDidMount() {
    const { quote_id, personList, touristsFpList } = this.props;
    this.setState({
      quote_id,
      personList,
      touristsFpList,
    });
  }

  render() {
    const {
      type,
      touristToAdd,
      personRequest: personRequest,
      touristfpRequest: touristfpRequest,
      results,
      count,
      clientToChoose,
      isLoading,
      personIssues,
      touristIssues,
      issuesListEmpty,
    } = this.state;
    const { personList, touristsFpList, title, quote_id } = this.props;
    return (
      <div
        style={{ background: '#b5daed', padding: '10px', maxWidth: '600px' }}
        className="mt-2 mb-2"
      >
        <div className="geopick__clients-container">
          {type === 'newPerson' ? (
            <div>
              <LeadAddWithQuote
                source="Геоподборка"
                iconClass="fa-share-alt"
                term="quotes_from_crm"
                onUpdate={this.handleUpdate}
                onCreate={this.handleCreate}
              />
            </div>
          ) : null}
          {type === 'persons' ? (
            <SearchBlock
              labelText={'Потенциальный клиент'}
              title={'Данные потенциального клиента'}
              name={'personRequest'}
              value={personRequest}
              onChange={this.handleInputChange}
              handleSearch={this.handlePersonSearch}
              handleUpdate={this.handleUpdate}
              isLoading={isLoading}
            />
          ) : null}
          {type === 'touristfp' ? (
            <SearchBlock
              labelText={'Постоянный клиент'}
              title={'Данные постоянного клиента'}
              name={'touristfpRequest'}
              value={touristfpRequest}
              onChange={this.handleInputChange}
              handleSearch={this.handleTouristSearch}
              handleUpdate={this.handleUpdate}
              isLoading={isLoading}
            />
          ) : null}
          {results.length > 0 ? (
            <div className="geopick__select-container">
              <label>Найдено: {count}</label>
              <Select
                options={results}
                placeholder="Выберите клиента..."
                onChange={this.handleClientSelect}
                value={clientToChoose}
                openMenuOnFocus={true}
                formatOptionLabel={(option) =>
                  option.fio ? option.fio : option.full_name
                }
                getOptionValue={(option) => option.id}
                autoFocus
              />
              <button
                className={`btn btn-${
                  clientToChoose.id ? 'success' : 'secondary'
                }`}
                onClick={this.handleAddPersonToQuery}
                disabled={clientToChoose.id ? false : true}
              >
                Добавить
              </button>
              <button
                className="btn btn-danger"
                onClick={this.resetClientToChoose}
              >
                Сбросить
              </button>
            </div>
          ) : null}
          {type === 'list' ? (
            <div>
              <Select
                placeholder={`Добавить клиента`}
                isMulti={false}
                closeOnSelect={true}
                value={touristToAdd}
                options={this.options}
                onChange={this.handleTouristTypeChange}
                openMenuOnFocus={true}
              />
            </div>
          ) : null}
          {(clientToChoose.id && personIssues) ||
          (clientToChoose.id && touristIssues) ? (
            <>
              Геоподборка будет записана в:
              <ul className="list-group mt-2">
                <li className="list-group-item">
                  <IssueListItem
                    issue={touristIssues ? touristIssues[0] : personIssues[0]}
                  />
                </li>
              </ul>
            </>
          ) : null}
          {issuesListEmpty ? (
            <div className="list-group-item">
              Запросов нет, будет создана персональная ссылка на подборку.
            </div>
          ) : null}
          {personList?.length > 0 ? (
            <div>
              Потенциальные клиенты
              <ul className="list-group mt-2">
                {personList.map((person) => (
                  <li className="list-group-item" key={`person-${person.id}`}>
                    <PersonWithQuote
                      title={title}
                      person={person}
                      quote_id={quote_id}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {touristsFpList?.length > 0 ? (
            <div>
              Постоянные клиенты
              <ul className="list-group mt-2">
                {touristsFpList.map((tourist) => (
                  <li className="list-group-item" key={`tourist-${tourist.id}`}>
                    <TouristWithQuote
                      title={title}
                      tourist={tourist}
                      quote_id={quote_id}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
