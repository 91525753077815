import React, { useEffect, useState } from 'react';
import {
  TemplateDataAPI,
  OfficeAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../../api/Client/';
import { Alert, CardBody } from 'reactstrap';
import BackButton from '../../../components/BackButton';
import FieldErrorViewer from '../../Passports/components/FieldErrorViewer';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer';
import { useNavigate, useParams } from 'react-router-dom';

const waImage = '/img/social/wa.png';
const vbImage = '/img/social/vb.png';
const fbImage = '/img/social/fb.png';
const vkImage = '/img/social/vk.png';
const tgImage = '/img/social/tg.png';
const okImage = '/img/social/ok.png';
const igImage = '/img/social/ig.png';
const ytImage = '/img/social/youtube.png';
const TIMEOUT = 2000;

function TemplateSettings() {
  const { officeId } = useParams();

  const [templateInfo, setTemplateInfo] = useState({});
  const [isSuccess, setSuccess] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isError, setError] = useState();
  const [errorMessages, setErrorMessages] = useState({});
  const [isDisabledButtons, setDisabledButtons] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getInfo();
  }, []);

  function getInfo() {
    let status;
    OfficeAPI.fetchJSON(`${officeId}/template_data/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) => {
        if (status === POSITIVE_ACTION_STATUSES.retrieve) {
          if (!!result.template_data) {
            setTemplateInfo(result);
          }
        }
      });
  }

  function onDeleteData() {
    let status;
    setDisabledButtons(true);
    TemplateDataAPI.delete(templateInfo.template_data.id)
      .then((r) => {
        status = r.status;
        if (POSITIVE_ACTION_STATUSES.destroy !== status) {
          return r.json();
        }
        return {};
      })
      .then((r) => {
        if (POSITIVE_ACTION_STATUSES.destroy === status) {
          setSuccessOrError(
            'Удаление произведено успешно. Вы будете перенаправлены.',
            ''
          );
          setTimeout(() => {
            navigate(`/options/`, { replace: true });
          }, TIMEOUT);
        } else {
          setSuccessOrError('', r);
        }
      });
  }

  function onHandleChange(e) {
    const { name, value } = e.target;
    setDisabledButtons(false);
    setTemplateInfo({
      ...templateInfo,
      template_data: { ...templateInfo.template_data, [name]: value },
    });
    setSuccessOrError(false, false);
  }

  function setSuccessOrError(successMessage = false, err = false) {
    setSuccess(successMessage ? true : false);
    setError(err ? true : false);
    setSuccessMessage(successMessage ? successMessage : '');
    setErrorMessages(err ? err : {});
  }

  function onSaveData() {
    let status = 200;
    const responseHandler = (r) => {
      status = r.status;
      return r.json();
    };
    let action;
    const resultHandler = (r) => {
      if (POSITIVE_ACTION_STATUSES[action] === status) {
        const success =
          action === 'create'
            ? 'Данные для шаблонов созданы. Вы будете перенаправлены.'
            : 'Данные для шаблонов обновлены.';
        setSuccessOrError(success);
        setDisabledButtons(action === 'create');
        if (action === 'create') {
          setTimeout(() => {
            navigate(`/options/`, { replace: true });
          }, TIMEOUT);
        }
        setTimeout(() => {
          getInfo();
        }, TIMEOUT);
      } else {
        setSuccessOrError('', r);
      }
    };
    const { template_data = {} } = templateInfo;
    const data = {
      id: template_data.id,
      header_office_link: template_data.header_office_link,
      footer_office_link: template_data.footer_office_link,
      header_office_line: template_data.header_office_line,
      footer_office_line: template_data.footer_office_line,
      content_review_link: template_data.content_review_link,
      footer_vk_link: template_data.footer_vk_link,
      footer_office_phone: template_data.footer_office_phone,
      footer_instagram_link: template_data.footer_instagram_link,
      footer_facebook_link: template_data.footer_facebook_link,
      footer_viber: template_data.footer_viber,
      footer_telegram: template_data.footer_telegram,
      footer_whats_app: template_data.footer_whats_app,
      footer_youtube_link: template_data.footer_youtube_link,
      footer_ok_link: template_data.footer_ok_link,
      footer_phone: template_data.footer_phone,
      office: officeId,
    };

    if (template_data.id) {
      action = `update`;
      setDisabledButtons(true);
      TemplateDataAPI.update(template_data.id, data)
        .then(responseHandler)
        .then(resultHandler);
    } else if (!template_data.id) {
      action = `create`;
      setDisabledButtons(true);
      TemplateDataAPI.create(data).then(responseHandler).then(resultHandler);
    }
  }

  function onChangeOffice(v) {}

  const { template_data = {} } = templateInfo;

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-accent-primary">
          <div className="card-header">
            <h4>Настройка шаблона для email рассылки</h4>
          </div>
          <CardBody>
            <div className="form-group row">
              <label className="text-right col-md-2 col-form-label">
                Офис:
              </label>
              <div className="col-md-6">
                <OfficeSelectContainer
                  disabledSelect={true}
                  selected_office={template_data.office}
                  changeOffice={onChangeOffice}
                />
                <FieldErrorViewer
                  errorMessages={errorMessages}
                  field="office"
                />
              </div>
            </div>
          </CardBody>

          <Alert color="info">Верхняя часть шаблона</Alert>

          <div className="card-body">
            {[
              {
                name: 'header_office_line',
                lang: 'Название офиса',
                type: 'text',
                placeholder: 'География',
              },
              {
                name: 'header_office_link',
                lang: 'Ссылка на офис',
                type: 'url',
                placeholder: 'https://geograftour.com/my_office',
              },
            ].map((item, idx) => {
              return (
                <div className="form-group row" key={idx}>
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor={`input-${item.name}-${idx}`}
                  >
                    {item.lang}
                  </label>
                  <div className="col-md-8">
                    <input
                      type={item.type}
                      id={`input-${item.name}-${idx}`}
                      name={item.name}
                      value={template_data[item.name]}
                      onChange={onHandleChange}
                      className="form-control"
                      placeholder={item.placeholder}
                    />
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field={item.name}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <Alert color="info">Нижняя часть шаблона</Alert>

          <div className="card-body">
            <h6 className="mb-4">Контактная информация:</h6>
            {[
              {
                name: 'footer_office_line',
                lang: 'Название офиса',
                type: 'text',
                placeholder: 'География',
              },
              {
                name: 'footer_office_link',
                lang: 'Ссылка на офис',
                type: 'url',
                placeholder: 'https://geograftour.com/my_office',
              },
              {
                name: 'footer_phone',
                lang: 'Телефон',
                type: 'text',
                placeholder: '+79077777777',
              },
              {
                name: 'content_review_link',
                lang: 'Ссылка на Яндекс отзыв',
                type: 'text',
                placeholder: 'укажите ссылку на организацию',
              },
            ].map((item, idx) => {
              return (
                <div className="form-group row" key={idx}>
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor={`input-${item.name}-${idx}`}
                  >
                    {item.lang}
                  </label>
                  <div className="col-md-8">
                    <input
                      type={item.type}
                      id={`input-${item.name}-${idx}`}
                      name={item.name}
                      value={template_data[item.name]}
                      onChange={onHandleChange}
                      className="form-control"
                      placeholder={item.placeholder}
                    />
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field={item.name}
                    />
                  </div>
                </div>
              );
            })}

            <h6 className="mb-4">Мессенджеры:</h6>
            {[
              {
                name: 'footer_whats_app',
                placeholder: '+79077777777',
                image: {
                  src: waImage,
                  alt: 'WhatsApp',
                },
              },
              {
                name: 'footer_telegram',
                placeholder: '+79077777777',
                image: {
                  src: tgImage,
                  alt: 'Telegram',
                },
              },
              {
                name: 'footer_viber',
                placeholder: '+79077777777',
                image: {
                  src: vbImage,
                  alt: 'Viber',
                },
              },
            ].map((item, idx) => {
              return (
                <div className="form-group row" key={idx}>
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor={`input-${item.name}-${idx}`}
                  >
                    <img
                      src={item.image.src}
                      alt={item.image.alt}
                      width="16"
                      className="mr-2"
                    />
                    {item.image.alt}
                  </label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      id={`input-${item.name}-${idx}`}
                      name={item.name}
                      value={template_data[item.name]}
                      onChange={onHandleChange}
                      className="form-control"
                      placeholder={item.placeholder}
                    />
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field={item.name}
                    />
                  </div>
                </div>
              );
            })}

            <h6 className="mb-4">Соц. сети:</h6>
            {[
              {
                name: 'footer_vk_link',
                lang: 'ВКонтакте',
                placeholder: 'https://vk.com/geograftur/',
                image: {
                  src: vkImage,
                  alt: 'VK',
                },
              },
              {
                name: 'footer_instagram_link',
                lang: 'Instagram',
                placeholder: 'https://instagram.com/geograftur_rus/',
                image: {
                  src: igImage,
                  alt: 'Instagram',
                },
              },
              {
                name: 'footer_facebook_link',
                lang: 'Facebook',
                placeholder: 'https://facebook.com/geograftur/',
                image: {
                  src: fbImage,
                  alt: 'Facebook',
                },
              },
              {
                name: 'footer_youtube_link',
                lang: 'YouTube',
                placeholder: 'https://youtube.com/geograftur/',
                image: {
                  src: ytImage,
                  alt: 'Ютуб',
                },
              },
              {
                name: 'footer_ok_link',
                lang: 'ОК',
                placeholder: 'https://odnoklasniki.ru/geograftur/',
                image: {
                  src: okImage,
                  alt: 'Odnoklasniki',
                },
              },
            ].map((item, idx) => {
              return (
                <div className="form-group row" key={idx}>
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor={`input-${item.name}-${idx}`}
                  >
                    <img
                      src={item.image.src}
                      alt={item.image.alt}
                      width="16"
                      className="mr-2"
                    />
                    {item.lang}
                  </label>
                  <div className="col-md-8">
                    <input
                      type="url"
                      id={`input-${item.name}-${idx}`}
                      name={item.name}
                      value={template_data[item.name]}
                      onChange={onHandleChange}
                      className="form-control"
                      placeholder={item.placeholder}
                    />
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field={item.name}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          {isSuccess && <Alert color="success">{successMessage}</Alert>}
          {isError && !!errorMessages.non_field_errors
            ? errorMessages.non_field_errors.map((item, idx) => {
                return (
                  <Alert color="warning" key={idx}>
                    {item}
                  </Alert>
                );
              })
            : ``}
          {isError && !!errorMessages.detail ? (
            <Alert color="warning">
              Ошибка запроса: {errorMessages.detail}
            </Alert>
          ) : (
            ``
          )}

          <div className="card-footer">
            <BackButton />
            <button
              className="btn btn-success"
              onClick={onSaveData}
              disabled={isDisabledButtons}
            >
              Применить
            </button>
            {template_data.id ? (
              <button
                className="btn btn-danger float-right"
                onClick={onDeleteData}
                disabled={isDisabledButtons}
              >
                Удалить
              </button>
            ) : (
              ``
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateSettings;
