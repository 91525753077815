import React, { Component } from "react";
import { TouristCabinetList } from "./TouristCabinetList";
import { TouristCabinetFilter } from "./TouristCabinetFilter";
import { POSITIVE_ACTION_STATUSES, CabinetAPI } from "../../api/Client";
import ProgressBar from "../../components/ProgressBar";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Paginator from "../../components/Paginator";
import Swal from "sweetalert";
import { clearAllFilterParams } from "../../Utils/clearEmailfilter";

export default class TouristCabinets extends Component {
  constructor(props) {
    super(props);
    this.fetchTouristCabinets = this.fetchTouristCabinets.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.createMail = this.createMail.bind(this);
    this.handlerSort = this.handlerSort.bind(this);
    this.state = {
      items: [],
      filter: "",
      page: 1,
      count: 0,
      selected: [],
      isSuccess: false,
      isLoading: false,
      errors: null,
      order: '',
      orderPrev: '',
    };

    clearAllFilterParams();
  }

  handleFilter(filter) {
    this.setState(
      {
        filter,
      },
      () => this.fetchTouristCabinets()
    );
  }

  fetchTouristCabinets(page = 1) {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        let status;
        let { filter } = this.state;
        // console.log("filter", filter)
        CabinetAPI.fetchList(page, filter)
          .then((r) => {
            status = r.status;
            return r.json();
          })
          .then((r) => {
            if (status === POSITIVE_ACTION_STATUSES.list) {
              this.setState({
                items: r.results || [],
                count: r.count,
                page: page,
                isSuccess: false,
                errors: null,
                selected: [],
                isLoading: false,
              });
            } else {
              this.setState({
                items: [],
                count: 0,
                page: 1,
                isLoading: false,
              });
            }
          });
      }
    );
  }

  createMail() {
    const { count, filter } = this.state;
    Swal(
      `Создаем рассылку для ${this.state.count} пользователей кабинета туриста`
    );
    if (filter) {
      localStorage.setItem("tourists_cabinet_filter", JSON.stringify(filter));
    } else {
      localStorage.setItem("tourists_cabinet_filter", JSON.stringify({}));
    }
    localStorage.setItem("tourists_cabinet_total", JSON.stringify(count));
    localStorage.setItem("type", "cabinet");
    return false;
  }
  handlePageChange(data) {
    this.fetchTouristCabinets(data.selected + 1);
  }

  handlerSort(param) {
    let sortToggle = this.state.order != param ? true : !this.state.sortToggle;
    this.setState({
      order: param,
      orderPrev: this.state.order,
      sortToggle: sortToggle,
    }, () => this.handleFilter({...this.state.filter, ordering: `${sortToggle?'-':''}${param}`}))
  }

  componentDidMount() {
    const { page } = this.state;
    this.fetchTouristCabinets(page);
  }

  render() {
    const { items, page, count, isLoading, selected, isSuccess } = this.state;
    return (
      <>
        <TouristCabinetFilter onFilter={this.handleFilter} />
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader className="card-header__container">
                <div className="card-header__info-group">
                  <span className="badge badge-info">
                    <i className="fa fa-align-justify">{""}</i> 
                    Всего&nbsp;{count}
                  </span>
                </div>
                <div className="card-header__btn-group">
                  <Link
                    className="btn btn-success mt-0"
                    onClick={this.createMail}
                    to="/templates/"
                  >
                    <span>
                      <i className="fa fa-send mr-2">{""}</i>
                      Создать рассылку
                    </span>
                  </Link>
                </div>
              </CardHeader>
              {isLoading ? (
                <ProgressBar />
              ) : (
                <CardBody className="card-body_responsive">
                  <TouristCabinetList
                    items={items}
                    selected={selected}
                    toggleItem={this.toggleItem}
                    onFilter={this.handleFilter}
                    handlerSort={this.handlerSort}
                  />
                  <Paginator
                    total={count}
                    onPageChange={this.handlePageChange}
                    forcePage={page - 1}
                  />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
