import React, { PureComponent } from 'react';
import { Alert, Button, CardHeader } from 'reactstrap';
import InputOnClick from './components/InputOnClick';
import FilterTourists from './components/FilterTourists';
import EmailTemplates from './components/EmailTemplates';
import swal from 'sweetalert';
import { clearAllFilterParams } from '../../Utils/clearEmailfilter';
import {
  addLinkToUrlWithGeoclient,
  getFilterAbout,
  getFilterCount,
  sendToRecepients,
} from './utils';
import RecipientsList from './components/RecipientsList';
import Mailbox, { SIMPLE } from '../Mailbox/Mailbox';
import { MailingAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client/Client';
import EditorWrapper from './components/EditorWrapper';

const SMS_LENGTH = 70;
const SMS_MAX_LENGTH = 15 * SMS_LENGTH;

export default class Templates extends PureComponent {
  constructor(props) {
    super(props);
    this.typeSwitch = this.typeSwitch.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.sendMailer = this.sendMailer.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleSubject = this.handleSubject.bind(this);
    this.handleEmailTemplates = this.handleEmailTemplates.bind(this);
    this.showError = this.showError.bind(this);
    this.responseHandler = this.responseHandler.bind(this);
    this.showRecipientsList = this.showRecipientsList.bind(this);
    this.setListLoading = this.setListLoading.bind(this);
    this.chooseToRepeat = this.chooseToRepeat.bind(this);
    this.handleSentMailingList = this.handleSentMailingList.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.resetChosenTemplate = this.resetChosenTemplate.bind(this);

    this.state = {
      tourists: [],
      persons: [],
      selected: [],
      template: { title: '', sms: '', email: '' },
      selectedCount: 0,
      checkedAll: false,
      is_sms: true,
      templates: [{ title: '', sms: '', email: '' }],
      hasNext: false,
      hasPrev: false,
      pageNumber: 1,
      count: 0,
      message: '',
      sms: '',
      subject: '',
      isSended: false,
      isError: false,
      type: 'tourist',
      provider: 3,
      current_email_template: null,
      typeBefore: true,
      error_messages: {},
      filter: {
        type: false,
      },
      isDisabledSendButton: false,
      isViewSpinner: false,
      address: '',
      utm: {
        source: 'crm',
        medium: 'email',
        campaign: '',
        term: '',
        content: '',
        custom: {},
      },
      isCampaignInputValid: true,
      touristType: localStorage.getItem('type'),
      isListLoading: false,
      isSentMailingOpened: false,
    };
  }

  handleEmailTemplates(template) {
    this.setState({
      current_email_template: template,
      message:
        template && template.email_template.message
          ? template.email_template.message
          : '',
    });
  }

  handleMessage(e) {
    this.setState({
      message: e.target.value,
      isSended: false,
      isError: false,
    });
  }

  handleFilter(filter) {
    this.setState({
      filter: filter,
      isSended: false,
      isError: false,
    });
  }

  handleSubject(e) {
    this.setState({
      subject: e.target.value,
      isSended: false,
      isError: false,
    });
  }

  responseHandler = (response) => {
    return response.json();
  };

  sendMailer(e) {
    e.preventDefault();
    const { filter, provider, touristType } = this.state;
    if (filter.type === 'personal' && filter.id.length < 1) {
      this.showError('Выберите получателей рассылки');
      return;
    }

    const resultHandler = (result) => {
      if (result.address_book) {
        this.setState({
          isSended: true,
          isError: false,
          isViewSpinner: false,
          error_messages: {},
        });

        clearAllFilterParams();
        setTimeout(() => {
          window.location.replace('https://crm.geograftour.com/#/mailbox');
        }, 2e3);
      } else {
        this.setState({
          isSended: false,
          isError: true,
          error_messages: result,
          isDisabledSendButton: false,
          isViewSpinner: false,
        });
      }
    };
    if (
      filter.type !== 'all' &&
      filter.type !== 'tags' &&
      filter.type !== 'personal' &&
      filter.type !== 'cabinet' &&
      !touristType
    ) {
      //  не выбраны получатели
      this.setState({
        isSended: false,
        isError: true,
        error_messages: { type: ['не выбраны получатели рассылки'] },
        isDisabledSendButton: false,
        isViewSpinner: false,
      });
      return false;
    }

    this.setState(
      {
        isDisabledSendButton: true,
        isSended: false,
        isError: false,
        isViewSpinner: true,
      },
      () => {
        let data = {
          dispatch_type: this.state.is_sms ? 1 : 2,
          sender_type: provider,
          title: this.state.subject,
          address_book: {},
        };
        if (this.state.is_sms) {
          data['sms_campaign'] = {
            message: this.state.message,
          };
        } else {
          data['email_campaign'] = {
            message: addLinkToUrlWithGeoclient(this.state.message), ///сюда надо вшить ссылку///
            template: this.state.current_email_template.id,
            utm: this.state.utm,
          };
        }
        sendToRecepients(
          filter,
          touristType,
          data,
          this.responseHandler,
          resultHandler
        );
      }
    );
  }

  typeSwitch(e) {
    const { innerText } = e.target;
    if (innerText === 'SMS') {
      this.setState({
        is_sms: true,
        typeBefore: false,
        type: 'SMS',
      });
    } else if (innerText === 'Email') {
      this.setState({
        is_sms: false,
        typeBefore: false,
        type: 'Email',
      });
    }
  }

  checkProvider = (e) => {
    this.setState({
      provider: e.target.value,
      isSended: false,
      isError: false,
    });
  };

  showPreviewTemplate = (e) => {
    e.preventDefault();
    let { current_email_template, message } = this.state;

    let message_to_change = '&nbsp;&nbsp;';
    if (current_email_template.email_template.message) {
      message_to_change = current_email_template.email_template.message;
    }

    var newWindowEmailTemplate = window.open(
      'about:blank',
      'просмотр шаблона рассылки',
      'width=900,height=1000'
    );
    newWindowEmailTemplate.document.write(
      current_email_template.email_template.html
        .replace(message_to_change, message)
        .replace(
          '<body>',
          '<body><button class="btn btn-success" onclick="window.close()" style="float: right; cursor: pointer; margin: 20px; padding: 20px 40px; display: inline-block; color: #fff; font-size: 18px; border: none; border-radius: 5px; background: #20a8d8;">закрыть</button>'
        )
    );
  };

  componentDidMount() {
    if (
      localStorage.getItem('tourists_report_type') &&
      localStorage.getItem('tourists_report_total')
    ) {
      if (
        JSON.parse(localStorage.getItem('tourists_report_type')).name == 'SMS'
      ) {
        this.setState({
          is_sms: true,
          typeBefore: false,
          type: 'SMS',
        });
      } else if (
        JSON.parse(localStorage.getItem('tourists_report_type')).name == 'Email'
      ) {
        this.setState({
          is_sms: false,
          typeBefore: false,
          type: 'Email',
        });
      }
    } else {
      localStorage.removeItem('tourist_report_type');
    }
  }

  handleUtmInputChange = (e) => {
    const { name, value } = e.target;
    const regex = /^([a-zA-Z0-9-]+).*$/;

    if (name === 'campaign') {
      if (!regex.test(value)) {
        this.setState({ isCampaignInputValid: false });
      } else {
        this.setState({ isCampaignInputValid: true });
      }
    }
    this.setState((prevState) => ({
      utm: {
        ...prevState.utm,
        [name]: value,
      },
    }));
  };

  handleInputChange = (inputData) => {
    const custom = {};
    inputData.forEach((item) => {
      const key = item.inputKey.value;
      const value = item.inputValue.value;
      custom[key] = value;
    });
    this.setState((prevState) => ({
      utm: {
        ...prevState.utm,
        custom: { ...custom },
      },
    }));
  };

  showError(message) {
    swal(message);
  }

  showRecipientsList() {
    this.setState({
      isRecipientsListOpened: !this.state.isRecipientsListOpened,
    });
  }

  setListLoading() {
    this.setState({
      ...this.state,
      isListLoading: !this.state.isListLoading,
    });
  }

  handleSentMailingList() {
    this.setState({
      isSentMailingOpened: !this.state.isSentMailingOpened,
    });
  }

  resetChosenTemplate() {
    this.setState({
      message: '',
      current_email_template: null,
      utm: {
        source: 'crm',
        medium: 'email',
        campaign: '',
        term: '',
        content: '',
        custom: {},
      },
      subject: '',
    });
  }

  chooseToRepeat(id) {
    this.resetChosenTemplate();
    let status;
    MailingAPI.get(id)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.retrieve) {
          const { title, email_campaign } = r;
          this.setState(
            {
              isSentMailingOpened: false,
              subject: title,
              current_email_template: email_campaign.template,
              utm: email_campaign.utm || this.state.utm,
            },
            () => {
              this.handleEditorChange(email_campaign.message);
            }
          );
        }
      });
  }

  handleEditorChange = (message, editor) => {
    this.setState({ message });
  };

  render() {
    const {
      error_messages,
      isDisabledSendButton,
      isSended,
      isError,
      isViewSpinner,
      provider,
      touristType,
      filter,
      isListLoading,
      isRecipientsListOpened,
      isSentMailingOpened,
      current_email_template,
    } = this.state;
    const messageNames = {
      title: `Тема письма (название рассылки)`,
      message: `Необходимо заполнить текст письма`,
      dispatch_type: `Тип рассылки`,
      sender_type: `Провайдер`,
      email_campaign: ``,
      sms_campaign: ``,
      non_field_errors: ``,
      type: `Тип получателей`,
      image: `Изображение`,
    };
    let filterAbout = getFilterAbout();
    let filterCount = getFilterCount();

    return (
      <div className="mailer">
        {this.state.typeBefore ? (
          <div className="row">
            <div className="col-md-12" style={{ margin: '20px' }}>
              <img
                width="430"
                style={{ float: 'left', marginRight: '50px' }}
                src="/img/sender.png"
                className="w-100-mobile mb-2"
              />

              <h3 style={{ color: '#ff2d89' }}>
                Туристы Географии
                <br /> будут в курсе!
              </h3>
              <div
                className="w-100-mobile"
                style={{ width: '400px', display: 'inline-block' }}
              >
                <p className="hidden-mobile">
                  Ведите эффективную коммуникацию со своими клиентами при помощи
                  рассылок! Для вас готовые решения по отправке рассылки e-mail
                  и SMS
                  <br />
                  <br />
                  Вы платите только за нужное вам количество сообщений, никакой
                  абонентской платы. Рассылка отправляется от имени Географии с
                  вашими контактами.
                </p>
                <div className="row">
                  <div className="col-md-6 text-center  mb-2">
                    <Button
                      style={{
                        borderRadius: '5px',
                        padding: '10px 50px',
                        lineHeight: '16px',
                      }}
                      size="lg"
                      className="btn btn-pill"
                      color="success"
                      onClick={this.typeSwitch}
                    >
                      SMS
                    </Button>
                  </div>
                  <div className="col-md-6 text-center mb-2">
                    <Button
                      style={{
                        borderRadius: '5px',
                        padding: '10px 50px',
                        lineHeight: '16px',
                      }}
                      size="lg"
                      color="warning"
                      onClick={this.typeSwitch}
                    >
                      Email
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-4">
                  <strong>Рассылка</strong> SMS и E-mail
                </div>
                {JSON.parse(localStorage.office).fp_type === 'subagent' ? (
                  ''
                ) : (
                  <div className="col">
                    <div className="d-flex justify-content-end">
                      <div className="mx-3">
                        <label className="col-form-label">
                          <span> Провайдер:</span>
                        </label>
                      </div>
                      <div>
                        <select
                          onChange={this.checkProvider}
                          className="form-control"
                          placeholder="Выберите провайдер"
                          disabled={isDisabledSendButton}
                        >
                          <option value="3">Mailganer</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <form>
                    <div className="row">
                      {!this.state.is_sms ? (
                        <div className="col-md-12 mb-12">
                          <CardHeader className="bg-danger mb-2">
                            Стоимость 1 письма для 1 адресата - 0,20 копеек
                          </CardHeader>
                          <Button
                            onClick={this.handleSentMailingList}
                            color="warning"
                            className="mb-2"
                          >
                            {isSentMailingOpened
                              ? 'Закрыть список'
                              : 'Выбрать отправленную рассылку'}
                          </Button>
                          {isSentMailingOpened && (
                            <Mailbox
                              campaignInitType={SIMPLE}
                              chooseToRepeat={this.chooseToRepeat}
                              isDesktop={false}
                            />
                          )}
                          <EmailTemplates
                            onChoose={this.handleEmailTemplates}
                            sender_type={provider}
                            current_email_template={current_email_template}
                          />
                          <input
                            className="form-control mb-4"
                            placeholder="Укажите тему письма"
                            onChange={this.handleSubject}
                            value={this.state.subject}
                            disabled={isDisabledSendButton}
                          />
                          <EditorWrapper
                            message={this.state.message}
                            updateMessage={this.handleEditorChange}
                            isDisabled={isDisabledSendButton}
                          />
                        </div>
                      ) : (
                        <div className="col-md-12 mb-12">
                          <CardHeader className="bg-danger mb-2">
                            Стоимость 1 sms сообщения для 1 адресата - 4 рубля
                            sms сообщение должно быть не более 70 знаков
                          </CardHeader>
                          <h4>
                            <i
                              className="icon-speech mr-1 ml-2"
                              aria-hidden="true"
                            >
                              {''}
                            </i>
                            СМС
                          </h4>
                          <small>* Турист этот текст не увидит</small>
                          <input
                            className="form-control mb-4"
                            placeholder="Укажите название рассылки"
                            onChange={this.handleSubject}
                            value={this.state.subject}
                            disabled={isDisabledSendButton}
                          />
                          <small>Введено {this.state.message.length}</small>
                          {' / '}
                          <small>
                            Смс{' '}
                            {Math.ceil(this.state.message.length / SMS_LENGTH)}
                          </small>
                          <textarea
                            className="form-control mb-4"
                            placeholder="Текст сообщения"
                            rows="5"
                            onChange={this.handleMessage}
                            value={this.state.message}
                            maxLength={SMS_MAX_LENGTH}
                            disabled={isDisabledSendButton}
                          >
                            {''}
                          </textarea>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <button
                          className="btn btn-sm btn-success"
                          onClick={this.sendMailer}
                          disabled={isDisabledSendButton}
                        >
                          Отправить
                        </button>{' '}
                        {!this.state.is_sms && (
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={this.showPreviewTemplate}
                          >
                            Предпросмотр
                          </button>
                        )}
                        {isViewSpinner && (
                          <div>
                            <span className="fa fa-spinner fa-spin fa-2x">
                              {''}
                            </span>
                            <span>Подождите ...</span>
                          </div>
                        )}
                      </div>

                      {isSended && (
                        <div className="col-md-12 mt-4">
                          <Alert>
                            Рассылка создана и будет обработана в течение 5
                            минут
                          </Alert>
                        </div>
                      )}

                      {isError && (
                        <div className="col-md-12 mt-4">
                          {Object.keys(messageNames).map((key) => {
                            if (!error_messages.hasOwnProperty(key)) {
                              return null;
                            }

                            if (
                              Array.isArray(error_messages[key]) &&
                              error_messages[key].length
                            ) {
                              return error_messages[key].map((item) =>
                                this.showError(`${messageNames[key]}: ${item}`)
                              );
                            }

                            if (
                              typeof error_messages[key] === 'object' &&
                              Object.keys(error_messages[key]).length
                            ) {
                              return [
                                [`viber_campaign`, `Поля рассылки`],
                                [`title`, `Тема письма`],
                                [`message`, `Сообщение`],
                                [`template`, `Шаблон письма`],
                                [`task_name`, `Тема рассылки Viber`],
                                [`manager_name`, `Менеджер`],
                              ].map((i) => {
                                if (!error_messages[key].hasOwnProperty(i[0])) {
                                  return null;
                                }
                                return error_messages[key][i[0]].map((item) =>
                                  this.showError(`${i[1]}: ${item}`)
                                );
                              });
                            }
                          })}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
                <div className="col-md-6">
                  {filterAbout ? (
                    <div>
                      <h2>{filterAbout}</h2>
                      <p style={{ fontSize: '30px', color: '#d92a22' }}>
                        Получателей: {`${filterCount}`}
                      </p>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.showRecipientsList}
                        disabled={isListLoading}
                        style={{ marginBottom: '10px' }}
                      >
                        {!isRecipientsListOpened
                          ? 'Показать получателей'
                          : 'Скрыть получателей'}
                      </button>
                      <RecipientsList
                        isOpened={this.state.isRecipientsListOpened}
                        filterType={filter}
                        touristType={touristType}
                        setListLoading={this.setListLoading}
                        isListLoading={isListLoading}
                      />
                    </div>
                  ) : (
                    <FilterTourists
                      onFilter={this.handleFilter}
                      isDisabled={isDisabledSendButton}
                    />
                  )}
                  {this.state.type === 'Email' ? (
                    <div className="card utm">
                      <div className="card-header col-md-12 utm-header">
                        Создание UTM-меток для Email-рассылки
                      </div>
                      <div className="card-body p-4 utm__inputs-container">
                        <div className="utm__inputs utm__inputs_basic">
                          <label htmlFor="source" className="utm__label">
                            Источник трафика
                            <input
                              className="utm__input"
                              id="source"
                              name="source"
                              onChange={this.handleUtmInputChange}
                              value={this.state.utm.source}
                              placeholder="source"
                              maxLength={100}
                            ></input>
                          </label>
                          <label htmlFor="medium" className="utm__label">
                            Тип источника
                            <input
                              className="utm__input"
                              id="medium"
                              name="medium"
                              onChange={this.handleUtmInputChange}
                              value={this.state.utm.medium}
                              placeholder="medium"
                              maxLength={100}
                            ></input>
                          </label>
                          <label htmlFor="campaign" className="utm__label">
                            <div className="utm__input-header">
                              Название рассылки
                            </div>
                            <div
                              className={`utm__error-msg ${
                                this.state.isCampaignInputValid
                                  ? ''
                                  : 'utm__error-msg_active'
                              }`}
                            >
                              Допустимы только буквы, цифры и дефисы.
                            </div>
                            <input
                              className={`utm__input ${
                                this.state.isCampaignInputValid
                                  ? ''
                                  : 'utm__input_error'
                              }`}
                              id="campaign"
                              name="campaign"
                              onChange={this.handleUtmInputChange}
                              value={this.state.utm.campaign}
                              placeholder="campaign"
                              maxLength={100}
                            ></input>
                          </label>
                          <label htmlFor="term" className="utm__label">
                            Ключевые слова
                            <input
                              className="utm__input"
                              id="term"
                              name="term"
                              onChange={this.handleUtmInputChange}
                              value={this.state.utm.term}
                              placeholder="term"
                              maxLength={100}
                            ></input>
                          </label>
                          <label htmlFor="content" className="utm__label">
                            Тип ссылки при клике
                            <input
                              className="utm__input"
                              name="content"
                              id="content"
                              onChange={this.handleUtmInputChange}
                              value={this.state.utm.content}
                              placeholder="content"
                              maxLength={100}
                            ></input>
                          </label>
                        </div>
                        <InputOnClick
                          onChange={this.handleInputChange}
                          custom={this.state.utm.custom}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
