import 'moment/locale/ru';
import classnames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Alert } from 'reactstrap';

import { ADMIN, getUserRole, SUPER_ADMIN } from '../../../connect/auth';
import { AgenciesAPI } from '../../../api/Client';
import OffeceLeadNotifyToggler from './OfficeLeadNotifyToggler';
import OfficeShowOrderButtonToggler from './OfficeShowOrderButtonToggler';

export const AGENCY_OPTIONS = {
  activity: 'activity',
  falls: 'falls',
  awards: 'awards',
  terminated: 'terminated',
  comment: 'comment',
  site: 'site',
};

export const AGENCY_OPTIONS_LANG = {
  [AGENCY_OPTIONS.activity]: 'активность',
  [AGENCY_OPTIONS.falls]: 'нарушения',
  [AGENCY_OPTIONS.deffered]: 'награды',
  [AGENCY_OPTIONS.tours]: 'расторжение договора',
  [AGENCY_OPTIONS.comment]: 'комментарий',
  [AGENCY_OPTIONS.site]: 'заявка с сайта',
};

export default class AgencyOptions extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.setComment = this.setComment.bind(this);
    this.resetComment = this.resetComment.bind(this);

    this.state = {
      is_show: false,
      activeTab: '5',
      comment: '',
      comment_type: 'comment',
      isSuccessComment: false,
      hasLeadFromSite: true,
      userRole: null,
      office: null,
    };
  }

  handleSuccessComment() {
    this.setState(
      {
        isSuccessComment: true,
      },
      () => {
        setTimeout(this.resetComment, 3000);
      }
    );
  }
  handleComment() {
    const { agencyId } = this.props;
    const data = {
      text: this.state.comment,
      type: this.state.comment_type,
    };
    AgenciesAPI.comment(agencyId, data)
      .then((r) => r.json())
      .then((res) => {
        if (res.text) {
          this.handleSuccessComment({});
        }
      });
    // this.resetComment();
  }
  resetComment() {
    this.setState(
      {
        comment: '',
        isSuccessComment: false,
      },
      this.props.onUpdate
    );
  }
  setComment(e) {
    this.setState({
      comment: e.target.value,
    });
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggle() {
    this.setState({
      ...this.state,
      is_show: !this.state.is_show,
    });
  }

  onCreate() {
    this.setState({
      is_show: false,
    });
    this.props.onCreate.bind(this)();
  }

  componentDidMount() {
    this.setState({
      userRole: getUserRole(),
    });
  }

  render() {
    const { item } = this.props;
    const { activeTab } = this.state;
    const { userRole } = this.state;
    const { offices } = this.props.data;
    let office = offices.length ? offices[0] : false;
    const { agencyId } = this.props;
    return (
      <div className="issues mt-4">
        <div className="card card-accent-success">
          <div className="card-body">
            <div className="col-md-12 mb-4 issues-tab-list">
              <Nav tabs>
                {/* <NavItem> */}
                  {/* <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      this.toggleTab('1');
                    }}
                  >
                    <span className="p-2">
                      <i className="fa fa-star-o">{''}</i> Активность
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      this.toggleTab('2');
                    }}
                  >
                    <span className="p-2">
                      <i className="fa fa-exclamation-circle">{''}</i> Нарушение
                      стандартов сети
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      this.toggleTab('3');
                    }}
                  >
                    <span className="p-2">
                      <i className="fa fa-flag-o">{''}</i> Награды
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => {
                      this.toggleTab('4');
                    }}
                  >
                    <span className="p-2">
                      <i className="fa fa-times-circle-o">{''}</i> Расторжение
                      договора
                    </span>
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => {
                      this.toggleTab('5');
                    }}
                  >
                    <span className="p-2">
                      <i className="fa fa-comment">{''}</i> Комментарий
                    </span>
                  </NavLink>
                </NavItem>

                {userRole == SUPER_ADMIN && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '6' })}
                      onClick={() => {
                        this.toggleTab('6');
                      }}
                    >
                      <span className="p-2">
                        <i className="fa fa-star">{''}</i> Офис на сайте
                      </span>
                    </NavLink>
                  </NavItem>
                )}

                {userRole == SUPER_ADMIN && item.merges && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '7' })}
                      onClick={() => {
                        this.toggleTab('7');
                      }}
                    >
                      <span className="p-2">
                        <i className="fa fa-list">{''}</i> Слияния
                      </span>
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent className="col-md-12" activeTab={activeTab}>
                {/* <TabPane tabId="1">нет</TabPane>
                <TabPane tabId="2">нет</TabPane>
                <TabPane tabId="3">нет</TabPane>
                <TabPane tabId="4">нет</TabPane> */}
                <TabPane tabId="5">
                  <div className="form-group row">
                    <div className="col-md-6">
                      <textarea
                        autoFocus={true}
                        name="textarea-input"
                        rows="7"
                        className="form-control mb-2"
                        placeholder="Введите сообщение"
                        value={this.state.comment}
                        onChange={this.setComment}
                      ></textarea>
                      <button
                        className="btn btn-success mb-2"
                        onClick={this.handleComment}
                      >
                        Сохранить
                      </button>
                      {this.state.isSuccessComment ? (
                        <Alert color="success">
                          Комментарий успешно добавлен
                        </Alert>
                      ) : null}
                    </div>
                  </div>
                </TabPane>
                {userRole == SUPER_ADMIN && (
                  <TabPane tabId="6">
                    {office && office.id ? (
                      <div>
                        <OffeceLeadNotifyToggler
                          office={office.id}
                          agencyId={agencyId}
                          value={office.is_lead_notify}
                          onChange={this.props.onUpdate}
                        />
                        {/*<OfficeShowOrderButtonToggler
                          office={office.id}
                          agencyId={agencyId}
                          value={office.show_order_button}
                          onChange={this.props.onUpdate}
                        />*/}
                        <div>
                          Показывать кнопку "Подать заявку на тур" на детальной
                          странице:
                        </div>
                        <div>{office.show_order_button ? 'Да' : 'Нет'}</div>
                      </div>
                    ) : (
                      <div>Агентство не имеет привязки к сайту</div>
                    )}
                  </TabPane>
                )}

                {userRole == SUPER_ADMIN && item.merges && (
                  <TabPane tabId="7">
                    <div>
                      <ul>
                        {item.merges.map((merge) => (
                          <li key={merge.id}>
                            <ul>
                              <li>
                                <b>Название</b>:{' '}
                                <Link
                                  to={`/agencies/${merge.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {merge.public_name}
                                </Link>
                              </li>
                              <li>
                                <b>Дата открытия</b>:{' '}
                                {moment(merge.opened_date).format('DD.MM.YYYY')}
                              </li>
                              <li>
                                <b>Номер в ФСУ</b>: {merge.fp_index}
                              </li>
                            </ul>
                            <hr />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPane>
                )}
              </TabContent>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    );
  }
}
