import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, CardBody, CardHeader, Alert } from 'reactstrap';

import Paginator from '../../components/Paginator';
import { OfficeAPI } from '../../api/Client';
import NotificationSender from './components/NotificationSender';

export const SENDERS = [
  { label: 'Mailigen', value: '1' },
  { label: 'SendPulse', value: '2' },
  { label: 'Mailganer', value: '3' },
];

const TIMEOUT = 2000;

export default class OfficeSettings extends Component {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.actionResultHandler = this.actionResultHandler.bind(this);
    this.updateItemsByActionData = this.updateItemsByActionData.bind(this);

    this.state = {
      items: [],
      hasNext: false,
      hasPrev: false,
      pageNumber: 1,
      count: 0,
      messages: [],
      isSuccess: false,
      isError: false,
    };
  }

  handlePageChange(data) {
    const self = this;
    let page = data.selected + 1;
    self.setState(
      {
        pageNumber: page,
        isShowSuccess: false,
        messages: [],
      },
      () => self.fetchItems(page)
    );
  }

  fetchItems(page) {
    let status = 200;

    OfficeAPI.fetchList(page, {}, 'general_settings/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) => {
        if (status === 200) {
          this.setState({
            pageNumber: page,
            items: result.results || [],
            hasNext: result.next != null,
            hasPrev: result.previous != null,
            count: result.count || 0,
          });
        } else {
          this.setState({
            pageNumber: 1,
            items: [],
            hasNext: null,
            hasPrev: null,
            count: 0,
          });
        }
      });
  }

  updateItemsByActionData(office, action, data) {
    const items = this.state.items.map((item) => {
      if (office === item.id) {
        if (action === `create` || action === `partial_update`) {
          item.notification_sender = data;
        } else if (action === `destroy`) {
          item.notification_sender = null;
        }
      }
      return item;
    });
    this.setState({
      items: items,
    });
  }

  actionResultHandler(office, action, messages, isError, data) {
    if (isError) {
      this.setState(
        {
          messages: messages,
          isError: true,
          isSuccess: false,
        },
        () =>
          setTimeout(() => {
            this.setState({
              isSuccess: false,
              isError: false,
              messages: {},
            });
          }, 2 * TIMEOUT)
      );
    } else {
      this.setState(
        {
          isSuccess: true,
          isError: false,
          messages: messages,
        },
        () =>
          setTimeout(() => {
            this.setState(
              {
                isSuccess: false,
                isError: false,
                messages: {},
              },
              () => this.updateItemsByActionData(office, action, data)
            );
          }, TIMEOUT)
      );
    }
  }

  componentDidMount() {
    const self = this;
    self.fetchItems(this.state.pageNumber);
  }

  render() {
    const { messages, count, pageNumber, items, isSuccess, isError } =
      this.state;
    let errorMessages = [];
    if (typeof messages === 'object' && !!messages) {
      [
        { name: 'non_field_errors', lang: '' },
        { name: 'sender', lang: 'Провайдер' },
        { name: 'office', lang: 'Офис' },
      ].forEach((item) => {
        if (Array.isArray(messages[item.name]) && messages[item.name].length) {
          messages[item.name].map((message) =>
            errorMessages.push(`${item.lang}: ${message}`)
          );
        }
      });
      if (typeof messages['detail'] === 'string') {
        errorMessages.push(`Ошибка запроса: ${messages['detail']}`);
      }
    }
    return (
      <div className="animated fadeIn">
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify">{''}</i> Всего&nbsp;
                <span className="badge badge-info">{count}</span>
              </CardHeader>

              <CardBody>
                {isSuccess ? (
                  <Alert color="success">{messages.message}</Alert>
                ) : (
                  ``
                )}
                {isError
                  ? errorMessages.map((item, idx) => {
                      return (
                        <Alert key={idx} color="warning">
                          {item}
                        </Alert>
                      );
                    })
                  : ``}

                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Адрес</th>
                      <th>Шаблоны Email Писем</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((office) => {
                      return (
                        <tr key={office.id}>
                          <td>
                            <Link to={`/offices/${office.id}`}>{office.id}</Link>
                          </td>
                          <td>{office.address}</td>
                          <td>
                            {!!office.template_data ? (
                              <Link
                                className="btn btn-info"
                                to={`/templates/${office.id}/edit`}
                              >
                                Изменить данные
                              </Link>
                            ) : (
                              <Link
                                className="btn btn-secondary"
                                to={`/templates/${office.id}/edit`}
                              >
                                Добавить данные
                              </Link>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <p>
                  * - Автоматические уведомления уходят один раз в день в 12.00
                  по МСК. <br />
                  Email - за счет управляющей компании. Смс - в случае наличия у
                  Вас средств <Link to={'/cashaccounts'}>на балансе</Link>
                </p>
                <Paginator
                  total={count}
                  onPageChange={this.handlePageChange}
                  forcePage={pageNumber - 1}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
