import React, { Component } from 'react';
import { CardBody, Button, Col, Alert } from 'reactstrap';
import Select from 'react-select';
import { PassportAPI, POSITIVE_ACTION_STATUSES } from '../../../api/Client';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import { PASSPORT_TYPES } from '../Passports';
import FieldErrorViewer from './FieldErrorViewer';
import { DJANGO_BASE_DATE_FORMAT } from '../../Components/Calendar';

const TIMEOUT = 2000;

export default class Passport extends Component {
  constructor(props) {
    super(props);

    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSave = this.onHandleSave.bind(this);
    this.onHandleDelete = this.onHandleDelete.bind(this);
    this.onExpiresChange = this.onExpiresChange.bind(this);
    this.onIssuedChange = this.onIssuedChange.bind(this);
    this.onChangePassportType = this.onChangePassportType.bind(this);
    this.onFormChanged = this.onFormChanged.bind(this);

    this.state = {
      id: props.passport.id || 0,
      type: props.passport.type || PASSPORT_TYPES[0].value,
      series: props.passport.series || '',
      number: props.passport.number || '',
      expires: props.passport.expires || undefined,
      issued: props.passport.issued || undefined,
      issuer: props.passport.issuer || '',
      formChanged: false,
      errorMessages: {},
      isDisabledButtons: false,
      isSuccess: false,
    };
  }

  onHandleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.onFormChanged();
  }

  onExpiresChange(expires) {
    this.setState({ expires });
    this.onFormChanged();
  }

  onIssuedChange(issued) {
    this.setState({ issued });
    this.onFormChanged();
  }

  onChangePassportType(type) {
    this.setState({ type: type.value });
    this.onFormChanged();
  }

  onFormChanged() {
    this.setState({
      formChanged: true,
      errorMessages: {},
      isDisabledButtons: false,
      isSuccess: false,
    });
  }

  onHandleSave(e) {
    e.preventDefault();
    const self = this;
    const { type, series, number, expires, issued, issuer } = this.state;
    const { onSuccess } = this.props;
    let status;
    let action;
    const data = {
      type: type,
      series: series,
      number: number,
      expires: !!expires
        ? moment(expires).format(DJANGO_BASE_DATE_FORMAT)
        : null,
      issued: !!issued ? moment(issued).format(DJANGO_BASE_DATE_FORMAT) : null,
      issuer: issuer,
    };
    const responseHandler = (r) => {
      status = r.status;
      return r.json();
    };
    const resultHandler = (r) => {
      if (POSITIVE_ACTION_STATUSES[action] === status) {
        self.setState(
          {
            formChanged: false,
            errorMessages: {},
            isDisabledButtons: false,
            isSuccess: true,
          },
          () => {
            setTimeout(() => {
              self.setState(
                {
                  isSuccess: false,
                },
                () => onSuccess(r, action)
              );
            }, TIMEOUT);
          }
        );
      } else {
        self.setState({
          errorMessages: r,
          isDisabledButtons: false,
          isSuccess: false,
        });
      }
    };

    if (this.state.id) {
      action = 'partial_update';
      PassportAPI.update(this.state.id, data)
        .then(responseHandler)
        .then(resultHandler);
    } else {
      action = 'create';
      data.tourist = this.props.tourist;
      PassportAPI.create(data).then(responseHandler).then(resultHandler);
    }
  }

  onHandleDelete(e) {
    e.preventDefault();
    const self = this;
    const { id } = this.state;
    const { onSuccess } = this.props;
    self.setState(
      {
        isDisabledButtons: true,
      },
      () => {
        PassportAPI.delete(id).then((r) => {
          if (r.status === POSITIVE_ACTION_STATUSES.destroy) {
            self.setState(
              {
                formChanged: false,
                errorMessages: {},
                isDisabledButtons: false,
                isSuccess: true,
              },
              () => {
                setTimeout(() => {
                  onSuccess({ id: id }, 'destroy');
                }, TIMEOUT);
              }
            );
          } else {
            self.setState({
              errorMessages: r.json(),
              isDisabledButtons: false,
              isSuccess: false,
            });
          }
        });
      }
    );
  }

  render() {
    const errorMessages = [];
    if (typeof this.state.errorMessages['detail'] === 'string') {
      errorMessages.push(this.state.errorMessages['detail']);
    }
    ['non_field_errors', 'tourist'].forEach((f) => {
      if (
        Array.isArray(this.state.errorMessages[f]) &&
        this.state.errorMessages[f].length
      ) {
        this.state.errorMessages[f].map((message) =>
          errorMessages.push(message)
        );
      }
    });

    const {
      type,
      series,
      number,
      issuer,
      issued,
      id,
      formChanged,
      isDisabledButtons,
      isSuccess,
    } = this.state;

    return (
      <Col xs="12" className="animated fadeIn">
        <div className="card card-accent-primary">
          <CardBody>
            <form action="" method="post">
              <div className="form-group row">
                {[
                  {
                    name: 'issued',
                    lang: 'Дата выдачи',
                    handler: this.onIssuedChange,
                  },
                  {
                    name: 'expires',
                    lang: 'Дата окончания',
                    handler: this.onExpiresChange,
                  },
                ].map((item, idx) => {
                  return (
                    <div className="col-12 col-md-2" key={idx}>
                      <label className="col-form-label">{item.lang}:</label>
                      <DatePicker
                        locale={ru}
                        dateFormat="dd.MM.yyyy"
                        selected={
                          this.state[item.name]
                            ? moment(this.state[item.name]).format()
                            : undefined
                        }
                        onChange={item.handler}
                        className="d-block"
                        placeholderText="Неизвестно"
                      />
                      <FieldErrorViewer
                        errorMessages={this.state.errorMessages}
                        field={item.name}
                      />
                    </div>
                  );
                })}
                <div className="col-12 col-md-4">
                  <label className="col-form-label">Тип паспорта:</label>
                  <Select
                    isMulti={false}
                    joinValues={true}
                    simpleValue={true}
                    onChange={this.onChangePassportType}
                    options={PASSPORT_TYPES}
                    closeOnSelect={true}
                    placeholder="Тип паспорта"
                    value={
                      typeof type === 'string'
                        ? PASSPORT_TYPES.find((item) => item.value === type)
                        : type
                    }
                  />
                  <FieldErrorViewer
                    errorMessages={this.state.errorMessages}
                    field="type"
                  />
                </div>
                <div className="col-12 col-md-4">
                  <div className="row">
                    <div className="col-4">
                      <br />
                      {type === 'civil' ? (
                        <img alt="" width="64" src="/img/passport-rus.png" />
                      ) : (
                        <img alt="" width="64" src="/img/passport.png" />
                      )}
                    </div>
                    <div className="col-8">
                      <br />
                      <span>Серия / Номер: </span>
                      <span>
                        {series} / {number}
                      </span>
                      <br />
                      <span>Выдан: </span>
                      <span>
                        {typeof issuer === 'string'
                          ? issuer.toUpperCase()
                          : 'Неизвестно'}
                      </span>
                      <br />
                      <span>Дата выдачи: </span>
                      <span>
                        {typeof issued === 'object' && !!issued
                          ? moment(issued).format('DD.MM.YYYY')
                          : 'Неизвестно'}
                      </span>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                {[
                  {
                    name: 'series',
                    lang: 'Серия',
                    className: 'col-12 col-md-2',
                    type: 'text',
                  },
                  {
                    name: 'number',
                    lang: 'Номер',
                    className: 'col-12 col-md-2',
                    type: 'number',
                  },
                  {
                    name: 'issuer',
                    lang: 'Кем выдан',
                    className: 'col-12 col-md-4',
                    type: 'text',
                  },
                ].map((item, idx) => {
                  return (
                    <div className={item.className} key={idx}>
                      <label htmlFor={item.name} className="col-form-label">
                        {item.lang}:
                      </label>
                      <input
                        type={item.type}
                        id={`${item.name}-input-${id ? id : ''}-${
                          id ? '' : Date.now()
                        }`}
                        name={item.name}
                        value={this.state[item.name]}
                        onChange={this.onHandleChange}
                        className="form-control"
                        placeholder={item.lang}
                      />
                      <FieldErrorViewer
                        errorMessages={this.state.errorMessages}
                        field={item.name}
                      />
                    </div>
                  );
                })}
                <div className="col-12 col-md-3"></div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <Button
                    color={formChanged ? 'success' : 'secondary'}
                    disabled={!formChanged || isDisabledButtons}
                    onClick={this.onHandleSave}
                    className="btn-xs"
                  >
                    <i className="fa fa-dot-circle-o">{''}</i> Сохранить
                  </Button>
                </div>
                <div className="col-6 col-xs-12">
                  <Button
                    color={id ? 'danger' : 'default'}
                    onClick={id ? this.onHandleDelete : this.props.onCancel}
                    disabled={isDisabledButtons}
                    className="float-right btn-xs"
                  >
                    <i className="fa fa-dot-circle-o">{''}</i>{' '}
                    {id ? 'Удалить' : 'Отмена'}
                  </Button>
                </div>
              </div>
            </form>

            <div className="form-group row">
              <div className="col-12">
                {isSuccess ? (
                  <Alert>Успешно</Alert>
                ) : (
                  <div className="alert alert-dismissable"></div>
                )}

                {errorMessages.length
                  ? errorMessages.map((message, idx) => {
                      return (
                        <Alert color="warning" key={idx}>
                          {message}
                        </Alert>
                      );
                    })
                  : ''}
              </div>
            </div>
          </CardBody>
        </div>
      </Col>
    );
  }
}
