import React, { Component } from 'react';
import {
  Alert,
  Table,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardHeader,
  Col,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';

import Paginator from '../../components/Paginator';
import { LeadAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client';
import { getUserRole, SUPER_ADMIN } from '../../connect/auth';
import * as FileSaver from 'file-saver';
import Swal from 'sweetalert';

export const SOURCE_TYPE_DICT = {
  geo_site_chat: 'Сайт (чат)',
  geo_site_form_hot: 'Сайт (горящая заявка)',
  agency_tour: 'Агентский поисковик',
  geo_site_quote: 'Геоподборка',
  cabinet: 'ЛК (офис)',
  cabinet_online: 'ЛК (покупка онлайн)',
  cabinet_excursion_tour: 'Сайт (экскурсионный тур)',
};

export const STATUS_DESCRIPTION_MAP = {
  new: 'Новая',
  work: 'Открыл',
  accepted: 'Покупка',
  reserved: 'Покупка',
  cancelled: 'Аннулирована',
  call: 'Назначен звонок',
  meet: 'Встреча',
  tours: 'Отправлена подборка',
  client_cancel: 'Турист отказался',
  we_cancel: 'Мы отказали',
  deffered: 'Отложен',
  called: 'Cозвонились',
  no_call_answered: 'Не берет трубку',
  whatsapp: 'Написали на WA',
  quotes_from_crm: 'Отправили подборку',
};

export const LEADS_STATUS_COLOR = {
  new: 'badge-danger',
  work: 'badge-warning',
  accepted: 'badge-success',
  reserved: 'badge-success',
  flight_out: 'badge-success',
  cancelled: 'badge-gray',
  call: 'badge-green',
  meet: 'badge-green',
  tours: 'badge-green',
  client_cancel: 'badge-brown',
  we_cancel: 'badge-black',
  deffered: 'badge-purple',
  called: 'badge-primary',
  no_call_answered: 'badge-default',
  whatsapp: 'badge-green',
  quotes_from_crm: 'badge-pink',
};

function default_map(d, k) {
  return d.hasOwnProperty(k) ? d[k] : k;
}

class LeadsTable extends Component {
  constructor(props) {
    super(props);

    this.createMail = this.createMail.bind(this);
    this.createMailWarning = this.createMailWarning.bind(this);
    this.fetchReport = this.fetchReport.bind(this);

    localStorage.removeItem('abandonedcustomers_filter');
    localStorage.removeItem('franchise_filter');
    localStorage.removeItem('franchise_leads_filter');
    localStorage.removeItem('leads_filter');
    localStorage.removeItem('persons_filter');
    localStorage.removeItem('subscription_filter');
    localStorage.removeItem('tourist_filter');
    localStorage.removeItem('tourists_cabinet_filter');
    localStorage.removeItem('tourists_filter');
    localStorage.removeItem('tourists_report_filter');
    localStorage.removeItem('touristsfp_filter');

    localStorage.removeItem('leads_total');
    localStorage.removeItem('tourists_total');
    localStorage.removeItem('tourist_total');
    localStorage.removeItem('touristsfp_total');
    localStorage.removeItem('persons_total');
    localStorage.removeItem('tourists_cabinet_total');
    localStorage.removeItem('franchise_total');
    localStorage.removeItem('subscription_total');
    localStorage.removeItem('franchise_leads_total');
    localStorage.removeItem('tourists_report_type');
    localStorage.removeItem('tourists_report_address_books');
    localStorage.removeItem('abandonedcustomers_total');
    localStorage.removeItem('type');

    this.state = {
      isCreationReport: false,
      isErrorCreation: false,
    };
  }

  createMail() {
    if (this.props.filters) {
      localStorage.setItem('leads_filter', JSON.stringify(this.props.filters));
      localStorage.setItem('leads_total', JSON.stringify(this.props.count));
      localStorage.setItem('type', 'leads');
    }
    return false;
  }

  createMailWarning() {
    Swal(`Для создания рассылки выберите фильтры в форме поиска.

    После этого список будет сформирован.`);
  }

  fetchReport() {
    let status;
    this.setState(
      {
        isCreationReport: true,
        isErrorCreation: false,
      },
      () => {
        LeadAPI.fetchReport(this.props.filters)
          .then((response) => {
            status = response.status;
            if (status === POSITIVE_ACTION_STATUSES.create) {
              return response.blob();
            }
            return {};
          })
          .then((file) => {
            if (status === POSITIVE_ACTION_STATUSES.create) {
              FileSaver.saveAs(file, 'report.xls');
              this.setState({
                isErrorCreation: false,
                isCreationReport: false,
              });
            } else {
              this.setState({
                isErrorCreation: true,
                isCreationReport: false,
              });
            }
          });
      }
    );
  }

  render() {
    const {
      items,
      count,
      handlePageChange,
      isShowActions,
      errors,
      isSuccess,
      selected,
      handleDeleteLeads,
      toggleItem,
      forcePage,
    } = this.props;
    const { isCreationReport, isErrorCreation } = this.state;
    const userRole = getUserRole();
    const isAccessToDelete = userRole === SUPER_ADMIN;
    return (
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader className="card-header__container">
              <div className="card-header__info-group">
                <span className="badge badge-info">
                  <i className="fa fa-align-justify hidden-mobile mr-2" />
                  Всего&nbsp;{count}
                </span>
              </div>
              {userRole === SUPER_ADMIN ? (
                <div className="float-right" hidden={!isShowActions}>
                  <UncontrolledDropdown size="sm" color="info">
                    <DropdownToggle caret disabled={!items.length} color="info">
                      Действия
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem header>Excel</DropdownItem>
                      {isCreationReport ? (
                        <div className="fa fa-spinner fa-spin fa-5x fa-fw margin-bottom"></div>
                      ) : null}
                      {isErrorCreation ? (
                        <Alert color="danger">Ошибка</Alert>
                      ) : null}
                      {!isCreationReport ? (
                        <button
                          className="float-right btn fa-primary text-center btn-sm mr-2"
                          onClick={this.fetchReport}
                        >
                          <i className="fa fa-file-excel-o">Сохранить</i>
                        </button>
                      ) : null}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              ) : (
                <div className="float-right" hidden={!isShowActions}></div>
              )}
              {isAccessToDelete ? (
                <div className="float-right" hidden={!selected.length}>
                  <Button color="danger" onClick={handleDeleteLeads}>
                    Удалить заявки ({selected.length} шт.)
                  </Button>
                </div>
              ) : null}
            </CardHeader>
            <CardBody className="card-body_responsive">
              {errors !== null &&
              typeof errors === 'object' &&
              Object.keys(errors).length
                ? Object.keys(errors).map((item, idx) => (
                    <Alert color="danger" key={idx}>
                      {errors[item]}
                    </Alert>
                  ))
                : null}
              {isSuccess ? <Alert>Успешно</Alert> : null}
              <Table
                striped
                bordered
                size="sm"
                className="font-xs table--orange leads-table"
              >
                <thead>
                  <tr>
                    <th
                      className="text-center hidden-mobile  tableItem"
                      hidden={!isAccessToDelete}
                    >
                      *
                    </th>
                    <th className="text-center tableItem">Номер заявки</th>
                    <th className="text-center tableItem">Дата</th>
                    <th className="text-center tableItem">Дата перевода</th>
                    <th className="text-center tableItem">Статус</th>
                    <th className="text-center tableItem">
                      Дата начала работы агента
                    </th>
                    <th className="text-center tableItem">Источник</th>
                    <th className="text-center tableItem">Офис</th>
                    <th className="text-center tableItem">Менеджер</th>
                    <th className="text-center tableItem">Номер заявки в СБ</th>
                    <th className="text-center tableItem">Клиент</th>
                    <th className="text-center tableItem">Город вылета</th>
                    <th className="text-center tableItem">Страна назначения</th>
                    <th className="text-center tableItem" colSpan="2">
                      Туристов
                    </th>
                    <th className="text-center tableItem">Цена (Руб.)</th>
                    <th className="text-center tableItem">Номер запроса</th>
                    <th className="text-center tableItem">Был перевод</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) => (
                    <tr key={item.id}>
                      <td
                        className="text-center hidden-mobile  tableItem"
                        hidden={!isAccessToDelete}
                      >
                        <input
                          type="checkbox"
                          id={`lead-${item.id}`}
                          onChange={() => toggleItem(item.id)}
                          checked={selected.includes(item.id)}
                        />
                      </td>
                      <td className="text-center tableItem">
                        <Link to={`/leads/${item.id}`}>{item.id}</Link>
                      </td>
                      <td className="text-center tableItem">
                        <Link to={`/leads/${item.id}`}>
                          {moment(item.created).format('DD.MM.YY HH:mm')}
                        </Link>
                      </td>
                      <td className="text-center tableItem">
                        {item.was_transferred_at &&
                          moment(item.was_transferred_at).format(
                            'DD.MM.YY HH:mm'
                          )}
                      </td>
                      <td className="text-center tableItem">
                        <span
                          className={`badge ${
                            LEADS_STATUS_COLOR[item.status] || 'badge-default'
                          }`}
                        >
                          {default_map(STATUS_DESCRIPTION_MAP, item.status)}
                        </span>
                      </td>
                      <td className="text-center tableItem">
                        {item.first_updater_at &&
                          moment(item.first_updater_at).format(
                            'DD.MM.YY HH:mm'
                          )}
                      </td>
                      <td className="text-center tableItem">
                        {default_map(SOURCE_TYPE_DICT, item.source)}
                        {item.source == 'geo_site_quote' && item.manager ? (
                          <span>
                            {' '}
                            ({item.manager.first_name} {item.manager.last_name})
                          </span>
                        ) : null}
                      </td>
                      <td className="text-center tableItem">
                        {item.office ? (
                          <Link to={`/agencies/${item.office.agency_id}`}>
                            {item.office.title}
                          </Link>
                        ) : null}
                      </td>
                      <td className="text-center tableItem">
                        {item.manager ? (
                          <span>
                            {item.manager.first_name} {item.manager.last_name}
                          </span>
                        ) : null}
                      </td>
                      <td className="text-center tableItem">
                        {!!item['orders_fp'] ? (
                          <Link to={`/ordersfp/${item['orders_fp'].id}`}>
                            {item['orders_fp'].fp_pk}
                          </Link>
                        ) : (
                          item.fp_number
                        )}
                      </td>
                      <td className="text-center tableItem">
                        <Link to={`/persons/${item.author.id}`}>
                          {item.author.fio || 'ID:<' + item.author.id + '>'}
                        </Link>
                      </td>
                      <td className="text-center tableItem">{item.city}</td>
                      <td className="text-center tableItem">
                        {item.destination_country}
                      </td>
                      <td className="text-center tableItem">
                        {item.adult_count}
                      </td>
                      <td className="text-center tableItem">
                        {item.child_count}
                      </td>
                      <td className="text-center tableItem">
                        {item.tour_amount}
                      </td>
                      <td className="text-center tableItem">
                        {!!item.issues ? (
                          <Link to={`/persons/${item.author.id}`}>
                            {item.issues.id}
                          </Link>
                        ) : null}
                      </td>
                      <td className="text-center tableItem">
                        {item.was_transferred ? (
                          <i className="fa fa-send-o">{}</i>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Paginator
                total={count}
                onPageChange={handlePageChange}
                forcePage={forcePage}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

LeadsTable.defaultProps = {
  isShowActions: false,
  filters: null,
  handleRefresh: () => null,
};

export default LeadsTable;
