import React, { Component } from 'react';
import { POSITIVE_ACTION_STATUSES, TemplatesAPI } from '../../../api/Client';
import { Card } from 'reactstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './css/EmailTemplates.css';

export default class EmailTemplates extends Component {
  constructor(props) {
    super(props);
    this.getTemplates = this.getTemplates.bind(this);
    this.state = {
      email_templates: [],
      current_email_template: this.props.current_email_template || null,
      isChoose: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.current_email_template !== this.props.current_email_template
    ) {
      this.setState({
        current_email_template: this.props.current_email_template,
      });
    }
  }

  handleChooseTemplate = (template) => {
    if (!template) {
      template = this.state.current_email_template;
    }

    this.setState(
      {
        isChoose: true,
        current_email_template: template,
      },
      this.props.onChoose(template)
    );
  };

  handleChooseClick = (e) => {
    this.hideTemplates;
    this.handleChooseTemplate();
  };

  hideTemplates = () => {
    this.setState({
      isChoose: true,
    });
  };
  handleChangeTemplate = () => {
    this.setState({
      isChoose: false,
    });
  };
  setEmailTemplate = (index) => {
    this.setState(
      {
        current_email_template: this.state.email_templates[index],
      },
      this.props.onChoose(this.state.email_templates[index])
    );
  };
  getTemplates() {
    let status;
    TemplatesAPI.getAll()
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState(
            {
              email_templates: result,
              current_email_template:
                this.state.current_email_template === null
                  ? result[0]
                  : this.state.current_email_template,
            },
            this.props.onChoose(result[0])
          );
        }
      });
  }

  componentDidMount() {
    this.getTemplates();
  }

  render() {
    const { email_templates, current_email_template } = this.state;
    const template =
      this.props.current_email_template ||
      current_email_template ||
      email_templates[0];
    return (
      <Card>
        {this.state.isChoose && (
          <>
            <div className="card-header">
              Выберите шаблон (<b>{email_templates.length}</b>)
              {template && (
                <span className="float-right hidden-mobile">
                  {template.name}
                </span>
              )}
            </div>
            <div className="card-body row m-0 w-100">
              <div className="col-md-12">
                <Carousel
                  showIndicators={false}
                  onClickItem={(index) => {
                    this.handleChooseTemplate(email_templates[index]);
                  }}
                  onChange={this.setEmailTemplate}
                  onClickThumb={this.setEmailTemplate}
                  centerMode={true}
                  selectedItem={email_templates.findIndex(o => o.id === template.id)}
                >
                  {email_templates.map((template) => (
                    <div key={template.created}>
                      <img
                        src={
                          template.email_template.preview
                            ? template.email_template.preview.file
                            : ''
                        }
                        onClick={(e) => this.handleChooseTemplate(template)}
                        style={{ cursor: 'pointer' }}
                        alt={template.name}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </>
        )}
      </Card>
    );
  }
}
