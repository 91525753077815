import React, { Component } from 'react';
import Select from 'react-select';
import { BarLoader } from 'react-spinners';

const TOURIST_STATUS_OPTIONS = [
  { label: 'Доставлено', value: 'delivered' },
  { label: 'Доставлено и прочитано', value: 'read' },
  { label: 'Доставлено, прочитано и есть переход', value: 'redirected' },
  { label: 'Отписался', value: 'unsubscribe' },
];

export class TouristViberListsFilter extends Component {
  constructor(props) {
    super(props);
    this.onFilter = this.onFilter.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleOptions = this.handleOptions.bind(this);
    this.state = {
      status: '',
      options: '',
    };
  }

  handleStatus(status) {
    this.setState({ status }, () => {
      this.onFilter(status);
    });
  }

  handleOptions(options) {
    this.setState({ options }, () => {});
    this.props.setStrikesForRequest(options);
  }

  onFilter() {
    let filter = {};
    if (this.state.status.length === 0) {
    } else if (this.state.status.length !== 0) {
      filter.report_status = this.state.status.map(item => item.value).join(',');
    }
    this.props.onFilter(filter);
  }

  render() {
    const { status, options, strikes } = this.state;
    return (
      <div className="row" style={{ marginBottom: '10px' }}>
        <div className="col-md-6">
          <Select
            id="tourist_status_1"
            name="status"
            isMulti={true}
            style={{ width: '100%' }}
            placeholder="Статус"
            options={TOURIST_STATUS_OPTIONS}
            closeOnSelect={true}
            simpleValue={true}
            value={status}
            onChange={this.handleStatus}
          />
        </div>
        <div className="col-md-6">
          <Select
            disabled={this.props.isLoadingStrikes}
            id="options"
            name="options"
            isMulti={true}
            style={{ width: '100%' }}
            placeholder="Фильтрация адресатов по целям"
            options={this.props.strikes}
            closeOnSelect={true}
            simpleValue={true}
            value={options}
            onChange={this.handleOptions}
          />
          {/*<div>Загрузка...</div>*/}
          <div style={{ width: '100%', overflow: 'hidden', marginTop: '10px' }}>
            {this.props.isLoadingStrikes && (
              <BarLoader color={'green'} width={'1000px'} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
