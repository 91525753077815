import React, { useEffect, useState } from 'react';
import {
  AgenciesAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../../api/Client/Client';
import Select from 'react-select';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment';
import 'moment/locale/ru';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { AiFillCheckCircle, AiFillMinusCircle } from 'react-icons/ai';
import ProgressBar from '../../../components/ProgressBar';
import { Button, CardHeader, CardTitle } from 'reactstrap';

const MONTHS = {
  '01': 'Январь',
  '02': 'Февраль',
  '03': 'Март',
  '04': 'Апрель',
  '05': 'Май',
  '06': 'Июнь',
  '07': 'Июль',
  '08': 'Август',
  '09': 'Сентябрь',
  10: 'Октябрь',
  11: 'Ноябрь',
  12: 'Декабрь',
};

const YEAR_COLOURS = [
  '#de4596',
  '#009436',
  '#1584c1',
  '#bc9622',
  '#923365',
  '#055c25',
  '#0d597a',
  '#7a621b',
  '#d69dbb',
  '#61e591',
  '#70d4ff',
  '#ffe69c',
  '#000',
];

function AgencySalesGraph({ agencyId }) {
  const todaysDate = new Date();
  const currentYear = todaysDate.getFullYear();

  const [startDate, setStartDate] = useState(new Date(`${currentYear}.01.01`));
  const [endDate, setEndDate] = useState(todaysDate);
  const [initialSales, setInitialSales] = useState([]);
  const [salesByDay, setSalesByDay] = useState([]);
  const [salesByHalfMonth, setSalesByHalfMonth] = useState([]);
  const [salesByMonth, setSalesByMonth] = useState([]);
  const [dataShowMode, setDataShowMode] = useState('month');
  const [isSalesLoading, setSalesLoading] = useState(false);
  const [yearsOfSalesToShow, setYearsOfSalesToShow] = useState([]);
  const [initialYearsOfSales, setInitialYearsOfSales] = useState([]);

  useEffect(() => {
    getSalesData();
  }, []);

  useEffect(() => {
    filterSales(initialSales);
  }, [startDate, endDate]);

  function getSalesData() {
    let status;
    setSalesLoading(true);
    AgenciesAPI.getSalesData(`${agencyId}`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.retrieve) {
          if (r.length > 0) {
            setInitialSales(r);
            handleSalesYears(r);
            filterSales(r);
          }
          setSalesLoading(false);
        }
      });
  }

  function handleSalesYears(data) {
    const years = Object.keys(data[0])
      .filter((year) => year !== 'name')
      .map((year) => ({ id: year, value: year }))
      .reverse();
    setInitialYearsOfSales(years);
    const yearsToShow = years.slice(0, 3);
    setYearsOfSalesToShow(yearsToShow);
  }

  function filterSales(data) {
    const filteredData = data.filter(
      (date) =>
        moment(startDate).isSameOrBefore(
          moment(`${date.name}.${currentYear}`, 'DD.MM.YYYY').format(
            'YYYY-MM-DD'
          )
        ) &&
        moment(endDate).isSameOrAfter(
          moment(`${date.name}.${currentYear}`, 'DD.MM.YYYY').format(
            'YYYY-MM-DD'
          )
        )
    );

    setSalesByDay(filteredData);
    groupSalesByMonth(filteredData);
    groupSalesByHalfMonth(filteredData);
  }

  function groupSalesByMonth(data) {
    const salesGroupedByMonth = data.reduce((acc, day) => {
      const monthNumber = day.name.slice(-2);

      if (!acc[monthNumber]) {
        acc[monthNumber] = { ...day, monthNumber, name: MONTHS[monthNumber] }; // Инициализация
      } else {
        Object.keys(day).forEach((year) => {
          if (year !== 'name') {
            acc[monthNumber][year] = (acc[monthNumber][year] || 0) + day[year];
          }
        });
      }

      return acc;
    }, {});

    const salesByMonthArray = Object.values(salesGroupedByMonth).sort(
      (a, b) => a.monthNumber - b.monthNumber
    );
    setSalesByMonth(salesByMonthArray);
  }

  function groupSalesByHalfMonth(data) {
    const salesGroupedByHalfMonth = data.reduce((acc, day) => {
      const date = day.name.slice(0, 2);
      const monthNumber = day.name.slice(3);
      const period = date < 16 ? `01-${monthNumber}` : `16-${monthNumber}`;

      if (!acc[period]) {
        acc[period] = { ...day, name: period }; // Инициализация, если нет записей за этот период
      } else {
        Object.keys(day).forEach((year) => {
          if (year !== 'name') {
            acc[period][year] = (acc[period][year] || 0) + day[year];
          }
        });
      }

      return acc;
    }, {});

    const salesByHalfMonthArray = Object.values(salesGroupedByHalfMonth).sort(
      (a, b) => a.name - b.name
    );
    setSalesByHalfMonth(salesByHalfMonthArray);
  }

  function handleSalesYearsChange(year) {
    const newValue = yearsOfSalesToShow.filter((item) => item.id !== year.id);
    setYearsOfSalesToShow(newValue);
  }

  return salesByDay.length > 0 ? (
    <div className="agency-graph">
      <CardHeader className="agency-graph__header">
        <CardTitle className="m-0">
          График продаж
          <br />
          (по улетевшим туристам)
        </CardTitle>
        <div className="agency-graph__dates-control">
          <p style={{ margin: 0 }}>Период: </p>
          <div>
            <DatePicker
              locale={ru}
              dateFormat="dd.MM"
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={setStartDate}
              placeholderText="Дата начала"
              disabled={isSalesLoading}
            />
          </div>
          <div>
            <DatePicker
              locale={ru}
              dateFormat="dd.MM"
              selected={endDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={setEndDate}
              placeholderText="Дата окончания"
              disabled={isSalesLoading}
            />
          </div>
        </div>
      </CardHeader>
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
          <LineChart
            data={
              dataShowMode === 'day'
                ? salesByDay
                : dataShowMode === 'halfMonth'
                ? salesByHalfMonth
                : salesByMonth
            }
            margin={{ top: 5, right: 20, bottom: 5, left: -20 }}
          >
            {yearsOfSalesToShow.map((year, idx) => (
              <Line
                key={year.id}
                type="monotone"
                dataKey={year.value}
                stroke={YEAR_COLOURS[idx]}
                dot={dataShowMode === 'day' ? false : true}
              />
            ))}
            <CartesianGrid stroke="333" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="agency-graph__control">
        <div className="agency-graph__control-buttons">
          {yearsOfSalesToShow.map((year, idx) => (
            <Button
              key={year.id}
              className="agency-graph__control-button agency-graph__control-button_green"
              style={{ backgroundColor: `${YEAR_COLOURS[idx]}` }}
              title="Скрыть год на графике"
              onClick={
                year.id === currentYear
                  ? ''
                  : () => handleSalesYearsChange(year)
              }
            >
              <AiFillCheckCircle className="agency-graph__control-button-indicator" />
              <AiFillMinusCircle className="agency-graph__control-button-hide" />
              {year.value}
            </Button>
          ))}
        </div>
        <Select
          name="years_to_show"
          onChange={setYearsOfSalesToShow}
          options={initialYearsOfSales}
          placeholder="Годы для отображения"
          isMulti
          isSearchable
          value={yearsOfSalesToShow}
          isClearable
          getOptionLabel={(o) => o.value}
          getOption={(o) => o.id}
          closeMenuOnSelect={false}
        />
        <div className="switcher">
          <p className="switcher-title">Шаг на графике</p>
          <Button
            className="switcher-btn"
            color={dataShowMode === 'day' ? 'success' : ''}
            onClick={() => setDataShowMode('day')}
          >
            День
          </Button>
          <Button
            className="switcher-btn"
            color={dataShowMode === 'halfMonth' ? 'success' : ''}
            onClick={() => setDataShowMode('halfMonth')}
          >
            1/2 месяца
          </Button>
          <Button
            className="switcher-btn"
            color={dataShowMode === 'month' ? 'success' : ''}
            onClick={() => setDataShowMode('month')}
          >
            Месяц
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <CardHeader color="warning" className="mt-4 mb-4">
      {isSalesLoading ? (
        <ProgressBar />
      ) : (
        <CardTitle className="m-0">
          Нет данных для отображения в графике
        </CardTitle>
      )}
    </CardHeader>
  );
}

export default AgencySalesGraph;
