import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { Link } from 'react-router-dom';

import { IssuesAPI } from '../../../api/Client/Client';
import { ISSUE_STATUS_COLOR, ISSUE_STATUS_ICONS, ISSUE_STATUSES_LANG } from '../constants';


export default class CommentsList extends Component {
  constructor(props) {
    super(props);

    this.onCreate = this.onCreate.bind(this);

    this.state = {
      comments: [],
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      comments: this.props.comments,
    });
  }

  onCreate() {
    IssuesAPI.getList(1, { person: this.state.personId }).then((result) => {
      this.setState({
        ...this.state,
        comments: result.results.answers,
      });
    });
    this.props.onCreate.bind(this)();
  }
  render() {
    const COMMENT_HISTORY_ICON_CLASS = {
      ...ISSUE_STATUS_ICONS,
      comment: 'fa-commenting',
    };

    let COMMENTS_REVERSE_LIST = this.state.comments
      ? [...this.state.comments]
      : [];
    return (
      <div>
        <div className="status-life mb-3">
            <span class="fa-stack">
              <i className="fa fa-stack-1x fa-dot-circle-o"></i>
            </span>

          {COMMENTS_REVERSE_LIST.reverse().map((comment, key) => (
            <span>
              <span class="fa-stack">
                <i className="fa fa-stack-1x fa-long-arrow-right"></i>
              </span>
              {(comment.type === 'no_call_answered') ? (
                <span class="fa-stack" title={ISSUE_STATUSES_LANG[comment.type]}>
                  <i className="fa fa-phone fa-stack-1x"></i>
                  <i class="fa fa-ban fa-stack-2x" style={{color:'Tomato'}}></i>
                </span>
              ) : (
                <span class="fa-stack" title={ISSUE_STATUSES_LANG[comment.type]}>
                  <i className={`fa fa-stack-1x ${COMMENT_HISTORY_ICON_CLASS[comment.type]}`}></i>
                </span>
              )}
            </span>
          ))}
        </div>
        {this.state.comments.map((comment, key) => (
          <div
            className="p-2 text-dark issue__comment comment__wrapper"
            key={key}
          >
            {comment.type == 'tours' ? (
              <div>
                Подборка туров{' '}
                <Link
                  to={'https://geograftour.com/geoclient/' + comment.text}
                  target="blank"
                >
                  {comment.text} 
                </Link>{' | '}
                <Link to={`https://crm.geograftour.com/#/quotes/${comment.text}`}> Подборка в CRM</Link>
                <span className="float-right">
                  {moment(comment.created).format('DD.MM.YY H:mm')}
                </span>
              </div>
            ) : (
              <div>
                {(comment.type === 'no_call_answered') ? (
                  <span className={`fa-stack mr-1 ${ISSUE_STATUS_COLOR[comment.type]}`} title={ISSUE_STATUSES_LANG[comment.type]}>
                    <i className="fa fa-phone fa-stack-1x"></i>
                    <i class="fa fa-ban fa-stack-2x" style={{color:'Tomato'}}></i>
                  </span>
                ) : (
                  <span class={`fa-stack mr-1 ${ISSUE_STATUS_COLOR[comment.type]}`} title={ISSUE_STATUSES_LANG[comment.type]}>
                    <i className={`fa fa-stack-1x ${COMMENT_HISTORY_ICON_CLASS[comment.type]}`}></i>
                  </span>
                )}
                {comment.text ? comment.text : '-'}{' '}
                <span className="float-right">
                  {moment(comment.created).format('DD.MM.YY H:mm')}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
