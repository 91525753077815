import React, { useEffect, useState } from 'react';
import BackButton from '../../../components/BackButton';
import { UsersAPI } from '../../../api/Client';
import { Link, useParams } from 'react-router-dom';

function EmployeesDetail() {
  const { employeeId } = useParams();

  const [employeeInfo, setEmployeeInfo] = useState();

  useEffect(() => {
    getInfo();
  }, []);

  function getInfo() {
    UsersAPI.fetchJSON(`${employeeId}/`)
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((res) => setEmployeeInfo(res))
      .catch((err) => console.log(err));
  }

  if (!employeeInfo) {
    return null;
  }
  const {
    id,
    is_active,
    last_name,
    first_name,
    username,
    email,
    manager_manager_fp,
    offices,
    profile,
    info,
  } = employeeInfo;

  const basic_profile_img = '/img/avatars/tourist-avatar.png';

  return (
    <div className="card p-4">
      <div className="row">
        <div className="col-md-6 col-lg-6 mb-4">
          <h4>
            {last_name} {first_name}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 mb-4">
          <img
            width="120"
            height="120"
            style={{ borderRadius: '50%', border: 'none' }}
            className="float-left mr-3"
            src={info.avatar ? info.avatar : basic_profile_img}
          />
          {is_active && (
            <p>
              <span className="badge badge-success">активен</span>
            </p>
          )}
          <p>Логин: {username}</p>
          <p>Тип: {profile?.role.name || '-'}</p>
          <p>
            Электронная почта: <b>{email}</b>
          </p>
        </div>
        <Link to={`/employees/${id}/edit/`}>Редактировать</Link>
      </div>
      {Array.isArray(offices) && offices.length ? (
        <div className="row">
          <div className="col-md-6 col-lg-6 mb-4">
            {offices.map((office) => (
              <p key={office.id} className="badge badge-success mr-1">
                <b>{office.name}</b>
              </p>
            ))}
          </div>
        </div>
      ) : null}
      {Array.isArray(manager_manager_fp) && manager_manager_fp.length ? (
        <div className="row">
          <p>Назначены заявки из системы бронирования:</p>
          <div className="col-md-6 col-lg-6 mb-4">
            {manager_manager_fp.map((manager) => (
              <p key={manager.manager_fp.id} className="badge badge-success">
                {`${manager.manager_fp.name} ${manager.manager_fp.fp_pk}`}
              </p>
            ))}
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-md-6 col-lg-6 mb-4">
          <BackButton />
        </div>
      </div>
    </div>
  );
}

export default EmployeesDetail;
