import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import { Button } from 'reactstrap';

import OfficeSelectContainer from '../../../components/OfficeSelectContainer/OfficeSelectContainer';

class IssuesReportFilter extends Component {
  constructor(props) {
    super(props);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeOfficeCity = this.changeOfficeCity.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.state = {
      startDate: '',
      endDate: '',
      offices: [],
      offices_city: null,
    };
  }
  componentDidMount() {
    if (this.props.initialFilter) {
      if (this.props.initialFilter.hasOwnProperty('created__gte')) {
        this.startDateChange(this.props.initialFilter.created__gte);
      }
      if (this.props.initialFilter.hasOwnProperty('created__lte')) {
        this.endDateChange(this.props.initialFilter.created__lte);
      }
    }
  }
  changeOffice(offices) {
    this.setState({ offices });
  }
  changeOfficeCity(offices_city) {
    this.setState({ offices_city });
  }
  filterItems(st) {
    let result = {};

    if (st.startDate) {
      result.created__gte = moment(st.startDate).format('DD.MM.YYYY');
    }
    if (st.endDate) {
      result.created__lte = moment(st.endDate).format('DD.MM.YYYY');
    }
    if (st.offices) {
      result.office = st.offices.map((office) => office.id);
    }

    return result;
  }
  startDateChange(startDate) {
    this.setState({ startDate });
  }

  endDateChange(endDate) {
    this.setState({ endDate });
  }
  handleReset() {
    this.setState({
      startDate: '',
      endDate: '',
      offices: [],
      offices_city: null,
    });
  }
  handleSubmit() {
    this.props.onFilter(this.filterItems(this.state));
  }
  render() {
    return (
      <>
        <div className="filter__office">
          <OfficeSelectContainer
            changeCity={this.changeOfficeCity}
            changeOffice={this.changeOffice}
            multi_office={true}
            selected_city={this.state.offices_city}
            selected_office={this.state.offices}
          >
            <div className="filter-field date-from">
              <label htmlFor="leadDate" className="col-form-label">
                Дата от
              </label>
              <DatePicker
                locale={ru}
                dateFormat="dd.MM.yyyy"
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.startDateChange}
                className="d-block"
                placeholderText="Дата начала"
                isClearable={this.state.startDate ? true : false}
              />
            </div>
            <div className="filter-field date-to">
              <label htmlFor="leadDate" className="col-form-label">
                Дата до
              </label>
              <DatePicker
                locale={ru}
                dateFormat="dd.MM.yyyy"
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.endDateChange}
                placeholderText="Дата окончания"
                isClearable={this.state.endDate ? true : false}
              />
            </div>
          </OfficeSelectContainer>
        </div>
        <div className="card-footer" style={{ paddingLeft: 0 }}>
          <Button
            className="btn btn-sm btn-success mr-2"
            style={{ width: '120px' }}
            onClick={this.handleSubmit}
          >
            Найти
          </Button>
          <Button
            className="btn btn-sm btn-success"
            style={{
              width: '120px',
              color: '#009436',
              backgroundColor: 'transparent',
            }}
            onClick={this.handleReset}
          >
            Сбросить
          </Button>
        </div>
      </>
    );
  }
}

export default IssuesReportFilter;
