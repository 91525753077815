import React, { Component, useEffect, useState } from 'react';
import BackButton from '../../../components/BackButton';
import ClientAPI, { PersonAPI } from '../../../api/Client';
import { Button, Input } from 'reactstrap';
import Select from 'react-select';
import 'react-phone-input-2/lib/style.css';
import EditInputPhone from '../../../components/EditInputPhone/EditInputPhone';
import EditInputEmail from '../../../components/EditInputEmail/EditInputEmail';
import { useParams } from 'react-router-dom';

function PersonEdit() {
  const { personId } = useParams();

  const [errors, setErrors] = useState();
  const [personInfo, setPersonInfo] = useState({});
  const [initialPersonInfo, setInitialPersonInfo] = useState({});
  const [isFormChanged, setFormChanged] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [tags, setTags] = useState(false);

  function handleChange(e) {
    const { name, value } = e.target;
    setPersonInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (!isFormChanged) {
      setFormChanged(true);
    }
  }

  function handleTagsOnChange(tags) {
    setTags(tags);
    setFormChanged(true);
  }

  function fetchTags() {
    ClientAPI.getJSON('/tags/full_list/').then((res) => setTagsList(res || []));
  }

  function handleSave() {
    PersonAPI.modify(personId, getChangedValues())
      .then((r) => r.json())
      .then((result) => {
        if (!result.id) {
          setErrors(result);
        } else {
          setErrors(false);
          setFormChanged(false);
        }
      });
  }

  function getChangedValues() {
    let result = {};
    if (personInfo.name !== initialPersonInfo.name) {
      result.name = personInfo.name || null;
    }
    if (personInfo.last_name !== initialPersonInfo.last_name) {
      result.last_name = personInfo.last_name;
    }
    if (personInfo.middle_name !== initialPersonInfo.middle_name) {
      result.middle_name = personInfo.middle_name;
    }
    if (personInfo.phone !== initialPersonInfo.phone) {
      if (personInfo.phone) {
        result.phone = personInfo.phone;
      } else {
        result.phone = personInfo.phone;
      }
    }
    if (personInfo.email !== initialPersonInfo.email) {
      if (personInfo.email) {
        result.email = personInfo.email;
      } else {
        result.email = personInfo.email;
      }
    }
    if (personInfo.born !== initialPersonInfo.born) {
      result.born = personInfo.born;
    }
    if (personInfo.passport_series !== initialPersonInfo.passport_series) {
      result.passport_series = personInfo.passport_series;
    }
    if (personInfo.passport_number !== initialPersonInfo.passport_number) {
      result.passport_number = personInfo.passport_number;
    }
    if (personInfo.passport_expires !== initialPersonInfo.passport_expires) {
      result.passport_expires = personInfo.passport_expires;
    }
    if (personInfo.info !== initialPersonInfo.info) {
      result.info = personInfo.info;
    }
    if (tags !== initialPersonInfo.tag) {
      result.tag = tags.map((tag) => tag.id);
    }
    for (let k in result) {
      if (result[k] === '') {
        result[k] = null;
      }
    }
    return result;
  }

  useEffect(() => {
    PersonAPI.getInfo(personId).then((result) => {
      if (!result.id) {
        setErrors(result);
      } else {
        setPersonInfo(result);
        setTags(result.tag);
        setInitialPersonInfo(result);
        setErrors(false);
        fetchTags();
      }
    });
  }, []);

  const {
    last_name,
    name,
    phone,
    middle_name,
    email = [{ value: '' }],
    born,
    passport_series,
    passport_number,
    passport_expires,
    info,
  } = personInfo;

  return (
    <div className="animated fadeIn filter filter--green">
      <div className="row">
        <div className="col-sm-8 col-md-8">
          <div className="card card-accent-primary">
            <div className="card-body">
              {personInfo ? (
                <>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Фамилия:
                    </label>
                    <div className="col-md-10">
                      <input
                        type="text"
                        id="last-name-input"
                        name="last_name"
                        value={last_name}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Имя:
                    </label>
                    <div className="col-md-10">
                      <input
                        type="text"
                        id="name-input"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Отчество:
                    </label>
                    <div className="col-md-10">
                      <input
                        type="text"
                        id="middle-name-input"
                        name="middle_name"
                        value={middle_name}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Телефоны:
                    </label>
                    <EditInputPhone
                      phones={phone}
                      onChange={(phone) => {
                        setPersonInfo((prevState) => ({
                          ...prevState,
                          phone,
                        }));
                        if (!isFormChanged) {
                          setFormChanged(true);
                        }
                      }}
                      errors={
                        errors && errors.phone !== undefined
                          ? errors.phone
                          : ''
                      }
                    />
                  </div>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Эл.почта:
                    </label>
                      {typeof email === 'object' ? (
                        <EditInputEmail
                          emails={email}
                          onChange={(email) => {
                            setPersonInfo((prevState) => ({
                              ...prevState,
                              email,
                            }));
                            if (!isFormChanged) {
                              setFormChanged(true);
                            }
                          }}
                          errors={
                            errors && errors.email !== undefined
                              ? errors.email
                              : ''
                          }
                        />
                      ) : (
                        <div className="col-md-10">
                          <input
                            type="email"
                            id="email-input"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      )}
                  </div>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Рожден:
                    </label>
                    <div className="col-md-10">
                      <input
                        type="date"
                        id="born-input"
                        name="born"
                        value={born|| ''}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Метки:
                    </label>
                    <div className="col-md-10">
                      <Select
                        id="tagsTouristID"
                        className="mb-3"
                        name="tags"
                        placeholder="Выберите из спиcка..."
                        isMulti={true}
                        closeOnSelect={true}
                        value={tags}
                        onChange={handleTagsOnChange}
                        getOptionValue={(option) => option.id}
                        formatOptionLabel={(option) => option.name}
                        options={tagsList}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Паспорт:
                    </label>
                    <div className="col-md-3">
                      <Input
                        type="number"
                        id="passport-series-input"
                        name="passport_series"
                        value={passport_series || ''}
                        onChange={handleChange}
                        className="p-2"
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        type="number"
                        id="passport-number-input"
                        name="passport_number"
                        value={passport_number || ''}
                        onChange={handleChange}
                        className="p-2"
                      />
                    </div>
                    <div className="col-md-4 ">
                      <Input
                        type="date"
                        id="passport-expires-input"
                        name="passport_expires"
                        value={passport_expires || ''}
                        onChange={handleChange}
                        className="p-2"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Информация:
                    </label>
                    <div className="col-md-10">
                      <textarea
                        id="info"
                        name="info"
                        rows="9"
                        value={info || ''}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Информация о клиенте..."
                      />
                    </div>
                  </div>
                  <BackButton />
                  <Button
                    color={isFormChanged ? 'success' : 'secondary'}
                    disabled={!isFormChanged}
                    onClick={handleSave}
                  >
                    <i className="fa fa-dot-circle-o">{}</i> Сохранить
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonEdit;
