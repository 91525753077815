import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { Alert, Input, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import { ADMIN, getUserRole, SUPER_ADMIN } from '../../../connect/auth';
import { POSITIVE_ACTION_STATUSES, AnswersAPI, IssuesAPI } from '../../../api/Client';
import {
  ISSUE_SOURCE_MAP,
  ISSUE_STATUSES,
  STATUS_DESCRIPTION_MAP,
  ISSUE_STATUS_COLOR,
  ISSUE_STATUS_ICONS,
  ISSUE_STATUSES_LANG,
} from '../constants';

import CommentsList from './CommentsList';
import IssueApproach from './IssueApproach';
import IssueCommentForm from './IssueCommentForm';
import IssueCompleteForm from './IssueCompleteForm';
import IssueDeleteForm from './IssueDeleteForm';
import IssueFinally from './IssueFinally';
import IssueHandlingObjections from './IssueHandlingObjections';
import IssueManagerForm from './IssueManagerForm';
import IssueMergerForm from './IssueMergerForm';
import IssueNeeds from './IssueNeeds';
import IssuePresentation from './IssuePresentation';
import IssueQuoteAttach from './IssueQuoteAttach';
import IssueSaleRules from './IssueSaleRules';
import IssueSendEmailForm from './IssueSendEmailForm';
import IssueSendSmsForm from './IssueSendSmsForm';


export default class IssueItem extends Component {
  constructor(props) {
    super(props);

    this.onCreate = this.onCreate.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.actionWhatsApp = this.actionWhatsApp.bind(this);
    this.actionTelegram = this.actionTelegram.bind(this);

    this.state = {
      is_show: false,
      activeTab: '',
      status: '',
    };
  }

  componentDidMount() {
    this.setState({status: this.props.item.status});
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({activeTab: tab});
    } else {
      this.setState({activeTab: ''})
    }
  }

  toggleStatusTab(status) {
    const tab = `status_${status}`;
    if (this.state.status !== status && this.state.activeTab !== tab) {
      this.setState({activeTab: tab});
    }
    if (this.state.activeTab === tab) {
      this.setState({activeTab: ''})
    }
  }

  onCreate() {
    this.setState({is_show: false});
    this.props.onCreate.bind(this)();
  }

  changeStatus(status) {
    if (this.state.status !== status) {
      this.setState({status});
      // IssuesAPI.modify(this.props.item.id, {status})
      //   .then((r) => r.json())
      //   .then((result) => {
      //     this.setState({status});
      //   });
    }
    if (this.state.activeTab === `status_${status}`) {
      this.setState({activeTab: ''});
    }
  }

  actionWhatsApp(phone) {
    if (phone.startsWith('+')) {
      phone = phone.slice(1);
    }
    const uri = encodeURI(`https://wa.me/${phone}?text=${this.state.whatsapp_message}`);
    window.open(uri, '_blank');
  }

  actionTelegram(phone) {
    if (!phone.startsWith('+')) {
      phone = `+${phone}`;
    }
    const { item } = this.props;
    const uri = `https://t.me/${phone}?text=${this.state.telegram_message}`;
    window.open(uri, '_blank');
  }

  render() {
    const { item } = this.props;
    const city = item.city ? item.city : 'не указан';
    const { activeTab } = this.state;
    const userRole = getUserRole();
    const IS_LOCK = item.status === 'order';

    let phone = (this.props.info && this.props.info.phone) ? this.props.info.phone : null;
    if (Array.isArray(phone)) {
      if (phone.length > 0 && phone[0].hasOwnProperty('value')) {
        phone = phone[0].value;
      } else {
        phone = null;
      }
    }

    function parseDescriptionIssue(text) {
      let Tour = {};
      try {
        text
          .split(',')
          .map((f) =>
            console.log((Tour[f.split(':')[0].trim()] = f.split(':')[1].trim()))
          );
      } catch (e) {
        return text;
      }
      return (
        <div>
          <p>
            <span>{Tour.country}</span>, <span>{Tour.town}</span>
          </p>
          <p>
            Отель:{' '}
            <span>
              {Tour.hotel} {Tour.star_name}*
            </span>
          </p>
          <p>
            Тип номера: <span>{Tour.room}</span>
          </p>
          <p>
            Питание: <span>{Tour.meal}</span>
          </p>
          <p>
            Туроператор: <span>{Tour.oper}</span>
          </p>
          <p>
            Даты вылета:{' '}
            <span>
              {Tour.dt1} - {Tour.dt2}
            </span>
          </p>
          <p>
            Взрослых: <span>{Tour.adult}</span>
          </p>
          {!!parseInt(Tour.child) && (
            <p>
              <span>Детей:</span>
              <span>{Tour.child}</span>
            </p>
          )}
          <p>
            Город вылета: <span>{Tour.depart_name}</span>
          </p>
          {Tour.hasOwnProperty('amount') && (
            <p>
              <span>Стоимость:</span>
              <span>{Tour.amount}</span>
            </p>
          )}
          <p>
            Валюта: <span>{Tour.currency}</span>
          </p>
        </div>
      );
    }

    function parseTitleIssue(text) {
      let Tour = {};
      try {
        text
          .split(',')
          .map((f) =>
            console.log((Tour[f.split(':')[0].trim()] = f.split(':')[1].trim()))
          );
      } catch (e) {
        return text;
      }
      return (
        <span>
          {Tour.country}, {Tour.town} {Tour.dt1} - {Tour.dt2}
        </span>
      );
    }

    return (
      <div className="card card-accent-success">
        <div className="card-header">
          <span className="badge badge-primary badge-pill"> №{item.id} </span>
          <span> {parseTitleIssue(item.title)} </span>
          <small>от {moment(item.created).format('DD.MM.YY HH:mm')}</small>
          <span className={`badge badge-pill float-right ${ISSUE_STATUS_COLOR[this.state.status]}`}>
            {(this.state.status === 'no_call_answered') ? (
              <span class="fa-stack">
                <i className="fa fa-phone fa-stack-1x"></i>
                <i class="fa fa-ban fa-stack-2x" style={{color:'Tomato'}}></i>
              </span>
            ) : (
              <i className={`fa ${ISSUE_STATUS_ICONS[this.state.status]}`}></i>
            )}
            {' '}
            {ISSUE_STATUSES_LANG[this.state.status]}
          </span>
        </div>
        <div className="card-body">
          <div className="issues-tab-list">

{/* ВОРОНКА ПРОДАЖ */}
            <Nav>
              <NavItem>
                <NavLink disabled><b>ВОРОНКА ПРОДАЖ</b></NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'sales__needs'}
                  onClick={() => this.toggleTab('sales__needs')}
                >
                  <i className="fa fa-check-square-o">{''}</i> Выявление потребностей
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'sales___quote'}
                  onClick={() => this.toggleTab('sales___quote')}
                >
                  <i className="fa fa-tasks">{''}</i> Подборка туров
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'sales__presentation'}
                  onClick={() => this.toggleTab('sales__presentation')}
                >
                  <i className="fa fa-star">{''}</i> Презентация
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'sales__objections'}
                  onClick={() => this.toggleTab('sales__objections')}
                >
                  <i className="fa fa-hourglass-half">{''}</i> Работа с возраженьями
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'sales__letter'}
                  onClick={() => this.toggleTab('sales__letter')}
                >
                  <i className="fa fa-history">{''}</i> Финальное письмо
                </NavLink>
              </NavItem>
              {item.order && item.person ? (
                <NavItem>
                  <NavLink
                    active={activeTab === 'sales__merge'}
                    onClick={() => this.toggleTab('sales__merge')}
                  >
                    <i className="fa fa-tasks">{''}</i> Слияние
                  </NavLink>
                </NavItem>
              ) : (
                ''
              )}
            </Nav>
            <TabContent className="col-md-12" activeTab={activeTab}>
              <TabPane tabId="sales__needs">
                <IssueNeeds id={item.id} need={item.need} />
              </TabPane>
              <TabPane tabId="sales___quote">
                <IssueQuoteAttach
                  id={item.id}
                  info={this.props.info}
                  onCreate={this.onCreate}
                  isLock={IS_LOCK}
                />
              </TabPane>
              <TabPane tabId="sales__presentation">
                <IssuePresentation />
              </TabPane>
              <TabPane tabId="sales__objections">
                <IssueHandlingObjections />
              </TabPane>
              <TabPane tabId="sales__letter">
                <IssueFinally />
              </TabPane>
              {item.order && item.person && (
                <TabPane tabId="sales__merge">
                  <IssueMergerForm
                    issue={item}
                    type="8"
                    onCreate={this.onCreate}
                  />
                </TabPane>
              )}
            </TabContent>
{/* / ВОРОНКА ПРОДАЖ */}

{/* ДЕЙСТВИЯ */}
            <Nav className="badge-default">
              <NavItem>
                <NavLink disabled><b>ДЕЙСТВИЯ</b></NavLink>
              </NavItem>
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <NavItem>
                  <NavLink
                    active={activeTab === 'action__manager'}
                    onClick={() => this.toggleTab('action__manager')}
                  >
                    <i className="fa fa-user">{''}</i> Выбрать менеджера
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  active={activeTab === 'action__comment'}
                  onClick={() => this.toggleTab('action__comment')}
                >
                  <i className="fa fa-comment">{''}</i> Комментарий
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'action__call'}
                  onClick={() => this.toggleTab('action__call')}
                >
                  <i className="fa fa-phone">{''}</i> Назначить звонок
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'action__calendar'}
                  onClick={() => this.toggleTab('action__calendar')}
                >
                  <i className="fa fa-calendar">{''}</i> Добавить событие в календарь
                </NavLink>
              </NavItem>
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <NavItem>
                  <NavLink
                    active={activeTab === 'action__sms'}
                    onClick={() => this.toggleTab('action__sms')}
                  >
                    <i className="fa fa-comment-o">{''}</i> Отправить SMS
                  </NavLink>
                </NavItem>
              )}
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <NavItem>
                  <NavLink
                    active={activeTab === 'action__whatsapp'}
                    onClick={() => this.toggleTab('action__whatsapp')}
                  >
                    <i className="fa fa-whatsapp">{''}</i> Отправить в WhatsApp
                  </NavLink>
                </NavItem>
              )}
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <NavItem>
                  <NavLink
                    active={activeTab === 'action__telegram'}
                    onClick={() => this.toggleTab('action__telegram')}
                  >
                    <i className="fa fa-telegram">{''}</i> Отправить в Telegram
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  active={activeTab === 'action__remove'}
                  onClick={() => this.toggleTab('action__remove')}
                >
                  <i className="fa fa-trash">{''}</i> Удалить
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="col-md-12" activeTab={activeTab}>
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <TabPane tabId="action__manager">
                  <IssueManagerForm
                    id={item.id}
                    isLock={IS_LOCK}
                    manager={item.manager}
                    type="7"
                    onCreate={this.onCreate}
                  />
                </TabPane>
              )}
              <TabPane tabId="action__comment">
                <IssueCommentForm
                  id={item.id}
                  type="comment"
                  onCreate={this.onCreate}
                  isLock={IS_LOCK}
                />
              </TabPane>
              <TabPane tabId="action__call">
                <IssueCommentForm
                  id={item.id}
                  type="call"
                  onCreate={this.onCreate}
                  isLock={IS_LOCK}
                />
              </TabPane>
              <TabPane tabId="action__calendar">
                <IssueCommentForm
                  id={item.id}
                  type="meet"
                  onCreate={this.onCreate}
                  isLock={IS_LOCK}
                />
              </TabPane>
              <TabPane tabId="action__sms">
                <IssueSendSmsForm
                  id={item.id}
                  item={item}
                  onCreate={this.onCreate}
                  isLock={true}
                />
              </TabPane>
              <TabPane tabId="action__whatsapp">
                <h4>Отправить в WhatsApp</h4>
                {phone ? (
                  <form>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <textarea
                          autoFocus={true}
                          className="form-control mb-4"
                          placeholder="Сообщение"
                          rows="5"
                          maxLength="1024"
                          onChange={(e) => this.setState({whatsapp_message: e.target.value})}
                          value={this.state.whatsapp_message}
                        />
                      </div>
                      <div className="col-md-12 pb-2">
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => this.actionWhatsApp(phone)}
                          disabled={!this.state.whatsapp_message}
                        >
                          <i className="fa fa-whatsapp"></i> Отправить
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <Alert color="danger">У туриста не указан телефон</Alert>
                )}
              </TabPane>
              <TabPane tabId="action__telegram">
                <h4>Отправить в Telegram</h4>
                {phone ? (
                  <form>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <textarea
                          autoFocus={true}
                          className="form-control mb-4"
                          placeholder="Сообщение"
                          rows="5"
                          maxLength="1024"
                          onChange={(e) => this.setState({telegram_message: e.target.value})}
                          value={this.state.telegram_message}
                        />
                      </div>
                      <div className="col-md-12 pb-2">
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => this.actionTelegram(phone)}
                          disabled={!this.state.telegram_message}
                        >
                          <i className="fa fa-telegram"></i> Отправить
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <Alert color="danger">У туриста не указан телефон</Alert>
                )}
              </TabPane>
              <TabPane tabId="action__remove">
                <IssueDeleteForm
                  issueId={item.id}
                  isLock={IS_LOCK}
                  onChangeActivity={this.props.onChangeActivity}
                />
              </TabPane>
            </TabContent>
{/* / ДЕЙСТВИЯ */}

{/* СТАТУСЫ */}
            <Nav>
              <NavItem>
                <NavLink disabled><b>СТАТУСЫ</b></NavLink>
              </NavItem>
              <NavItem active={this.state.status === 'called' || activeTab === 'status_called'}>
                <NavLink
                  active={activeTab === 'status_called'}
                  className={(this.state.status === 'called') ? ISSUE_STATUS_COLOR[this.state.status] : ''}
                  onClick={() => {this.toggleStatusTab('called')}}
                >
                  <span class="fa-stack">
                    <i className={`fa fa-stack-1x ${ISSUE_STATUS_ICONS['called']}`}></i>
                  </span>{' '}{ISSUE_STATUSES_LANG['called']}
                </NavLink>
              </NavItem>
              <NavItem active={this.state.status === 'no_call_answered' || activeTab === 'status_no_call_answered'}>
                <NavLink
                  active={activeTab === 'status_no_call_answered'}
                  className={(this.state.status === 'no_call_answered') ? ISSUE_STATUS_COLOR[this.state.status] : ''}
                  onClick={() => {this.toggleStatusTab('no_call_answered')}}
                >
                  <span class="fa-stack">
                    <i className="fa fa-phone fa-stack-1x"></i>
                    <i class="fa fa-ban fa-stack-2x" style={{color:'Tomato'}}></i>
                  </span>{' '}{ISSUE_STATUSES_LANG['no_call_answered']}
                </NavLink>
              </NavItem>
              <NavItem active={this.state.status === 'whatsapp' || activeTab === 'status_whatsapp'}>
                <NavLink
                  active={activeTab === 'status_whatsapp'}
                  className={(this.state.status === 'whatsapp') ? ISSUE_STATUS_COLOR[this.state.status] : ''}
                  onClick={() => {this.toggleStatusTab('whatsapp')}}
                >
                  <span class="fa-stack">
                    <i className={`fa fa-stack-1x ${ISSUE_STATUS_ICONS['whatsapp']}`}></i>
                  </span>{' '}{ISSUE_STATUSES_LANG['whatsapp']}
                </NavLink>
              </NavItem>
              <NavItem active={this.state.status === 'quotes_from_crm' || activeTab === 'status_quotes_from_crm'}>
                <NavLink
                  active={activeTab === 'status_quotes_from_crm'}
                  className={(this.state.status === 'quotes_from_crm') ? ISSUE_STATUS_COLOR[this.state.status] : ''}
                  onClick={() => {this.toggleStatusTab('quotes_from_crm')}}
                >
                  <span class="fa-stack">
                    <i className={`fa fa-stack-1x ${ISSUE_STATUS_ICONS['quotes_from_crm']}`}></i>
                  </span>{' '}{ISSUE_STATUSES_LANG['quotes_from_crm']}
                </NavLink>
              </NavItem>
              <NavItem active={this.state.status === 'deffered' || activeTab === 'status_deffered'}>
                <NavLink
                  active={activeTab === 'status_deffered'}
                  className={(this.state.status === 'deffered') ? ISSUE_STATUS_COLOR[this.state.status] : ''}
                  onClick={() => {this.toggleStatusTab('deffered')}}
                >
                  <span class="fa-stack">
                    <i className={`fa fa-stack-1x ${ISSUE_STATUS_ICONS['deffered']}`}></i>
                  </span>{' '}{ISSUE_STATUSES_LANG['deffered']}
                </NavLink>
              </NavItem>
              <NavItem active={this.state.status === 'order' || activeTab === 'status_order'}>
                <NavLink
                  active={activeTab === 'status_order'}
                  className={(this.state.status === 'order') ? ISSUE_STATUS_COLOR[this.state.status] : ''}
                  onClick={() => {this.toggleStatusTab('order')}}
                >
                  <span class="fa-stack">
                    <i className={`fa fa-stack-1x ${ISSUE_STATUS_ICONS['order']}`}></i>
                  </span>{' '}{ISSUE_STATUSES_LANG['order']}
                </NavLink>
              </NavItem>
              <NavItem active={this.state.status === 'we_cancel' || activeTab === 'status_we_cancel'}>
                <NavLink
                  active={activeTab === 'status_we_cancel'}
                  className={(this.state.status === 'we_cancel') ? ISSUE_STATUS_COLOR[this.state.status] : ''}
                  onClick={() => {this.toggleStatusTab('we_cancel')}}
                >
                  <span class="fa-stack">
                    <i className={`fa fa-stack-1x ${ISSUE_STATUS_ICONS['we_cancel']}`}></i>
                  </span>{' '}{ISSUE_STATUSES_LANG['we_cancel']}
                </NavLink>
              </NavItem>
              <NavItem active={this.state.status === 'client_cancel' || activeTab === 'status_client_cancel'}>
                <NavLink
                  active={activeTab === 'status_client_cancel'}
                  className={(this.state.status === 'client_cancel') ? ISSUE_STATUS_COLOR[this.state.status] : ''}
                  onClick={() => {this.toggleStatusTab('client_cancel')}}
                >
                  <span class="fa-stack">
                    <i className={`fa fa-stack-1x ${ISSUE_STATUS_ICONS['client_cancel']}`}></i>
                  </span>{' '}{ISSUE_STATUSES_LANG['client_cancel']}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="col-md-12" activeTab={activeTab}>
              <TabPane tabId="status_called">
                 <IssueCommentForm
                  id={item.id}
                  type="called"
                  comment={ISSUE_STATUSES_LANG['called']}
                  isLock={IS_LOCK}
                  onCreate={() => this.changeStatus('called')}
                />
              </TabPane>
              <TabPane tabId="status_no_call_answered">
                 <IssueCommentForm
                  id={item.id}
                  type="no_call_answered"
                  comment={ISSUE_STATUSES_LANG['no_call_answered']}
                  isLock={IS_LOCK}
                  onCreate={() => this.changeStatus('no_call_answered')}
                />
              </TabPane>
              <TabPane tabId="status_whatsapp">
                 <IssueCommentForm
                  id={item.id}
                  type="whatsapp"
                  comment={ISSUE_STATUSES_LANG['whatsapp']}
                  isLock={IS_LOCK}
                  onCreate={() => this.changeStatus('whatsapp')}
                />
              </TabPane>
              <TabPane tabId="status_quotes_from_crm">
                 <IssueCommentForm
                  id={item.id}
                  type="quotes_from_crm"
                  comment={ISSUE_STATUSES_LANG['quotes_from_crm']}
                  isLock={IS_LOCK}
                  onCreate={() => this.changeStatus('quotes_from_crm')}
                />
              </TabPane>
              <TabPane tabId="status_deffered">
                 <IssueCommentForm
                  id={item.id}
                  type="deffered"
                  comment={ISSUE_STATUSES_LANG['deffered']}
                  isLock={IS_LOCK}
                  onCreate={() => this.changeStatus('deffered')}
                />
              </TabPane>
              <TabPane tabId="status_order">
                <IssueCompleteForm
                  id={item.id}
                  fp_number={item.fp_number}
                  isLock={IS_LOCK}
                  onCreate={() => this.changeStatus('order')}
                />
              </TabPane>
              <TabPane tabId="status_we_cancel">
                 <IssueCommentForm
                  id={item.id}
                  type="we_cancel"
                  comment={ISSUE_STATUSES_LANG['we_cancel']}
                  isLock={IS_LOCK}
                  onCreate={() => this.changeStatus('we_cancel')}
                />
              </TabPane>
              <TabPane tabId="status_client_cancel">
                 <IssueCommentForm
                  id={item.id}
                  type="client_cancel"
                  comment={ISSUE_STATUSES_LANG['client_cancel']}
                  isLock={IS_LOCK}
                  onCreate={() => this.changeStatus('client_cancel')}
                />
              </TabPane>
            </TabContent>
{/* / СТАТУСЫ */}

          </div>

          <div>
            <div className="p-3 mb-3" style={{backgroundColor: '#fff', border: '1px solid #20a8d8'}}>
              {parseDescriptionIssue(item.text)}
              {item.text.includes('depart_name:')
                ? ''
                : ' - город вылета: ' + city}
            </div>
            <CommentsList comments={item.answers} />
          </div>
        </div>
      </div>
    );
  }
}
