import React, { Component } from 'react';
import 'moment/locale/ru';

import CampaignFilter from './components/CampaignFilter';
import CampaignList from './components/CampaignList';
import CampaignListMobile from './components/CampaignListMobile';
import Paginator from '../../components/Paginator';
import { MailingAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client';
import ProgressBar from '../../components/ProgressBar';

export const SIMPLE = 1;
export const NOTIFICATION = 2;
export const CAMPAIGN_INIT_TYPES = {
  [SIMPLE]: 'simple',
  [NOTIFICATION]: 'notification',
};

export default class Mailbox extends Component {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.resetHandler = this.resetHandler.bind(this);

    this.state = {
      items: [],
      hasNext: false,
      hasPrev: false,
      pageNumber: 1,
      count: 0,
      itemDispatchTypeID: 0,
      isLoading: false,
    };
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({ pageNumber: page }, () => this.fetchItems(page));
  }

  fetchItems(page) {
    let status;
    const { campaignInitType } = this.props;
    let { filter } = this.state;
    this.setState({ isLoading: true });

    if (this.state.itemDispatchTypeID === 0) {
      MailingAPI.fetchList(
        page,
        filter,
        `${CAMPAIGN_INIT_TYPES[campaignInitType]}/`
      )
        .then((r) => {
          status = r.status;
          return r.json();
        })
        .then((result) => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              ...this.state,
              items: result.results || [],
              hasNext: result.next != null,
              hasPrev: result.previous != null,
              count: result.count,
              isLoading: false,
            });
          }
        });
    } else {
      MailingAPI.fetchList(
        page,
        { dispatch_type: this.state.itemDispatchTypeID },
        `${CAMPAIGN_INIT_TYPES[campaignInitType]}/`
      )
        .then((r) => {
          status = r.status;
          return r.json();
        })
        .then((result) => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              ...this.state,
              items: result.results || [],
              hasNext: result.next != null,
              hasPrev: result.previous != null,
              count: result.count,
              isLoading: false,
            });
          }
        });
    }
  }

  componentDidMount() {
    this.resetHandler();
    this.fetchItems(1);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.campaignInitType !== prevProps.campaignInitType) {
      this.setState({
        ...this.state,
        itemDispatchTypeID: 0,
      });
      this.fetchItems(1);
    }
    if (this.state.itemDispatchTypeID !== prevState.itemDispatchTypeID) {
      this.fetchItems(1);
    }
  }

  handleClickItem(itemDispatchTypeID) {
    this.setState(
      {
        ...this.state,
        itemDispatchTypeID,
      },
      () => {
        let status;
        let page = this.state.pageNumber;
        const { campaignInitType } = this.props;
        MailingAPI.fetchList(
          page,
          { dispatch_type: itemDispatchTypeID },
          `${CAMPAIGN_INIT_TYPES[campaignInitType]}/`
        )
          .then((r) => {
            status = r.status;
            return r.json();
          })
          .then((result) => {
            if (status === POSITIVE_ACTION_STATUSES.list) {
              this.setState({
                items: result.results || [],
                hasNext: result.next != null,
                hasPrev: result.previous != null,
                count: result.count,
              });
            }
          });
      }
    );
  }

  handleFilter(filter) {
    this.setState(
      {
        filter,
      },
      () => this.fetchItems()
    );
  }

  resetHandler() {
    this.setState({
      ...this.state,
      itemDispatchTypeID: 0,
    });
  }

  render() {
    const { items, count, itemDispatchTypeID, isLoading } = this.state;
    const { isDesktop } = this.props;
    return (
      <div className="animated fadeIn">
        <CampaignFilter
          onFilter={this.handleFilter}
          chooseToRepeat={this.props.chooseToRepeat}
        />
        <div className="card" style={{ position: 'unset' }}>
          <div className="card-header">
            <i className="fa fa-align-justify">{}</i> Всего&nbsp;
            <span className="badge badge-info">{count}</span>
            {itemDispatchTypeID === 0 ? null : (
              <button
                className="btn btn-sm btn-primary"
                onClick={this.resetHandler}
              >
                Сбросить фильтр
              </button>
            )}
          </div>
          <div className="card-body card-body_responsive">
            {isLoading ? (
              <ProgressBar />
            ) : isDesktop ? (
              <CampaignList
                items={items}
                handleClickItem={this.handleClickItem}
              />
            ) : (
              <CampaignListMobile
                items={items}
                chooseToRepeat={this.props.chooseToRepeat}
              />
            )}
            <Paginator total={count} onPageChange={this.handlePageChange} />
          </div>
        </div>
      </div>
    );
  }
}
