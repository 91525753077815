import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Table, Card, CardHeader } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/ru';

import { AgenciesAPI, POSITIVE_ACTION_STATUSES } from '../../../api/Client';
import BackButton from '../../../components/BackButton';
import AgencyOptions from './AgencyOptions';
import AgencySalesGraph from '../../Components/AgencySalesGraph/AgencySalesGraph';

function Agency() {
  const { agencyId } = useParams();

  const [agencyInfo, setAgencyInfo] = useState();

  useEffect(() => {
    getInfo();
  }, []);

  function getInfo() {
    let status;
    AgenciesAPI.fetchJSON(`${agencyId}/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.retrieve) {
          setAgencyInfo(r);
        }
      });
  }

  if (!agencyInfo) {
    return null;
  }

  const office = !!agencyInfo.offices.length
    ? agencyInfo.offices[0]
    : { address: '' };

  let summary = {
    tourists: 0,
    leads: 0,
    leads_to_order: 0,
    issues: 0,
    issues_this_week: 0,
  };

  if (!!agencyInfo.offices.length) {
    summary = agencyInfo.offices[0].summary;
  }

  return (
    <div className="animated fadeIn filter--green">
      <div className="col-md-12">
        <Card>
          <CardHeader>
            <h3>{office.address}</h3>
          </CardHeader>
          <div className="card-body">
            <Row>
              <Col xs="12" className="agency__col">
                <label className="mr-2 agency__col_title">Название:</label>
                <span className="agency__col_data">
                  {agencyInfo.public_name}
                </span>
                <label className="mr-2 agency__col_title">Email:</label>
                <span className="agency__col_data">{agencyInfo.email}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="agency__col">
                <label className="mr-2 agency__col_title">Дата открытия:</label>
                <span className="agency__col_data">
                  {moment(agencyInfo.opened_date).format('DD.MM.YYYY')}
                </span>
                <label className="mr-2 agency__col_title">Руководитель:</label>
                <span className="agency__col_data">{agencyInfo.director}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="agency__col">
                <label className="mr-2 agency__col_title">
                  С опытом или без:
                </label>
                <span className="agency__col_data">
                  {agencyInfo.with_experience ? 'Да' : 'Нeт'}
                </span>
                <label className="mr-2 agency__col_title">Телефоны:</label>
                <span className="agency__col_data">{agencyInfo.phones}</span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs="12" className="agency__col">
                <label className="mr-2 agency__col_title">номер в CRM:</label>
                <span className="agency__col_data">{agencyInfo.id}</span>
                <label className="mr-2 agency__col_title">номер в ФСУ:</label>
                <span className="agency__col_data">{agencyInfo.fp_index}</span>
              </Col>
            </Row>
            <Table striped bordered size="sm" className="font-xs table--orange">
              <thead>
                <tr>
                  <th className="text-center hidden  tableItem">*</th>
                  <th className="text-center tableItem hidden ">
                    Продажи за текущий год
                  </th>
                  <th className="text-center tableItem">Клиентская база</th>
                  <th className="text-center tableItem hidden ">
                    Заявки с сайта
                  </th>
                  <th className="text-center tableItem">Перешли в покупку</th>
                  <th className="text-center tableItem">Запросы на туры</th>
                  <th className="text-center tableItem hidden ">
                    Запросы на тур(за неделю)
                  </th>
                  <th className="text-center tableItem hidden ">
                    Sms рассылка
                  </th>
                  <th className="text-center tableItem">Email рассылка</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="text-center hidden  tableItem">
                    <input type="checkbox" />
                  </th>
                  <th className="text-center tableItem hidden ">
                    {agencyInfo.sales_per_year}
                  </th>
                  <th className="text-center tableItem">
                    {summary ? summary.tourists : null}
                  </th>
                  <th className="text-center tableItem hidden ">
                    {summary ? summary.leads : null}
                  </th>
                  <th className="text-center tableItem">
                    {summary ? summary.leads_to_order : null}
                  </th>
                  <th className="text-center tableItem">
                    {summary ? summary.issues : null}
                  </th>
                  <th className="text-center tableItem hidden ">
                    {summary ? summary.issues_this_week : null}
                  </th>
                  <th className="text-center tableItem hidden ">0</th>
                  <th className="text-center tableItem">0</th>
                </tr>
              </tbody>
            </Table>
            <AgencySalesGraph agencyId={agencyId} />
            <AgencyOptions
              agencyId={agencyId}
              data={agencyInfo}
              onUpdate={getInfo}
              item={agencyInfo}
            />
            {agencyInfo.comments && agencyInfo.comments.length ? (
              <div className="mb-3">
                <h5>Комментарий:</h5>
                {agencyInfo.comments.map((comment) => (
                  <div
                    key={comment.id}
                    className="p-2 mb-1"
                    style={{
                      backgroundColor: '#d9eee0',
                      display: 'flex',
                      borderRadius: '5px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>{comment.text}</div>
                    <div>{moment(comment.created).format('DD.MM.YYYY')}</div>
                  </div>
                ))}
              </div>
            ) : null}
            <BackButton />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Agency;
