import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { FranchiseApplicationList } from './FranchiseApplicationList';
import { FranchiseApplicationFilter } from './FranchiseApplicationFilter';
import {
  FranchiseApplicationAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../api/Client';
import ProgressBar from '../../components/ProgressBar';
import { Alert, Button, CardBody, CardHeader, Row } from 'reactstrap';
import Paginator from '../../components/Paginator';
import FranchiseApplicationAction from './FranchiseApplicationAction';
import Swal from 'sweetalert';
import { clearAllFilterParams } from '../../Utils/clearEmailfilter';

export default class FranchiseApplications extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchFranchiseApplications =
      this.fetchFranchiseApplications.bind(this);
    this.deleteFranchiseApplications =
      this.deleteFranchiseApplications.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.toggleItem = this.toggleItem.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.createMail = this.createMail.bind(this);

    this.state = {
      items: [],
      filter: '',
      page: 1,
      count: 0,
      selected: [],
      isSuccess: false,
      isLoading: false,
      isAction: false,
      isShowForm: false,
      errors: null,
    };

    clearAllFilterParams();
  }

  handleFilter(filter) {
    this.setState(
      {
        filter,
      },
      () => this.fetchFranchiseApplications()
    );
  }
  createMail() {
    const { count, filter } = this.state;
    Swal(`Создаем рассылку для ${count} заявок на франчайзинг`);
    if (filter) {
      localStorage.setItem('franchise_leads_filter', JSON.stringify(filter));
    } else {
      localStorage.setItem('franchise_leads_filter', JSON.stringify({}));
    }
    localStorage.setItem('franchise_leads_total', JSON.stringify(count));
    localStorage.setItem('type', 'franchise_leads');
    return false;
  }
  fetchFranchiseApplications(page = 1) {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        let status;
        let { filter } = this.state;
        FranchiseApplicationAPI.fetchList(page, filter)
          .then((r) => {
            status = r.status;
            return r.json();
          })
          .then((r) => {
            if (status === POSITIVE_ACTION_STATUSES.list) {
              this.setState({
                items: r.results || [],
                count: r.count,
                page: page,
                isSuccess: false,
                errors: null,
                selected: [],
                isLoading: false,
              });
            } else {
              this.setState({
                items: [],
                count: 0,
                page: 1,
                isLoading: false,
              });
            }
          });
      }
    );
  }
  toggleItem(item) {
    const { selected } = this.state;
    this.setState({
      selected: selected.includes(item)
        ? selected.filter((pk) => pk !== item)
        : selected.concat([item]),
      isSuccess: false,
      errors: null,
    });
  }

  handlePageChange(data) {
    this.fetchFranchiseApplications(data.selected + 1);
  }

  deleteFranchiseApplications() {
    this.setState(
      {
        isAction: true,
      },
      () => {
        let status;
        const { selected } = this.state;
        FranchiseApplicationAPI.multipleDeleting(selected)
          .then((r) => {
            status = r.status;
            if (status === 400) {
              return r.json();
            }
            return {};
          })
          .then((r) => {
            if (status === POSITIVE_ACTION_STATUSES.destroy) {
              this.setState(
                {
                  isSuccess: true,
                  selected: [],
                  errors: null,
                  isAction: false,
                },
                () => setTimeout(this.fetchFranchiseApplications, 2000)
              );
            } else if (status === 400) {
              this.setState({
                errors: r,
                isSuccess: false,
                isAction: false,
              });
            } else {
              this.setState({
                isAction: false,
              });
            }
          });
      }
    );
  }

  toggleForm() {
    this.setState({
      isShowForm: !this.state.isShowForm,
      selected: [],
      isSuccess: false,
      isLoading: false,
      isAction: false,
      errors: null,
    });
  }

  onSuccess() {
    this.setState(
      {
        isShowForm: !this.state.isShowForm,
        selected: [],
        isSuccess: true,
        isLoading: false,
        isAction: false,
        errors: null,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              isSuccess: false,
            },
            this.fetchFranchiseApplications
          );
        }, 4000);
      }
    );
  }

  componentDidMount() {
    const { page } = this.state;
    this.fetchFranchiseApplications(page);
  }

  render() {
    const {
      items,
      page,
      count,
      isLoading,
      selected,
      isSuccess,
      isAction,
      isShowForm,
    } = this.state;
    return (
      <div>
        <FranchiseApplicationFilter
          onFilter={this.handleFilter}
          isShowForm={isShowForm}
        >
          <Button color="warning" onClick={this.toggleForm} className='mb-2'>
            {isShowForm ? 'Скрыть' : 'Показать'} форму создания
          </Button>
          {isShowForm ? (
            <FranchiseApplicationAction
              isCreation={true}
              onSuccess={this.onSuccess}
            />
          ) : null}
        </FranchiseApplicationFilter>

        <div>
          <Row>
            <div className="col-lg-12" style={{ position: 'inherit' }}>
              <div className="card" style={{ position: 'inherit' }}>
                <CardHeader>
                  <i className="fa fa-align-justify">{''}</i> Всего&nbsp;
                  <span className="badge badge-info">{count}</span>
                  <Link
                    className="btn btn-success ml-3 mt-0"
                    onClick={this.createMail}
                  to="/templates/"
                    style={{ display: 'inline-block' }}
                  >
                    <span>
                      <i className="fa fa-send mr-2">{''}</i>
                      Создать рассылку
                    </span>
                  </Link>
                  {selected.length ? (
                    <div className="float-right" hidden={isAction}>
                      <Button
                        color="danger"
                        onClick={this.deleteFranchiseApplications}
                      >
                        Удалить ({selected.length} шт.)
                      </Button>
                    </div>
                  ) : null}
                  {isSuccess ? <Alert>Успешно</Alert> : null}
                </CardHeader>
                {isLoading ? (
                  <ProgressBar />
                ) : (
                  <div>
                    {!items.length ? (
                      <p className="m-3">Заявок на франчайзинг не найдено</p>
                    ) : (
                      <CardBody className="card-body_responsive">
                        <FranchiseApplicationList
                          items={items}
                          selected={selected}
                          toggleItem={this.toggleItem}
                        />
                        <Paginator
                          total={count}
                          onPageChange={this.handlePageChange}
                          forcePage={page - 1}
                        />
                      </CardBody>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}
