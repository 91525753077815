import React, { PureComponent } from 'react';
import Select from 'react-select';

import OfficeSelectContainer from '../../../components/OfficeSelectContainer/OfficeSelectContainer';
import { ADMIN, SUPER_ADMIN, getUserRole } from '../../../connect/auth';
import {
  POSITIVE_ACTION_STATUSES,
  AbandonedCustomersAPI,
} from '../../../api/Client';

class AbandonedCustomersFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchFilterGroups = this.fetchFilterGroups.bind(this);
    this.fetchFilterTags = this.fetchFilterTags.bind(this);

    this.handleFilterHasEmail = this.handleFilterHasEmail.bind(this);
    this.handleFilterHasEmailDuplicates =
      this.handleFilterHasEmailDuplicates.bind(this);
    this.handleFilterHasPhone = this.handleFilterHasPhone.bind(this);
    this.handleFilterHasPhoneDuplicates =
      this.handleFilterHasPhoneDuplicates.bind(this);
    this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
    this.handleFilterOffices = this.handleFilterOffices.bind(this);
    this.handleFilterOfficesCity = this.handleFilterOfficesCity.bind(this);
    this.handleFilterOfficesCountry =
      this.handleFilterOfficesCountry.bind(this);
    this.handleFilterGroups = this.handleFilterGroups.bind(this);
    this.handleFilterTags = this.handleFilterTags.bind(this);
    this.handleFilterType = this.handleFilterType.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.onFilter = this.onFilter.bind(this);

    this.state = {
      has_email: '',
      has_email_duplicates: '',
      has_phone: '',
      has_phone_duplicates: '',
      offices: [],
      offices_city: null,
      offices_country: null,
      q: '',
      groups: '',
      groupsFetched: [],
      tags: '',
      tagsFetched: [],
      type: '',
    };
  }

  componentDidMount() {
    this.fetchFilterGroups();
    this.fetchFilterTags();
  }

  fetchFilterGroups() {
    let status;
    let { filter } = this.state;

    AbandonedCustomersAPI.fetchList(1, filter, 'filter/groups/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({ groupsFetched: r || [] });
        }
      });
  }
  fetchFilterTags() {
    let status;
    let { filter } = this.state;

    AbandonedCustomersAPI.fetchList(1, filter, 'filter/tags/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({ tagsFetched: r || [] });
        }
      });
  }

  handleFilterHasEmail(has_email) {
    this.setState({ has_email: has_email });
  }

  handleFilterHasEmailDuplicates(has_email_duplicates) {
    this.setState({ has_email_duplicates });
  }
  handleFilterHasPhone(has_phone) {
    this.setState({ has_phone });
  }

  handleFilterHasPhoneDuplicates(has_phone_duplicates) {
    this.setState({ has_phone_duplicates });
  }

  handleFilterInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleFilterOffices(offices) {
    this.setState({ offices });
  }
  handleFilterOfficesCity(offices_city) {
    this.setState({ offices_city });
  }
  handleFilterOfficesCountry(offices_country) {
    this.setState({ offices_country });
  }

  handleFilterGroups(groups) {
    this.setState({ groups });
  }

  handleFilterTags(tags) {
    this.setState({ tags });
  }

  handleFilterType(type) {
    this.setState({ type });
  }

  handleReset() {
    this.setState(
      {
        has_email: '',
        has_email_duplicates: '',
        has_phone: '',
        has_phone_duplicates: '',
        managers: [],
        offices: [],
        offices_city: null,
        offices_country: null,
        q: '',
        groups: '',
        tags: '',
        type: '',
      },
      this.onFilter
    );
  }

  onFilter() {
    const filter = {};

    filter.has_email =
      this.state.has_email !== '' ? this.state.has_email.value : '';
    filter.has_email_duplicates =
      this.state.has_email_duplicates !== ''
        ? this.state.has_email_duplicates.value
        : '';
    filter.has_phone =
      this.state.has_phone !== '' ? this.state.has_phone.value : '';
    filter.has_phone_duplicates =
      this.state.has_phone_duplicates !== ''
        ? this.state.has_phone_duplicates.value
        : '';
    filter.office = this.state.offices
      ? this.state.offices.map((office) => office.id).join()
      : '';
    filter.office_city = this.state.offices_city
      ? this.state.offices_city.id : '';
    filter.office_country = this.state.offices_country
      ? this.state.offices_country.id : '';
    filter.q = this.state.q ? this.state.q : '';
    filter.tag = this.state.tags
      ? this.state.tags.map((tag) => tag.value).join()
      : '';
    filter.groups = this.state.groups
      ? this.state.groups.map((group) => group.value).join()
      : '';
    filter.type = this.state.type ? this.state.type.value : '';

    this.props.onFilter(filter);
  }

  render() {
    const userRole = getUserRole();
    const {
      has_email,
      has_email_duplicates,
      has_phone,
      has_phone_duplicates,
      q,
      tags,
      tagsFetched,
      type,
    } = this.state;

    // filter
    const BOOL_OPTIONS = [
      { value: true, label: 'Да' },
      { value: false, label: 'Нет' },
    ];
    const TAGS_OPTIONS = tagsFetched.length
      ? tagsFetched.map((t) => {
          return { value: t.id.toString(), label: t.name };
        })
      : [];
    const TYPE_OPTIONS = [
      { value: 'person', label: 'Потенциальный' },
      { value: 'touristfp', label: 'Турист' },
    ];

    return (
      <div className="animated fadeIn filter-container">
        <div className="filter filter--blue">
          <div className="card card--search-bg">
            <div className="card-header">
              <strong>Клиенты закрывшихся агентов</strong>
            </div>
            <div className="card-body">
              <div className="filter-row">
                <div className="filter-field">
                  <label htmlFor="filterHasEmail">Есть почта</label>
                  <Select
                    id="filterHasEmail"
                    name="has_email"
                    placeholder="Есть почта"
                    closeOnSelect={true}
                    value={has_email}
                    simpleValue={true}
                    onChange={this.handleFilterHasEmail}
                    options={BOOL_OPTIONS}
                  />
                </div>
                <div className="filter-field">
                  <label htmlFor="filterHasEmailDuplicates">
                    Дублирующаяся почта
                  </label>
                  <Select
                    id="filterHasEmailDuplicates"
                    name="has_email_duplicates"
                    placeholder="Дубликаты почты"
                    closeOnSelect={true}
                    value={has_email_duplicates}
                    simpleValue={true}
                    onChange={this.handleFilterHasEmailDuplicates}
                    options={BOOL_OPTIONS}
                  />
                </div>
                <div className="filter-field">
                  <label htmlFor="filterHasPhone">Есть телефон</label>
                  <Select
                    id="filterHasPhone"
                    name="has_phone"
                    placeholder="Есть телефон"
                    closeOnSelect={true}
                    value={has_phone}
                    simpleValue={true}
                    onChange={this.handleFilterHasPhone}
                    options={BOOL_OPTIONS}
                  />
                </div>
                <div className="filter-field">
                  <label htmlFor="filterHasPhoneDuplicates">
                    Дублирующиеся телефоны
                  </label>
                  <Select
                    id="filterHasPhoneDuplicates"
                    name="has_phone_duplicates"
                    placeholder="Дубликаты телефонов"
                    closeOnSelect={true}
                    value={has_phone_duplicates}
                    simpleValue={true}
                    onChange={this.handleFilterHasPhoneDuplicates}
                    options={BOOL_OPTIONS}
                  />
                </div>
              </div>
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <div className="filter__office">
                  <OfficeSelectContainer
                    changeCity={this.handleFilterOfficesCity}
                    changeCountry={this.handleFilterOfficesCountry}
                    changeOffice={this.handleFilterOffices}
                    fetch_active={false}
                    multi_office={true}
                    selected_city={this.state.offices_city}
                    selected_country={this.state.offices_country}
                    selected_office={this.state.offices}
                  />
                </div>
              )}
              <div className="filter-row">
                <div className="filter-field filter__name">
                  <label htmlFor="personData">Клиент</label>
                  <input
                    type="text"
                    className="form-control"
                    id="personData"
                    name="q"
                    value={q}
                    title="Данные клиента"
                    placeholder="ФИО, email, телефон"
                    onChange={this.handleFilterInputChange}
                  />
                </div>
                <div className="filter-field">
                  <label htmlFor="filterType">Тип</label>
                  <Select
                    id="filterType"
                    name="type"
                    placeholder="Укажите тип"
                    closeOnSelect={true}
                    value={type}
                    simpleValue={true}
                    onChange={this.handleFilterType}
                    options={TYPE_OPTIONS}
                  />
                </div>
                <div className="filter-field">
                  <label htmlFor="filterTags">Метки</label>
                  <Select
                    id="filterTags"
                    name="tags"
                    placeholder="Укажите метки"
                    isMulti={true}
                    closeOnSelect={true}
                    value={tags}
                    simpleValue={true}
                    onChange={this.handleFilterTags}
                    options={TAGS_OPTIONS}
                  />
                </div>
                <div className="filter-field">
                  <label htmlFor="filterGroups">Группы</label>
                  <Select
                    id="filterGroups"
                    name="groups"
                    placeholder="Укажите группы"
                    isMulti={true}
                    closeOnSelect={true}
                    value={this.state.groups}
                    simpleValue={true}
                    onChange={this.handleFilterGroups}
                    options={this.state.groupsFetched}
                  />
                </div>
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.onFilter}
                >
                  Найти
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  style={{ backgroundColor: 'transparent' }}
                  onClick={this.handleReset}
                >
                  Сбросить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AbandonedCustomersFilter;
