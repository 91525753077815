import React, { Component } from 'react';
import { IssuesAPI, AnswersAPI } from '../../../api/Client';
import { Alert } from 'reactstrap';

class IssueCompleteForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.successSubmit = this.successSubmit.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.handleFpNumber = this.handleFpNumber.bind(this);
    this.doWithTimeout = this.doWithTimeout.bind(this);

    this.state = {
      id: '',
      fp_number: this.props.fp_number ? this.props.fp_number : '',
      showErrorMessage: false,
      showSuccessMessage: false,
    };
  }

  doWithTimeout(timeout = 2000) {
    setTimeout(() => {
      this.setState({
        ...this.state,
        showErrorMessage: false,
        showSuccessMessage: false,
      });
      if (this.props.onCreate) {this.props.onCreate();}
    }, timeout);
  }

  submit(e) {
    e.preventDefault();

    let data = {
      issue: this.props.id,
      title: 'title',
      fp_number: this.state.fp_number,
    };
    IssuesAPI.modify(this.props.id, data)
      .then((r) => r.json())
      .then((result) => {
        typeof result.fp_number === 'number'
          ? this.successSubmit(result)
          : this.errorMessage(result);
      });
  }

  successSubmit(r) {
    this.setState({
      ...this.state,
      title: '',
      orderfp: '',
      showSuccessMessage: true,
    });
    this.doWithTimeout();
  }

  errorMessage(err) {
    this.setState({
      ...this.state,
      showErrorMessage: err,
    });
  }

  handleFpNumber(e) {
    this.setState({
      ...this.state,
      fp_number: e.target.value,
    });
  }
  handleDate = (date) => {
    this.setState({
      ...this.state,
      date: date.format('YYYY-MM-DD'),
    });
  };

  render() {
    return (
      <form>
        {/* <input ref="_issue" type="hidden" value={this.props.issue.id} /> */}

        <div className="form-group row">
          <div className="col-md-12">
            <i style={{ color: '#c72900' }}>
              При покупке, добавьте номер заявки из системы бронирования
            </i>
            <input
              disabled={this.props.isLock}
              autoFocus={true}
              name="input"
              className="form-control"
              placeholder="Введите номер заявки"
              value={this.state.fp_number}
              onChange={this.handleFpNumber}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.submit(event);
                }
              }}
            />
          </div>
        </div>
        {!this.props.isLock ? (
          <button className="issue_btn btn btn-success" onClick={this.submit}>
            Сохранить
          </button>
        ) : (
          ''
        )}
        {this.state.showErrorMessage ? (
          <Alert color="warning">
            <strong>Ошибка!</strong> Не удалось сохранить
            <ul>
            {Object.values(this.state.showErrorMessage).map((err) => Array.isArray(err) ? err.join('') : err).map((err) => <li>{err}</li>)}
            </ul>
          </Alert>
        ) : (
          ''
        )}
        {this.state.showSuccessMessage ? (
          <Alert color="success">
            <strong>Номер</strong> успешно добавлен
          </Alert>
        ) : (
          ''
        )}
      </form>
    );
  }
}

export default IssueCompleteForm;
