export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const OFFICES_BY_STATE_REQUEST = 'OFFICES_BY_STATE_REQUEST';
export const OFFICES_BY_STATE_LOADED = 'OFFICES_BY_STATE_LOADED';

export const LEADS_BY_STATE_REQUEST = 'LEADS_BY_STATE_REQUEST';
export const LEADS_BY_STATE_LOADED = 'LEADS_BY_STATE_LOADED';
export const LEADS_TABLE_PAGE_NUMBER = 'LEADS_TABLE_PAGE_NUMBER';

export const PERSONS_BY_STATE_REQUEST = 'PERSONS_BY_STATE_REQUEST';
export const PERSONS_BY_STATE_LOADED = 'PERSONS_BY_STATE_LOADED';

export const MANAGERS_BY_STATE_REQUEST = 'MANAGERS_BY_STATE_REQUEST';
export const MANAGERS_BY_STATE_LOADED = 'MANAGERS_BY_STATE_LOADED';

export const TOURIST_FILES_BY_REQUEST = 'TOURIST_FILES_BY_REQUEST';
export const TOURIST_FILES_BY_LOADED = 'TOURIST_FILES_BY_LOADED';
export const TOURIST_FILES_BY_FAILURE = 'TOURIST_FILES_BY_FAILURE';

export const TOURIST_FILE_UPLOAD_REQUEST = 'TOURIST_FILE_UPLOAD_REQUEST';
export const TOURIST_FILE_UPLOAD_SUCCESS = 'TOURIST_FILE_UPLOAD_SUCCESS';
export const TOURIST_FILE_UPLOAD_FAILURE = 'TOURIST_FILE_UPLOAD_FAILURE';

export const TOURIST_FILE_DELETE_REQUEST = 'TOURIST_FILE_DELETE_REQUEST';
export const TOURIST_FILE_DELETE_SUCCESS = 'TOURIST_FILE_DELETE_SUCCESS';
export const TOURIST_FILE_DELETE_FAILURE = 'TOURIST_FILE_DELETE_FAILURE';

export const TOURIST_FILE_DETAIL_REQUEST = 'TOURIST_FILE_DETAIL_REQUEST';
export const TOURIST_FILE_DETAIL_SUCCESS = 'TOURIST_FILE_DETAIL_SUCCESS';
export const TOURIST_FILE_DETAIL_FAILURE = 'TOURIST_FILE_DETAIL_FAILURE';

export const UPDATE_SELECTED_MANAGER = 'UPDATE_SELECTED_MANAGER';

export const ISSUES_TABLE_PAGE_NUMBER = 'ISSUES_TABLE_PAGE_NUMBER';

export const QUOTES_TABLE_PAGE_NUMBER = 'QUOTES_TABLE_PAGE_NUMBER';

export const AGENCIES_TABLE_PAGE_NUMBER = 'AGENCIES_TABLE_PAGE_NUMBER';
export const AGENCIES_SORT_ORDER = 'AGENCIES_SORT_ORDER';
export const AGENCIES_SORT_PARAM = 'AGENCIES_SORT_PARAM';

/**
 * Функции Action Creators
 */

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    creds: creds,
  };
}

function requestLoginSuccess() {
  return {
    type: LOGIN_SUCCESS,
  };
}

function requestLoginFail(message) {
  return {
    type: LOGIN_REQUEST,
    message,
  };
}

export function requestOfficesByState() {
  return {
    type: OFFICES_BY_STATE_REQUEST,
  };
}

export function requestOfficesByStateLoaded(data) {
  return {
    type: OFFICES_BY_STATE_LOADED,
    data,
  };
}

export function requestLeadsByState() {
  return {
    type: LEADS_BY_STATE_REQUEST,
  };
}

export function requestLeadsByStateLoaded(data) {
  return {
    type: LEADS_BY_STATE_LOADED,
    data,
  };
}

export function requestPersonsByState() {
  return {
    type: PERSONS_BY_STATE_REQUEST,
  };
}

export function requestPersonsByStateLoaded(data) {
  return {
    type: PERSONS_BY_STATE_LOADED,
    data,
  };
}

export function requestManagersByState() {
  return {
    type: MANAGERS_BY_STATE_REQUEST,
  };
}

export function requestManagersByStateLoaded(data) {
  return {
    type: MANAGERS_BY_STATE_LOADED,
    data,
  };
}

export function requestTouristFiles() {
  return {
    type: TOURIST_FILES_BY_REQUEST,
  };
}

export function requestTouristFilesSuccess(data) {
  return {
    type: TOURIST_FILES_BY_LOADED,
    data,
  };
}

export function requestTouristFilesFailure(message) {
  return {
    type: TOURIST_FILES_BY_LOADED,
    message,
  };
}

export function requestTouristFileUpload() {
  return {
    type: TOURIST_FILE_UPLOAD_REQUEST,
  };
}

export const requestTouristFileUploadSuccess = (file) => {
  return {
    type: TOURIST_FILE_UPLOAD_SUCCESS,
    payload: file,
  };
};

export function requestTouristFileUploadFailure(message) {
  return {
    type: TOURIST_FILE_UPLOAD_FAILURE,
    message,
  };
}

export function requestTouristFileDelete() {
  return {
    type: TOURIST_FILE_DELETE_REQUEST,
  };
}

export const requestTouristFileDeleteSuccess = (file) => {
  return {
    type: TOURIST_FILE_DELETE_SUCCESS,
    payload: file,
  };
};

export function requestTouristFileDeleteFailure(message) {
  return {
    type: TOURIST_FILE_DELETE_FAILURE,
    message,
  };
}

export const switchCurrentIssuesPage = (pageNumber) => {
  return {
    type: ISSUES_TABLE_PAGE_NUMBER,
    pageNumber,
  };
};

export const switchCurrentLeadsPage = (pageNumber) => {
  return {
    type: LEADS_TABLE_PAGE_NUMBER,
    pageNumber,
  };
};

export const switchCurrentQuotesPage = (pageNumber) => {
  return {
    type: QUOTES_TABLE_PAGE_NUMBER,
    pageNumber,
  };
};

export const switchCurrentAgenciesPage = (pageNumber) => {
  return {
    type: AGENCIES_TABLE_PAGE_NUMBER,
    pageNumber,
  };
};

export const setParamToSortAgenciesBy = (sortParam) => {
  return {
    type: AGENCIES_SORT_PARAM,
    sortParam,
  };
};

export const setSortOrderOfAgencies = (sortOrder) => {
  return {
    type: AGENCIES_SORT_ORDER,
    sortOrder,
  };
};
