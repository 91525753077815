import React, { Component } from 'react';
import FilterOrdersFp from '../Components/FilterOrdersFp';
import { OrderFpAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client';
import OrdersFpTable from '../../views/OrdersFpTable';
import './OrdersFp.css';
import { DJANGO_BASE_DATE_FORMAT } from '../Components/Calendar';
import moment from 'moment';
import 'moment/locale/ru';

export default class Leads extends Component {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      items: [],
      count: 0,
      pageNumber: 1,
      filters: null,
      isLoading: false,
    };
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({ pageNumber: page }, () => this.fetchItems(page));
  }

  handleSubmit() {
    this.props.onFilter(this.state);
  }

  filterItems(st) {
    let result = {};

    if (st.fpNumber) {
      const fpNumber = parseInt(st.fpNumber, 10);
      if (!isNaN(fpNumber)) {
        result.fp_pk = fpNumber;
      }
    }

    if (st.arrivalStart) {
      result.arrival__gte = moment(st.arrivalStart).format(
        DJANGO_BASE_DATE_FORMAT
      );
    }

    if (st.arrivalEnd) {
      result.arrival__lte = moment(st.arrivalEnd).format(
        DJANGO_BASE_DATE_FORMAT
      );
    }

    if (st.departureStart) {
      result.departure__gte = moment(st.departureStart).format(
        DJANGO_BASE_DATE_FORMAT
      );
    }

    if (st.departureEnd) {
      result.departure__lte = moment(st.departureEnd).format(
        DJANGO_BASE_DATE_FORMAT
      );
    }

    if (st.createdStart) {
      result.created__gte = moment(st.createdStart).format(
        DJANGO_BASE_DATE_FORMAT
      );
    }

    if (st.createdEnd) {
      result.created__lte = moment(st.createdEnd).format(
        DJANGO_BASE_DATE_FORMAT
      );
    }

    if (!!st.selectedOffice && typeof st.selectedOffice === 'object') {
      result.office = st.selectedOffice.id;
    }

    if (!!st.manager) {
      const manager = st.manager.map((m) => m.id);
      if (manager.length) {
        result.manager = manager;
      }
    }

    if (!!st.managerFp) {
      const managerFp = st.managerFp.map((m) => m.value);
      if (managerFp.length) {
        result.manager_fp = managerFp;
      }
    }

    if (st.status) {
      result.status = st.status.value;
    }

    if (st.departCity) {
      result.depart_city = st.departCity;
    }

    if (st.destinationCountry) {
      result.destination_country = st.destinationCountry;
    }

    if (st.currency) {
      result.currency = st.currency.value;
    }

    if (st.orderAmountFrom) {
      result.summ__gte = st.orderAmountFrom;
    }

    if (st.orderAmountTo) {
      result.summ__lte = st.orderAmountTo;
    }

    if (st.nightsFrom) {
      result.nights__gte = st.nightsFrom;
    }

    if (st.nightsTo) {
      result.nights__lte = st.nightsTo;
    }

    if (!!st.tourist && typeof st.tourist === 'string') {
      result.tourist = st.tourist.trim();
    }

    if (!!result) {
      this.setState({ filters: result }, () => this.fetchItems(1));
    }
  }

  fetchItems(page = 1) {
    this.setState({ isLoading: true });
    let status;
    OrderFpAPI.fetchList(page, this.state.filters)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            items: r.results || [],
            count: r.count,
            isLoading: false,
          });
        }
      });
  }

  componentDidMount() {
    this.fetchItems(this.state.pageNumber);
  }

  render() {
    const { count, items, filters, pageNumber, isLoading } = this.state;

    return (
      <div className="animated fadeIn">
        <div className="filter filter--pink">
          <FilterOrdersFp
            onFilter={this.filterItems}
            handleRefresh={this.fetchItems}
          />
        </div>
        <OrdersFpTable
          count={count}
          isLoading={isLoading}
          items={items}
          filters={filters}
          isShowActions={!!filters}
          page={pageNumber}
          handleRefresh={this.fetchItems}
          handlePageChange={this.handlePageChange}
        />
      </div>
    );
  }
}
