import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { PersonAPI, TouristFpAPI, AnswersAPI, POSITIVE_ACTION_STATUSES} from '../../../api/Client';
import {Alert} from 'reactstrap';
import { SMS_PERSON_SUBJECT, SMS_TOURIST_SUBJECT } from '../constants';

const SMS_LENGTH = 70;
const SMS_MAX_LENGTH = 15 * SMS_LENGTH;

class IssueSendSmsForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.successSubmit = this.successSubmit.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.doWithTimeout = this.doWithTimeout.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleOptions = this.handleOptions.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.sendMailer = this.sendMailer.bind(this);

    this.state = {
      id: '',
      message: '',
      phone: '',
      subject: '',
      isDisabledSendButton: false,
      showErrorMessage: false,
      showSuccessMessage: false,
      errorMessage: '',
      isViewSpinner: false,
    };
  }

  submit(e) {
    e.preventDefault();
    // const { _issue, _text } = this.refs;
    // this.props.onSubmit(_issue.value, _text.value);
    // _text.value = '';
    let typeMap = {
      '1': 'comment',
      '2': 'meet',
      '3': 'cancel',
      '4': 'deffered',
      '5': 'tours',
      '10': 'call',
    };
    let status;
    let data = {
      issue: this.props.id,
      title: this.state.title,
      text: this.state.comment,
      type: typeMap[this.props.type],

    };
    if (this.state.date) {
      data.date = this.state.date;
    }
    if (typeMap[this.props.type] == 'tours') {
      data.text = this.state.quote;
    }

    AnswersAPI.
        create(data).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(result => status === POSITIVE_ACTION_STATUSES.create
            ? this.successSubmit(result)
            : this.errorMessage(result),
        );
  }

  doWithTimeout(fn, timeout = 2000) {
    setTimeout(fn.bind(this), timeout);
    setTimeout(() => {
      this.setState({
        ...this.state,
        showErrorMessage: false,
        showSuccessMessage: false,
      });
      this.props.onCreate();
    }, timeout);

  }

  successSubmit(r) {

    this.setState({
      ...this.state,
      title: '',
      comment: '',
      showSuccessMessage: true,
    });
    this.doWithTimeout(this.props.onCreate);

  }

  errorMessage(err) {
    this.setState({
      ...this.state,
      showErrorMessage: err,
    });
  }

  handleComment(e) {
    this.setState({
      ...this.state,
      comment: e.target.value,
    });
  }

  sendMailer(e) {
    let isValidPhone = false;
    const phones = this.state.phone;
    const filter = this.state.id ? {id: this.state.id} : 0;
    const touristType = this.props.item.person  ? 'person' : 'tourist';

    e.preventDefault();
    if (!this.state.message.length) {
      this.setState({
        errorMessage: 'Заполните текст смс сообщения'
      });
      return false;
    }
    // номеров может быть несколько
    this.setState({
      isDisabledSendButton: true,
      showSuccessMessage: false,
      showErrorMessage: false,
      errorMessage: '',
      isViewSpinner: true,
    }, () => {
      if (!phones.length) {
        this.setState({
          errorMessage: 'Необходимо заполнить контактный телефон для туриста'
        });
        return false;
      }
      phones.map((phone) => {
        if (phone.value && /(\+7)(\d{10})/.test(phone.value)) {
          isValidPhone = true;
        }
      })
      if (!isValidPhone) {
        this.setState({
          errorMessage: 'Возможно контактный телефон туриста  заполнен некорректно'
        });
        return false;
      }
      this.setState({
        isDisabledSendButton: true,
        showSuccessMessage: false,
        showErrorMessage: false,
        isViewSpinner: true,
      }, () => {
        let data = {
          dispatch_type: 1,
          sender_type: 1,
          title: this.state.subject,
          address_book: {},
        };
        data['sms_campaign'] = {
          'message': this.state.message,
        };

        let status;
        const responseHandler = (response) => {
          status = response.status;
          return response.json();
        };
        const resultHandler = (result) => {
          if (status === POSITIVE_ACTION_STATUSES.create) {
            this.setState({
              showSuccessMessage: true,
              isViewSpinner: false,
            });
          } else {
            this.setState({
              showSuccessMessage: false,
              showErrorMessage: true,
              isViewSpinner: false,
            });
          }
        };
        if (touristType === 'person') {
          PersonAPI.oneMessage(data, filter).then(responseHandler).then(resultHandler);
        } else if (touristType === 'tourist') {
          TouristFpAPI.oneMessage(data, filter).then(responseHandler).then(resultHandler);
        }
      });
    });
  }

  handleMessage(e) {
    this.setState({
      message: e.target.value,
      isSended: false,
      isError: false,
    });
  }
  handleOptions(value) {
    this.setState({
      ...this.state,
      quote: value,
    });
  }

  componentDidMount() {
    // получим данные по туристу
    const touristType = this.props.item.person  ? 'person' : 'tourist';
    if (touristType === 'person') {
      PersonAPI.getInfo(this.props.item.person.id).then((result) =>
          this.setState({
            id: result.id,
            phone: result.phone || [], // телефонов может быть несколько
            subject: SMS_PERSON_SUBJECT,
          })
      );
    } else if (touristType === 'tourist') {
      TouristFpAPI.getInfo(this.props.item.tourist.id).then((result) =>
          this.setState({
            id: result.id,
            phone: result.phone || [], // телефонов может быть несколько
            subject: SMS_TOURIST_SUBJECT,
          })
      );
    }
  }

  render() {
    const {isLock, type, id } = this.props;
    const {message, showErrorMessage, showSuccessMessage, isDisabledSendButton, errorMessage, isViewSpinner } = this.state;
    return (
        <form>

          <div className="form-group row">
            <Alert color="danger">Стоимость 1 сообщения для 1 адресата - 4 рубля<br/>Сообщение должно быть не более {SMS_LENGTH} знаков</Alert>
            <div className="col-md-12">
                <small>Введено {message.length}</small>
                {" / "}
                <small>Смс {Math.ceil(message.length / SMS_LENGTH)}</small>
                <textarea
                  autoFocus={true}
                  className="form-control mb-4"
                  placeholder="Текст сообщения"
                  rows="5"
                  onChange={this.handleMessage}
                  value={message}
                  maxLength={SMS_MAX_LENGTH}
                  disabled={isDisabledSendButton}
                >
                  {""}
                </textarea>
            </div>
            <button
              className="btn btn-sm btn-success mr-2"
              onClick={this.sendMailer}
              disabled={isDisabledSendButton}
            >
              Отправить
            </button>
            {isViewSpinner && (
              <span className="fa fa-spinner fa-spin fa-2x mr-2">
                {""}
              </span>
            )}
            {isViewSpinner && <span>Подождите ...</span>}
          </div>
          {showErrorMessage && <Alert color="warning"><strong>Ошибка!</strong> Не удалось отправить. Попробуйте позже</Alert>}
          {errorMessage && <Alert color="warning"><strong> {errorMessage} </strong></Alert>}
          {showSuccessMessage && <Alert color="success">Успешно! Ваше сообщение будет доставлено в течении нескольких минут. О статусе отправки вы можете посмотреть <Link to={`/mailbox/`}>здесь</Link></Alert>}
        </form>
    );
  }
}

export default IssueSendSmsForm;
